import React from "react";

import styles from "../styles.module.scss";

import { tabOptions } from "../types";

type PanelTabProps = {
  children?: React.ReactNode;
  activeTab: tabOptions;
  tabNav: string[];
  handleActiveTab: (active: string) => void;
};

const PanelTab: React.FunctionComponent<PanelTabProps> = ({
  children,
  activeTab,
  tabNav,
  handleActiveTab,
}) => {
  return (
    <>
      {
        <div className={styles.panel__tabs}>
          {tabNav.map((nav) => (
            <div
              key={nav}
              className={`${styles.panel__tab} ${
                activeTab === nav && styles.isActive
              }`}
              onClick={() => handleActiveTab(nav)}
            >
              {nav.toLowerCase()}
            </div>
          ))}
        </div>
      }
      {children}
    </>
  );
};

export default React.memo(PanelTab);
