import React from "react";
import { useHistory } from "react-router-dom";

import Sidebar from "components/Sidebar";
import Loading from "components/Loading";
import Button, { ButtonStyle, ButtonType } from "components/Button";

import * as Progress from "components/Progress";
import Headings from "components/Headings";
import OrganizationDetails from "components/Headings/OrganizationDetails";

import { AssessmentGetResponseResult } from "modules/api/endpoints/assessmentGet";
import { ProgressBarPageType } from "components/Progress/Simple/index";

import styles from "./styles.module.scss";
import Note from "components/Notes";
import { unarchiveAssessment } from "modules/utils/ArchiveAssessments";

type TemplateProps = {
  isLoading: boolean;
  assessment: AssessmentGetResponseResult;
  progressBarPages: ProgressBarPageType[];
  handleStartAssessment: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  assessment,
  progressBarPages,
  handleStartAssessment,
}) => {
  const history = useHistory();
  const isReadOnly = assessment.isArchived;
  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <div className={styles["review__wrapper"]}>
            <Headings
              title="Assessment profile set-up"
              subtitle="Review assessment profile"
            ></Headings>
            <div className={styles["review__items"]}>
              {/* Begins Item  */}
              <div className={styles.review__item}>
                <div className={styles["review__item-header"]}>
                  <h2>Assessment profile</h2>{" "}
                  <Button
                    label="Edit"
                    disabled={isReadOnly}
                    style={ButtonStyle.SECONDARY}
                    onClick={() =>
                      history.push(`/assessments/${assessment.id}/profile`)
                    }
                  />
                </div>
                <div className={styles.review__body}>
                  <div className={styles["review__item-list"]}>
                    <h4>Organization details</h4>
                    <ul>
                      <li>
                        <span>
                          Name: <strong>{assessment.organization.name}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Number of employees:{" "}
                          <strong>
                            {assessment.organization.size.name} people
                          </strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Location:{" "}
                          <strong>
                            {assessment.organization.country.name}
                          </strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Business unit:{" "}
                          <strong>
                            {assessment.organization.businessUnit}
                          </strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Industry:{" "}
                          <strong>
                            {assessment.organization.industry.name}
                          </strong>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className={styles["review__item-list"]}>
                    <h4>Assessment details</h4>
                    <ul>
                      <li>
                        <span>
                          Name: <strong>{assessment.name}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Scope: <strong>{assessment.selectedScope}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Certification:{" "}
                          <strong>
                            {assessment.certified
                              ? " Certified"
                              : " Uncertified"}
                          </strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Finish Item  */}

              <div
                className={`${styles.review__item} ${styles["review__item--row"]}`}
              >
                <div className={styles["review__item-header"]}>
                  <h2>Scope details</h2>{" "}
                  <Button
                    label="Edit"
                    style={ButtonStyle.SECONDARY}
                    disabled={isReadOnly}
                    onClick={() =>
                      history.push(`/assessments/${assessment.id}/scope`)
                    }
                  />
                </div>
                <div className={styles.review__body}>
                  <div className={styles["review__item-list"]}>
                    <h4>
                      Scope: <strong>{assessment.selectedScope}</strong>
                    </h4>
                  </div>
                  <div className={styles["review__item-list"]}>
                    <h4>Expected level:</h4>
                    <p className={styles.checkIcon}>
                      <strong>
                        Level {assessment.selectedLevel}
                        <span>*</span>
                      </strong>
                    </p>
                    {assessment.selectedLevel !== 5 && (
                      <p>
                        <strong>
                          <span>*</span>By selecting level{" "}
                          {assessment.selectedLevel}, level{" "}
                          {assessment.selectedLevel + 1} statement will also
                          become available for your review
                        </strong>
                      </p>
                    )}
                  </div>
                  <div className={styles["review__item-list"]}>
                    <h4>Selected perspectives:</h4>
                    <ul>
                      {assessment.perspectives.map((p) => (
                        <li key={p.id}>
                          <span className={styles.checkIcon}>
                            <strong>{p.name}</strong>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className={styles["review__item-list"]}>
                    <h4>Selected threads:</h4>
                    <ul>
                      {assessment.threads.map((t) => (
                        <li key={t.id}>
                          <span className={styles.checkIcon}>
                            <strong>{t.name}</strong>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Finish Item  */}
              <div className={styles.review__actions}>
                <Button
                  label="Save &amp; return home"
                  style={ButtonStyle.SECONDARY}
                  type={ButtonType.BUTTON}
                  onClick={() => history.push("/")}
                  disabled={isReadOnly}
                />
                <Button
                  label={
                    assessment.status === "Active"
                      ? "Continue assessment"
                      : "Start assessment"
                  }
                  style={ButtonStyle.PRIMARY}
                  type={ButtonType.BUTTON}
                  disabled={isReadOnly}
                  onClick={handleStartAssessment}
                />
              </div>
            </div>
            {/* Finish Items  */}
          </div>
          {/* sidebar */}
          <Sidebar>
            <OrganizationDetails
              country={assessment.organization.country.name}
              name={assessment.organization.name}
              businessUnit={assessment.organization.businessUnit}
            />
            {isReadOnly && (
              <Note>
                <p>
                  This assessment has been archived.
                  <strong
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={async () => {
                      await unarchiveAssessment(assessment.id);
                      history.push(`/`);
                    }}
                  >
                    &nbsp;Click here&nbsp;
                  </strong>
                  to restore if you would like to continue working on it.
                </p>
              </Note>
            )}
            {!isReadOnly && (
              <Progress.Simple
                title="Your Progress"
                subtitle="Assessment profile set-up"
                company={assessment.organization.name}
                pages={progressBarPages}
                assessmentId={assessment.id}
              />
            )}
          </Sidebar>
          {/* close sidebar */}
        </>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
