import React, { useLayoutEffect, useState } from "react";

import { ReactComponent as Warning } from "modules/theme/icons/modal/info.svg";
import { ReactComponent as Error } from "modules/theme/icons/modal/error.svg";
import { ReactComponent as Close } from "modules/theme/icons/modal/close.svg";
import { ReactComponent as Success } from "modules/theme/icons/modal/success.svg";
import styles from "./styles.module.scss";
import { ButtonStyle } from "components/Button";
import { ModalActions } from "./ModalActions";

export type ModalOptions = {
  catchOnCancel?: boolean;
  type: "error" | "info" | "confirm" | "success";
  title: string;
  description?: string;
  assessmentId?: number;
  buttonLabel?: string;
  buttonStyle?: ButtonStyle;
  displayCancelButton?: boolean;
  cancelButtonLabel?: string;
  cancelButtonStyle?: ButtonStyle;
  assessmentType?: "ITIL" | "P3M3";
  hasCloseButton?: boolean;
};

interface ModalProps extends ModalOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onCerfify: (assessmentId: number, type: string) => Promise<boolean>;
}

export enum ModalType {
  ERROR = "error",
  INFO = "info",
  CONFIRM = "confirm",
  SUCCESS = "success",
}

// Hook
function useLockBodyScroll(): void {
  // useLaoutEffect callback return type is "() => void" type
  useLayoutEffect((): (() => void) => {
    // Get original body overflow
    const originalStyle: string = window.getComputedStyle(
      document.body
    ).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}

const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  title,
  type,
  description,
  onSubmit,
  onClose,
  onCerfify,
  assessmentId,
  buttonLabel,
  buttonStyle,
  displayCancelButton,
  cancelButtonLabel,
  cancelButtonStyle,
  assessmentType,
  hasCloseButton,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [displayFeedback, setDisplayFeedback] = useState(false);
  useLockBodyScroll();

  const getIconType = (iconType: string) => {
    switch (iconType) {
      case ModalType.CONFIRM:
      case ModalType.INFO:
        return <Warning />;
      case ModalType.ERROR:
        return <Error />;
      case ModalType.SUCCESS:
        return <Success />;
      default:
        return null;
    }
  };

  const handleCertify = async () => {
    setIsLoading(true);
    const assmtId = assessmentId ? assessmentId : 0;
    const assmtType = assessmentType ? assessmentType : "P3M3";
    const response = await onCerfify(assmtId, assmtType);
    if (response === true) setDisplayFeedback(true);
  };

  return (
    <React.Fragment>
      {open && (
        <div className={styles.Modal}>
          <div className={styles.ModalBox}>
            <div className={styles.ModalBoxTop}>
              {getIconType(type)}
              {hasCloseButton && (
                <span
                  className={styles.ModalClose}
                  onClick={() => {
                    type === ModalType.SUCCESS ? onSubmit() : onClose();
                  }}
                >
                  <Close />
                </span>
              )}
            </div>
            <div className={styles.ModalBoxBody}>
              {type !== ModalType.CONFIRM && (
                <>
                  {title && <h2>{title}</h2>}
                  {description && <p>{description}</p>}
                </>
              )}
              {type === ModalType.CONFIRM && (
                <h2>{displayFeedback ? description : title}</h2>
              )}
            </div>
            <ModalActions
              isLoading={isLoading}
              type={type}
              buttonLabel={buttonLabel}
              buttonStyle={buttonStyle}
              displayCancelButton={!!displayCancelButton}
              cancelButtonLabel={cancelButtonLabel}
              cancelButtonStyle={cancelButtonStyle}
              displayFeedback={displayFeedback}
              handleClose={onClose}
              handleSubmit={onSubmit}
              handleCertify={handleCertify}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Modal;
