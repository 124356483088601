import React from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type StatusProps = {
  answered?: boolean;
  required?: boolean;
};

const Status: React.FunctionComponent<StatusProps> = ({
  answered,
  required,
}) => {
  const className = clsx(
    styles["status"],
    required && styles["required"],
    answered && required && styles["answered"]
  );
  return <span className={className}></span>;
};

export default Status;
