import React from "react";

type ProgressBarProps = {
  progress: number;
  className: string;
};
const ProgressBar: React.FC<ProgressBarProps> = ({ progress, className }) => {
  return (
    <span
      className={className}
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={100}
      style={{
        width: `${progress}%`,
      }}
    />
  );
};

export default ProgressBar;
