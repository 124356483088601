import { ApiEndpoint } from "./types";

import { ExamplePostRequest } from "./endpoints/examplePost";
import { ExampleGetRequest } from "./endpoints/exampleGet";
import { WorkflowApiRequest } from "./endpoints/workflow";

import { AssessmentPostRequest } from "./endpoints/assessmentPost";
import { AssessmentPutRequest } from "./endpoints/assessmentPut";

import { OrganizationPostRequest } from "./endpoints/organizationPost";
import { OrganizationPutRequest } from "./endpoints/organizationPut";

import { AnswerType, MatAnswerType } from "./endpoints/questionsPost";
import {
  CapabilityNoteType,
  MaturityNoteType,
  P3M3NoteType,
} from "./endpoints/notesPost";

import { DiagnosticType } from "./endpoints/questionsDiagnosticsPut";

const VERSION = process.env.REACT_APP_VERSION;
const HOST = process.env.REACT_APP_API_ROOT;

export enum ApiMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
}

export const API = {
  // ASSESSMENTS

  GET_ASSESSMENTS: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/assessments`,
  }),

  GET_ASSESSMENT: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/assessments/${id}`,
  }),

  GET_ASSESSMENT_RESULT: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/assessments/${id}/scores`,
  }),

  GET_ASSESSMENT_PROGRESS: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/assessments/${id}/progress`,
  }),

  GET_ASSESSMENT_SCORES: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/assessments/${id}/scores`,
  }),

  PUT_ASSESSMENT: (
    request: AssessmentPutRequest,
    id: string | number
  ): ApiEndpoint => ({
    method: ApiMethod.PUT,
    url: `${HOST}/assessments/${id}`,
    data: request,
  }),

  POST_ASSESSMENT: (
    request: AssessmentPostRequest,
    organizationId: string
  ): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/organizations/${organizationId}/assessments`,
    data: request,
  }),

  POST_ASSESSMENT_COMPLETE: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${id}/complete`,
    data: {},
  }),

  POST_ASSESSMENT_CERFITY: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${id}/certify`,
  }),

  // ORGANIZATIONS

  GET_ORGANIZATION: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/organizations/${id}`,
  }),

  GET_ORGANIZATIONS: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/organizations`,
  }),

  GET_ORGANIZATION_ASSESSMENTS: (
    organizationId: string | number
  ): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/organizations/${organizationId}/assessments`,
  }),

  POST_ORGANIZATION: (request: OrganizationPostRequest): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/organizations`,
    data: request,
  }),

  PUT_ORGANIZATION: (
    request: OrganizationPutRequest,
    id: string
  ): ApiEndpoint => ({
    method: ApiMethod.PUT,
    url: `${HOST}/organizations/${id}`,
    data: request,
  }),

  // QUESTIONS
  GET_QUESTIONS: (id: string | number, params: string): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/P3M3/assessments/${id}/questions${params}`,
  }),

  GET_All_QUESTIONS: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/P3M3/assessments/${id}/questions`,
  }),

  POST_ANSWER: (request: AnswerType[], id: string | number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/P3M3/assessments/${id}/answer`,
    data: request,
  }),

  PUT_QUESTION_DIAGNOSTIC: (
    request: DiagnosticType,
    assessmentId: string,
    diagnosticId: string
  ): ApiEndpoint => ({
    method: ApiMethod.PUT,
    url: `${HOST}/p3m3/assessments/${assessmentId}/diagnostics/${diagnosticId}`,
    data: request,
  }),

  POST_DESCOPE_THREAD: (
    assessmentId: string | number,
    perspectiveId: number,
    threadId: number
  ): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${assessmentId}/${perspectiveId}/${threadId}/descope`,
  }),

  POST_RESCOPE_THREAD: (
    assessmentId: string | number,
    perspectiveId: number,
    threadId: number
  ): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${assessmentId}/${perspectiveId}/${threadId}/rescope`,
  }),

  GET_P3M3_NOTE: (
    assessmentId: number,
    perspectiveId: number,
    threadId: number
  ): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/P3M3/notes/${assessmentId}/${perspectiveId}/${threadId}`,
  }),

  POST_P3M3_NOTE: (request: P3M3NoteType): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/P3M3/notes`,
    data: request,
  }),

  // QUESTIONS

  POST_CAPABILITY_ANSWER: (
    request: AnswerType | AnswerType[],
    id: string | number
  ): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/ITIL/assessments/${id}/answer/capability`,
    data: request,
  }),

  POST_MATURITY_ANSWER: (
    request: MatAnswerType[],
    id: string | number
  ): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/ITIL/assessments/${id}/answer/maturity`,
    data: request,
  }),

  GET_CAPABILITY_QUESTIONS: (
    assessmentId: string | number,
    params?: Record<string, any>
  ): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/itil/assessments/${assessmentId}/questions/capability`,
    params,
  }),

  GET_MATURITY_QUESTIONS: (assessmentId: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/itil/assessments/${assessmentId}/questions/maturity`,
  }),

  GET_ITIL_ASSESSMENT_PROGRESS: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/itil/assessments/${id}/progress`,
  }),

  GET_PERSPECTIVES: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/P3M3/perspectives`,
  }),

  GET_THREADS: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/P3M3/threads`,
  }),

  GET_COUNTRIES: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/countries`,
  }),

  GET_INDUSTRIES: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/industries`,
  }),

  GET_SIZES: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/sizes`,
  }),

  GET_BENCHMARK: (
    perspectiveIds: string[],
    countryId: number,
    industryId: number,
    sizeId: number
  ): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/P3M3/benchmarks/getbenchmarksbyperspective?perspectiveIds=${perspectiveIds}&countryId=${countryId}&industryId=${industryId}&sizeId=${sizeId}`,
  }),

  GET_CAPABILITIES: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/ITIL/capabilities`,
  }),

  GET_MATURITIES: (): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/ITIL/maturities`,
  }),

  GET_MATURITY_NOTE: (
    assessmentId: number,
    maturityId: number
  ): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/ITIL/notes/${assessmentId}/maturity/${maturityId}`,
  }),

  POST_MATURITY_NOTE: (request: MaturityNoteType): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/ITIL/notes/maturity`,
    data: request,
  }),

  GET_CAPABILITY_NOTE: (
    assessmentId: number,
    capabilityId: number
  ): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/ITIL/notes/${assessmentId}/capability/${capabilityId}`,
  }),

  POST_CAPABILITY_NOTE: (request: CapabilityNoteType): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/ITIL/notes/capability`,
    data: request,
  }),

  POST_ASSESSMENT_ENDORSE: (id: string | number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${id}/endorse`,
  }),

  GET_ASSESSMENT_LOCK: (assessmentId: number): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/assessments/${assessmentId}/lock`,
  }),

  POST_ASSESSMENT_LOCK: (assessmentId: number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${assessmentId}/lock`,
  }),

  DELETE_ASSESSMENT_LOCK: (assessmentId: number): ApiEndpoint => ({
    method: ApiMethod.DELETE,
    url: `${HOST}/assessments/${assessmentId}/lock`,
  }),
  POST_DELETE_ASSESSMENT_LOCK: (assessmentId: number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${assessmentId}/lock/delete`,
  }),

  POST_ARCHIVE_ASSESSMENT: (assessmentId: number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${assessmentId}/archive`,
  }),
  POST_UNARCHIVE_ASSESSMENT: (assessmentId: number): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/assessments/${assessmentId}/unarchive`,
  }),
  // OTHERS

  EXAMPLE_GET_REQUEST: (request: ExampleGetRequest): ApiEndpoint => ({
    method: ApiMethod.GET,
    url: `${HOST}/v0/example?data=${request}&version=${VERSION}`,
  }),

  EXAMPLE_POST_REQUEST: (request: ExamplePostRequest): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/v0/example`,
    data: request,
    params: {
      version: VERSION,
    },
  }),
  WORKFLOW_REQUEST: (request: WorkflowApiRequest): ApiEndpoint => ({
    method: ApiMethod.POST,
    url: `${HOST}/v0/workflow`,
    data: request,
    params: {
      version: VERSION,
    },
  }),
};
