import React from "react";
import { SubmitHandler } from "react-hook-form";

import { Debug } from "modules/debug/types";
import { debug } from "modules/debug/state";
import Template from "./Template";

import { schema } from "modules/debug/schema";

const handleSubmit: SubmitHandler<Debug> = (data: Debug) => {
  alert("success");
  // eslint-disable-next-line
  console.log(data);
};

const Route: React.FunctionComponent = () => {
  return <Template debug={debug} handleSubmit={handleSubmit} schema={schema} />;
};

export default Route;
