export enum ButtonsType {
  CHECKBOX = "checkbox",
  RADIO = "radio",
  SWITCH = "switch",
  TRUE_FALSE = "true-false",
  TRUE = "true",
  FALSE = "false",
}

export enum ButtonsFormat {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export const SelectAllOption = {
  label: "Select all",
  value: "allSelected",
};
