import React from "react";

import Button from "./Button";
import { ButtonsType } from "./constants";
import { ButtonSwitchProps } from "./types";

const ButtonSwitch: React.FunctionComponent<ButtonSwitchProps> = ({
  id,
  index,
  onClick,
  option,
  simple,
  tabIndex,
  value,
  ...props
}) => {
  const handleId = () => {
    if (value && value === option.value) return id;
    if (index === 0) return id;
  };
  const handleTabIndex = () => {
    if (value && value === option.value) return 0;
    if (value && value !== option.value) return -1;
    return tabIndex;
  };
  const handleButtonType = () => {
    if (simple && option.value === true) return ButtonsType.TRUE;
    if (simple && option.value === false) return ButtonsType.FALSE;
    return ButtonsType.SWITCH;
  };

  const handleLabel = () => {
    return simple ? "" : option.label;
  };
  return (
    <Button
      {...props}
      id={handleId()}
      label={handleLabel()}
      onClick={() => onClick(value !== option.value ? option.value : "")}
      selected={value === option.value}
      tabIndex={handleTabIndex()}
      type={handleButtonType()}
    />
  );
};

export default React.memo(ButtonSwitch);
