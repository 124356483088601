import React from "react";
import clsx from "clsx";

import { progressItem } from "../types";

import Button from "components/Button";
import { ReactComponent as TickSVG } from "modules/theme/icons/buttons/tick.svg";
import { ReactComponent as DashSVG } from "modules/theme/icons/progress/dash.svg";

import styles from "./styles.module.scss";

type PerspectiveItemProps = {
  perspective: string;
  item: progressItem;
  isSelected?: boolean;
  onClick: (perspectiveCode: string, threadCode: string) => void;
  currentThread: {
    id: number;
    code: string;
    name: string;
  };
};

const PerspectiveItem: React.FunctionComponent<PerspectiveItemProps> = ({
  onClick,
  item,
  isSelected,
  perspective,
  currentThread,
}) => {
  const isThreadSelected = () => {
    if (isSelected && currentThread && item.name === currentThread.name)
      return styles["selected"];
  };

  const className = clsx(
    styles["progress-section-item"],
    isThreadSelected(),
    item.answered && styles["complete"],
    item.answered === false && item.started && styles["incomplete"],
    item.descoped && styles["descoped"]
  );
  return (
    <li className={className}>
      {item.answered && <TickSVG />}
      {item.descoped && <DashSVG />}
      {isThreadSelected() ? (
        <span>{item.name}</span>
      ) : (
        <Button
          onClick={() => onClick(perspective, item.name)}
          label={item.name}
        ></Button>
      )}
    </li>
  );
};

export default React.memo(PerspectiveItem);
