import { AnswersType, StateType } from "./types";

export enum Answers {
  FULLY = "Fully",
  PARTIALLY = "Partially",
  RARELY = "Rarely",
  NA = "NA",
}

export const State: StateType = {
  level1: "",
  level2: "",
  level3: "",
  level4: "",
  level5: "",
};

export enum FormType {
  CREATE = "Create",
  EDIT = "Edit",
}
