import React from "react";
import { SubmitHandler } from "react-hook-form";

import { ReactComponent as ExitSVG } from "modules/theme/icons/buttons/exit.svg";

import { getAssessmentProfileSchema } from "../schema";
import {
  AssessmentProfileStatesType,
  FormDataType,
  ScopeNoteType,
} from "../types";
import { ProgressBarPageType } from "components/Progress/Simple/index";
import { Organization } from "modules/api/endpoints/organizationGet";

import * as Form from "components/Form";
import * as Progress from "components/Progress";
import { QuestionAssessmentProfile } from "components/Questions";
import Headings from "components/Headings";
import Section from "components/Section";
import Sidebar from "components/Sidebar";
import OrganizationDetails from "components/Headings/OrganizationDetails";
import Note from "components/Notes";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import Loading from "components/Loading";

type TemplateProps = {
  children?: React.ReactNode;
  formStates: AssessmentProfileStatesType;
  isLoading: boolean;
  handleSubmit: SubmitHandler<AssessmentProfileStatesType>;
  handleSaveAndExit: (data: AssessmentProfileStatesType) => void;
  displayNote: boolean;
  setDisplayNote: (certified: boolean) => void;
  organization: Organization;
  isSavingAssessment?: boolean;
  hasScope?: boolean;
  hasAssessmentStarted: boolean;
  assessmentId: number;
  progressBarPages: ProgressBarPageType[];
  setFormData: (data: FormDataType) => void;
  formData: FormDataType;
  endorsementNoteCopy: string[];
  handleScopeNoteCopy: (scope: string) => void;
  scopeNoteCopy?: ScopeNoteType;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  formStates,
  displayNote,
  handleSubmit,
  handleSaveAndExit,
  setDisplayNote,
  organization,
  isSavingAssessment,
  hasScope,
  hasAssessmentStarted,
  assessmentId,
  progressBarPages,
  setFormData,
  formData,
  endorsementNoteCopy,
  handleScopeNoteCopy,
  scopeNoteCopy,
}) => {
  const externaITILURL =
    "https://eu-assets.contentstack.com/v3/assets/blt637b065823946b12/bltce88ffa4d3ed241b/Axelos_Maturity_Level_Certification_2021.pdf";

  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <Section>
            <Headings
              title="Assessment profile set-up"
              subtitle="Assessment details"
            />
            <Form.Wrapper<AssessmentProfileStatesType>
              onSubmit={handleSubmit}
              state={formStates}
              schema={getAssessmentProfileSchema(false)}
              mode={"all"}
            >
              {({
                control,
                register,
                errors,
                formState: { isValid, isSubmitting, isDirty },
                getValues,
                trigger,
                watch,
              }) => {
                watch(["assessmentType", "selectedScope"]);

                const formValues = getValues();

                return (
                  <>
                    <Form.Fieldset assessmentSetup>
                      <QuestionAssessmentProfile
                        control={control}
                        register={register}
                        error={errors}
                        setDisplayNote={setDisplayNote}
                        displayNoteCopy={endorsementNoteCopy}
                        scopeNoteCopy={scopeNoteCopy}
                        hasScope={hasScope}
                        hasAssessmentStarted={hasAssessmentStarted}
                        selectedMaturityModel={"ITIL"}
                        selectedScope={formValues.selectedScope}
                        handleFormData={() => {
                          const updatedData = getValues();
                          setFormData({
                            endorsed: !!updatedData.endorsed,
                            certified: false,
                            selectedScope: updatedData.selectedScope,
                            assessmentType: updatedData.assessmentType,
                          });
                          handleScopeNoteCopy(updatedData.selectedScope);
                        }}
                      />
                    </Form.Fieldset>
                    <Form.Actions>
                      <Button
                        isLoading={isSavingAssessment}
                        label="Save &#38; Exit"
                        style={ButtonStyle.SECONDARY}
                        type={ButtonType.BUTTON}
                        onClick={async () => {
                          await trigger();
                          isValid && handleSaveAndExit(getValues());
                        }}
                      >
                        <ExitSVG />
                      </Button>
                      <Button
                        disabled={!isValid || (!isValid && !isDirty)}
                        label="Save &#38; Continue"
                        style={ButtonStyle.PRIMARY}
                        type={ButtonType.SUBMIT}
                        isLoading={isSubmitting}
                      />
                    </Form.Actions>
                  </>
                );
              }}
            </Form.Wrapper>
          </Section>

          <Sidebar>
            <OrganizationDetails
              name={organization.name}
              country={organization.country.name}
              businessUnit={organization.businessUnit}
            ></OrganizationDetails>

            {formData.endorsed && formData.selectedScope === "Maturity" && (
              <Note>
                <h3>Statement of results</h3>
                <p>
                  More information about obtaining a statement of results for
                  ITIL® Service Value System (Maturity) Assessments can be found
                  in the{" "}
                  <a href={externaITILURL} target="_new">
                    ITIL Maturity Model Assessors Guide
                  </a>
                </p>
              </Note>
            )}

            {formData.endorsed && formData.selectedScope === "Capability" && (
              <Note>
                <h3>Statement of results</h3>
                <p>
                  More information about obtaining a statement of results for
                  ITIL® Capability Assessments can be found in the{" "}
                  <a href={externaITILURL} target="_new">
                    ITIL Maturity Model Assessors Guide
                  </a>
                </p>
              </Note>
            )}

            {formData.endorsed && formData.selectedScope === "Comprehensive" && (
              <Note>
                <h3>Certify this assessment</h3>
                <p>
                  More information about obtaining an ITIL® Maturity Certificate
                  for comprehensive assessments can be found in the{" "}
                  <a href={externaITILURL} target="_new">
                    ITIL Maturity Model Assessors Guide
                  </a>
                </p>
              </Note>
            )}

            <Progress.Simple
              title="Your Progress"
              subtitle="Assessment profile set-up"
              company={organization.name}
              pages={progressBarPages}
              assessmentId={assessmentId}
              organizationId={organization.id ? organization.id : 0}
            />
          </Sidebar>
        </React.Fragment>
      )}
      {isLoading && <Loading noBackground />}
    </React.Fragment>
  );
};

export default Template;
