import React, { useState } from "react";

import styles from "./styles.module.scss";

type InfoProps = {
  children?: React.ReactNode;
};

const Info: React.FunctionComponent<InfoProps> = ({ children }) => {
  const [isHover, setHover] = useState(false);
  return (
    <div className={styles.TooltipContainer}>
      <div
        className={styles.tooltip}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div>?</div>
        {isHover && <span>{children}</span>}
      </div>
    </div>
  );
};

export default Info;
