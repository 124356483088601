import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { api, API } from "modules/api";
import { AssessmentGetResponseResult } from "modules/api/endpoints/assessmentGet";

import { ProgressBarPageType } from "components/Progress/Simple/index";
import { ProgressPages } from "./constants";

import { ErrorMessagesType, assessmentErrorMessages } from "modules/messages";
import { useModal } from "modules/hooks/modal";

import Template from "./Template";
import UseLockAssessment from "routes/questions/hooks/useLockAssessment";
import { getErrorMessages } from "modules/messages/helpers";
import MetaTitle from "components/MetaTitle";

type assessmentParam = {
  assessmentId: string;
};

const ReviewAssessment: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [assessment, setAssessment] = useState<AssessmentGetResponseResult>(
    {} as AssessmentGetResponseResult
  );
  const [progressBarPages, setProgressBarPages] = useState<
    ProgressBarPageType[]
  >([]);

  const errorConfirmation = useModal();

  const {
    params: { assessmentId },
  } = useRouteMatch<assessmentParam>();

  const history = useHistory();

  useEffect(() => {
    return () => {
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (!isAssessmentValid(assessmentId)) {
      return history.push("/");
    }

    const getAssessmentAndSetStates = async () => {
      try {
        const response = await api(API.GET_ASSESSMENT(assessmentId)).then(
          (res) => res.data
        );
        if (!response && isCancelled) return history.push("/");

        setAssessment(response);
        setProgressBarPages(() => {
          const patchPages = [...ProgressPages];
          const displayReviewNav =
            response.perspectives.length > 0 && response.threads.length > 0;

          patchPages[2].isActive = true;

          if (displayReviewNav) {
            return patchPages.map((p) => {
              return {
                ...p,
                isEnabled: true,
                isComplete: p.id !== 3,
              };
            });
          }

          return [...patchPages];
        });
        setIsLoading(false);
      } catch (error: any) {
        const messages = getErrorMessages(
          assessmentErrorMessages,
          error?.response?.status
        );
        await errorConfirmation({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description,
        }).finally(() => apiErrorRedirection(error?.response?.status));
      }
    };
    getAssessmentAndSetStates();
    return () => {
      isCancelled = true;
    };
  }, [assessmentId]);

  const apiErrorRedirection = (error = 400) => {
    if (error && error === 401) {
      window.location.assign("https://www.axelos.com/");
      return;
    }
    history.push("/");
  };

  const isAssessmentValid = useCallback((assemt: string) => {
    return !!assemt;
  }, []);

  const handleStartAssessment = () => {
    const firstThreadCode = assessment?.threads[0].code;
    const firstPerspectiveCode = assessment?.perspectives[0].code;

    history.push(
      `/assessments/${assessmentId}/questions/${firstPerspectiveCode}/${firstThreadCode}`
    );
  };

  return (
    <React.Fragment>
      <MetaTitle title="Review Assessment Profile | Axelos Maturity Assessment Tool" />
      <Template
        isLoading={isLoading}
        assessment={assessment}
        handleStartAssessment={handleStartAssessment}
        progressBarPages={progressBarPages}
      />
    </React.Fragment>
  );
};

export default ReviewAssessment;
