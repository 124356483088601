import React from "react";
import styles from "./styles.module.scss";

type LoadingProps = {
  noBackground?: boolean;
};

const Loading: React.FunctionComponent<LoadingProps> = ({ noBackground }) => {
  return (
    <div
      className={`${styles.spinner__wrapper} ${
        noBackground ? styles.noBackground : ""
      }`}
    >
      <div className={styles.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
