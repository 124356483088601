import React from "react";

import styles from "../../styles.module.scss";
import { PerspectiveType } from "modules/api/endpoints/perspectivesGet";
import { Assessment } from "../../types";
type StatusProps = {
  children?: React.ReactNode;
  assessment: Assessment;
  perspectives: PerspectiveType[];
};

const Status: React.FunctionComponent<StatusProps> = ({
  assessment,
  perspectives,
}) => {
  return (
    <div>
      {!!assessment.perspectives.length &&
        perspectives.map((p) => {
          const filteredPerspective = assessment.perspectives.filter(
            (fp) => fp.id === p.id
          )[0];
          const totalQuestionsNumber = 5;

          const answeredQuestions = Math.floor(
            filteredPerspective?.answeredQuestions / totalQuestionsNumber
          );
          const totalPerspectives = Math.round(
            filteredPerspective?.totalQuestions / totalQuestionsNumber
          );

          const handleClassType = (answeredQ: number, total: number) => {
            if (answeredQ === total) return styles.tag_done;

            return styles.tag_warning;
          };

          return filteredPerspective ? (
            <div key={p.id}>
              {answeredQuestions}/{totalPerspectives}
              <span
                className={handleClassType(
                  answeredQuestions,
                  totalPerspectives
                )}
                role="progressbar"
                aria-valuenow={(answeredQuestions / totalPerspectives) * 100}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{
                  width: `${(answeredQuestions / totalPerspectives) * 100}%`,
                }}
              />
            </div>
          ) : (
            <div key={p.id}>
              {"N/A"}
              <span
                className={styles.tag_white}
                role="progressbar"
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{
                  width: "100%",
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default React.memo(Status);
