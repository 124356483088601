import React from "react";
import { Switch } from "react-router-dom";
import { Router as ReactRouter } from "react-router";

import { history } from "modules/redux/store";
import { ROUTE } from "./constants";

import Route from "./route";
import AuthComponent from "routes/auth";

import RouteApp from "routes/app";
import RouteDashboard from "routes/dashboard";
import RouteDebug from "routes/debug";
import * as RouteAssessment from "routes/assessments";
import RouteOrganization from "routes/organization";
import RouteQuestions from "routes/questions";
import RouteQuestionsSummary from "routes/questions/Summary";
import RouteQuestionsItilSummary from "routes/questions/Summary/Itil";
import ItilScope from "routes/questions/Itil";

const Router: React.FunctionComponent = () => {
  return (
    <ReactRouter history={history}>
      <RouteApp>
        <Switch>
          <Route exact path={ROUTE.DASHBOARD} component={RouteDashboard} />

          <Route
            path={ROUTE.DASHBOARD_TOKEN}
            component={AuthComponent}
            isPrivate={false}
            exact
          />

          <Route exact path={ROUTE.DEBUG} component={RouteDebug} />

          <Route
            exact
            path={ROUTE.ASSESSMENT_PROFILE}
            component={RouteAssessment.Profile}
          />

          <Route
            exact
            path={ROUTE.ASSESSMENT_SCOPE}
            component={RouteAssessment.Scope}
          />

          <Route
            exact
            path={ROUTE.ASSESSMENT_REVIEW}
            component={RouteAssessment.Review}
          />

          <Route
            exact
            path={ROUTE.ASSESSMENT_RESULT}
            component={RouteAssessment.Result}
          />

          <Route
            exact
            path={ROUTE.ASSESSMENT_PROFILE_ITIL}
            component={RouteAssessment.ItilProfile}
          />

          <Route
            exact
            path={ROUTE.ASSESSMENT_SCOPE_ITIL}
            component={RouteAssessment.ItilScope}
          />

          <Route
            exact
            path={ROUTE.ASSESSMENT_REVIEW_ITIL}
            component={RouteAssessment.ItilReview}
          />

          <Route
            exact
            path={ROUTE.QUESTIONS_ITIL_SUMMARY}
            component={RouteQuestionsItilSummary}
          />

          <Route
            exact
            path={ROUTE.ASSESSMENT_RESULT_ITIL}
            component={RouteAssessment.ItilResult}
          />

          <Route exact path={ROUTE.QUESTIONS} component={RouteQuestions} />
          <Route
            exact
            path={ROUTE.QUESTIONS_SUMMARY}
            component={RouteQuestionsSummary}
          />

          <Route
            exact
            path={ROUTE.ORGANIZATION}
            component={RouteOrganization}
          />

          <Route
            exact
            path={ROUTE.ORGANIZATION_EDIT}
            component={RouteOrganization}
          />

          <Route
            exact
            path={ROUTE.ORGANIZATION_ASSESSMENT}
            component={RouteAssessment.Profile}
          />

          <Route exact path={ROUTE.QUESTIONS_ITIL} component={ItilScope} />

          <Route path="/redirect" component={AuthComponent} isPrivate={false} />
        </Switch>
      </RouteApp>
    </ReactRouter>
  );
};

export default Router;
