import React from "react";
import { Control, FieldErrors } from "react-hook-form";

import * as Form from "components/Form";
import Input from "components/Inputs/Input";
import RadioInput from "components/Inputs/Radio";
import SwitchButton from "components/Inputs/Switch";
import Select from "components/Inputs/Select";

import styles from "./styles.module.scss";
import { useAuth } from "modules/hooks/auth";

type QuestionAssessmentProfileProps = {
  error?: FieldErrors;
  control: Control;
  register: React.Ref<HTMLInputElement> | undefined;
  setDisplayNote: (certified: boolean) => void;
  hasScope?: boolean;
  hasAssessmentStarted: boolean;
  selectedMaturityModel?: string;
  selectedScope?: string;
  handleFormData: () => void;
  displayNoteCopy?: string[];
  scopeNoteCopy?: {
    title: string;
    text: string;
  };
};

const assessmentScopes = [
  { label: "Project", value: "Project" },
  { label: "Programme", value: "Programme" },
  { label: "Portfolio", value: "Portfolio" },
];

const itilAssessmentScopes = [
  { label: "Comprehensive", value: "Comprehensive" },
  { label: "Capability", value: "Capability" },
  { label: "Maturity", value: "Maturity" },
];

// const maturityModelOptions = [
//   { label: "ITIL", value: "ITIL" },
//   { label: "P3M3", value: "P3M3" },
// ];

const QuestionAssessmentProfile: React.FC<QuestionAssessmentProfileProps> = ({
  error,
  control,
  register,
  setDisplayNote,
  hasAssessmentStarted,
  selectedMaturityModel,
  selectedScope,
  hasScope,
  handleFormData,
  displayNoteCopy,
  scopeNoteCopy,
}) => {
  const { roles } = useAuth();

  const maturityModelOptions = roles.map((role) => {
    return { label: role, value: role };
  });

  const isItil = selectedMaturityModel && selectedMaturityModel === "ITIL";

  const scopeOptions = isItil ? itilAssessmentScopes : assessmentScopes;

  const certifyCopy = [
    "By selecting this option you also confirm you have received consent from the end customer to share data with AXELOS.",
    !isItil
      ? `Certifying this assessment will require all perspectives and four mandatory threads to be assessed.`
      : "",
  ];

  return (
    <>
      <Form.Control
        isInvalid={!!error && error["name"]}
        error={error && error["name"] ? error["name"] : null}
        label="Assessment name"
        fieldId="name"
        required
      >
        <Input control={control} name="name" placeholder="Assessment Name" />
      </Form.Control>

      <Form.Control
        isInvalid={!!error && error["assessmentType"]}
        error={
          error && error["assessmentType"] ? error["assessmentType"] : null
        }
        label={roles.length > 1 ? "Select Maturity Model" : "Maturity Model"}
        fieldId="assessmentType"
        required
      >
        <Select
          disabled={hasScope}
          name={"assessmentType"}
          control={control}
          options={maturityModelOptions}
          handleOnChange={() => {
            handleFormData();
          }}
        />
      </Form.Control>
      {selectedMaturityModel && (
        <>
          <Form.Control
            required
            label="Please select the scope of the assessment"
            sublabel="Select one scope per assessment"
            fieldId="selectedScope"
            error={
              error && error["selectedScope"] ? error["selectedScope"] : null
            }
            isInvalid={!!error && error["selectedScope"]}
            readOnly={hasAssessmentStarted}
          >
            <RadioInput
              isInvalid={!!error && error["selectedScope"]}
              control={control}
              ref={register}
              name="selectedScope"
              options={scopeOptions}
              onChange={() => {
                handleFormData();
              }}
            />
          </Form.Control>
          {isItil && scopeNoteCopy && scopeNoteCopy.title !== "" && (
            <div className={styles.extra_info}>
              <h4>{scopeNoteCopy.title}</h4>
              <p>{scopeNoteCopy.text}</p>
            </div>
          )}
          {!isItil && (
            <SwitchButton
              label={"Certify this assessment"}
              id="certified"
              name={"certified"}
              type="checkbox"
              ref={register}
              copy={certifyCopy}
              onChange={() => handleFormData()}
            />
          )}

          {isItil && (
            <SwitchButton
              label={displayNoteCopy?.length ? displayNoteCopy[0] : ""}
              id="endorsed"
              name={"endorsed"}
              type="checkbox"
              ref={register}
              copy={displayNoteCopy?.length ? displayNoteCopy.slice(1) : []}
              onChange={() => handleFormData()}
            />
          )}
        </>
      )}
    </>
  );
};

export default QuestionAssessmentProfile;
