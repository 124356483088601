export const CertifiedThreads = [
  { id: 6, name: "Information and knowledge management", code: "IKM" },
  { id: 9, name: "Organization", code: "ORG" },
  { id: 11, name: "Process", code: "PRO" },
  { id: 12, name: "Standards", code: "STD" },
];

export const CertifiedThreadsCode = CertifiedThreads.map(
  (thread) => thread.code
);

export const TotalPerspectivesNumber = 7;

export const Levels = [
  { label: "Level 1", value: "1" },
  { label: "Level 2", value: "2" },
  { label: "Level 3", value: "3" },
  { label: "Level 4", value: "4" },
  { label: "Level 5", value: "5" },
];

export const ProgressPages = [
  {
    id: 1,
    isEnabled: true,
    isActive: false,
    isComplete: true,
    name: "Assessment details",
    route: "profile",
  },
  {
    id: 2,
    isEnabled: true,
    isActive: true,
    isComplete: false,
    name: "Scope details",
    route: "scope",
  },
  {
    id: 3,
    isEnabled: true,
    isActive: false,
    isComplete: false,
    name: "Review profile",
    route: "review",
  },
];
