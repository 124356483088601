import React from "react";

import styles from "./styles.module.scss";

type OverallScoreProps = {
  decimalScore?: number;
  maturityScore?: number;
};

const OverallScore: React.FunctionComponent<OverallScoreProps> = ({
  decimalScore,
  maturityScore,
}) => {
  return (
    <section className={styles["overall-section"]}>
      {decimalScore?.toString() && (
        <div>
          <h4>{decimalScore?.toFixed(2)}</h4>
          <span>
            Current <br /> decimal score
          </span>
        </div>
      )}
      {maturityScore?.toString() && (
        <div>
          <h4>{maturityScore?.toFixed()}</h4>
          <span>
            Current <br /> maturity score
          </span>
        </div>
      )}
    </section>
  );
};

export default React.memo(OverallScore);
