import React from "react";

import styles from "./styles.module.scss";

type WrappertProps = {
  children?: React.ReactNode;
  title?: string;
};

const PageWrapper: React.FunctionComponent<WrappertProps> = ({
  title,
  children,
}) => {
  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
      )}
      {children}
    </div>
  );
};

export default React.memo(PageWrapper);
