import React, { useEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { useAuth } from "modules/hooks/auth";

import Loading from "components/Loading";

type AuthParams = {
  token: string;
};

const AuthComponent: React.FC = () => {
  const redirectURI = "https://www.axelos.com/";
  const {
    params: { token },
  } = useRouteMatch<AuthParams>();
  const location = useLocation();
  const { fetchAuth, fetchRefreshToken } = useAuth();

  useEffect(() => {
    if (token) {
      const getAuth = async () => {
        const response = await fetchAuth(token);

        if (response) {
          await fetchRefreshToken();
          return;
        }

        window.location.assign(redirectURI);
      };

      getAuth();

      return;
    }

    if (location.pathname === "/redirect") {
      window.location.assign(redirectURI);
    }
  }, []);

  return <Loading noBackground />;
};

export default AuthComponent;
