import React, { useContext } from "react";
import clsx from "clsx";

import PlatformContext from "modules/platform/context";
import { ButtonSize, ButtonStyle, ButtonTarget, ButtonType } from "./constants";

import { ReactComponent as ButtonSpinner } from "modules/theme/icons/buttons/spinner.svg";

import styles from "./styles.module.scss";

export { ButtonSize, ButtonStyle, ButtonTarget, ButtonType };

type ButtonProps = {
  ariaLabel?: string;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  id?: string;
  label?: string;
  onClick?: () => void;
  readOnly?: boolean;
  size?: ButtonSize;
  style?: ButtonStyle;
  tabIndex?: number;
  target?: ButtonTarget;
  type?: ButtonType;
  isLoading?: boolean;
  benchMarking?: boolean;
};

const Button: React.FunctionComponent<ButtonProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  href,
  id,
  label,
  onClick,
  readOnly,
  size,
  style,
  tabIndex,
  target,
  type,
  isLoading,
  benchMarking,
}) => {
  const buttonClassName = clsx(
    className && styles[className],
    style && styles["button"],
    styles[`${style}`],
    styles[`${size}`],
    disabled && styles["disabled"],
    readOnly || (isLoading && styles["read-only"]),
    isLoading && styles["loading"],
    benchMarking && "image-export-benchmark"
  );

  const { isSafari } = useContext(PlatformContext);
  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    if (type !== ButtonType.SUBMIT && !disabled && !readOnly) {
      onClick && onClick();
    }
    if (type === ButtonType.SUBMIT && !disabled && !readOnly) {
      event.currentTarget.closest("form")?.dispatchEvent(
        new Event("submit", {
          bubbles: true,
          cancelable: true,
        })
      );
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.key === "Enter" && handleClick(event);
  };

  const handleMouseDown = (
    event: React.MouseEvent<
      HTMLButtonElement | HTMLDivElement | HTMLAnchorElement
    >
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (
    event:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    (readOnly || disabled) && event.preventDefault();
  };

  const ariaLabelCheck = ariaLabel ? ariaLabel : label;
  const buttonType = type === ButtonType.SUBMIT ? "submit" : "button";

  const handleTabIndex = () => {
    if (disabled || readOnly) return -1;
    return tabIndex ? tabIndex : 0;
  };

  if (type === ButtonType.LINK)
    return (
      <a
        aria-label={ariaLabelCheck}
        className={buttonClassName}
        id={id}
        href={href}
        onMouseDown={handleMouseDown}
        rel="noopener"
        role="button"
        tabIndex={handleTabIndex()}
        target={target}
      >
        {children}
        {label && <span>{label}</span>}
      </a>
    );

  if (isSafari)
    return (
      <div
        aria-label={ariaLabelCheck}
        className={buttonClassName}
        id={id}
        onClick={type === ButtonType.SUBMIT ? handleClick : onClick}
        onKeyUp={type === ButtonType.SUBMIT ? handleKeyUp : onClick}
        onMouseDown={handleMouseDown}
        role="button"
        tabIndex={handleTabIndex()}
      >
        {children}
        {label && <span>{label}</span>}
        {isLoading && <ButtonSpinner className={styles.spinner} />}
      </div>
    );

  return (
    <button
      aria-label={ariaLabelCheck}
      className={buttonClassName}
      disabled={disabled}
      id={id}
      onClick={type === ButtonType.SUBMIT ? handleSubmit : onClick}
      onMouseDown={handleMouseDown}
      tabIndex={handleTabIndex()}
      type={buttonType}
    >
      {children}
      {label && <span>{label}</span>}
      {isLoading && <ButtonSpinner className={styles.spinner} />}
    </button>
  );
};

export default React.memo(Button);
