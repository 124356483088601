import { Debug } from "./types";

export const debug: Debug = {
  questionAnswered: "John Doe",
  questionCheckboxHorizontal: [],
  questionCheckboxVertical: [],
  questionRadioHorizontal: "",
  questionRadioVertical: "",
  questionRequired: "",
  questionSelect: "",
  questionSwitch: "",
  questionSwitchSimple: "",
  questionText: "",
  questionTextInfo: "",
  questionCheckboxHorizontalReadonly: ["option1", "option2"],
  questionCheckboxVerticalReadonly: ["option1", "option2"],
  questionRadioHorizontalReadonly: "option1",
  questionRadioVerticalReadonly: "option1",
  questionSelectReadonly: "option2",
  questionSwitchReadonly: true,
  questionSwitchSimpleReadonly: false,
  questionTextReadonly: "John Doe",
  questionTextInfoReadonly: "John Doe",
};
