import React from "react";
import clsx from "clsx";

import { htmlSafe } from "modules/utils";

import styles from "./styles.module.scss";

type LabelProps = {
  disabled?: boolean;
  id: string | undefined;
  label: string;
  readOnly?: boolean;
  required?: boolean;
};

const Label: React.FunctionComponent<LabelProps> = ({
  disabled,
  id,
  label,
  readOnly,
  required,
}) => {
  const className = clsx(
    styles["label"],
    disabled && styles["disabled"],
    readOnly && styles["read-only"]
  );
  return (
    <label className={className} htmlFor={id}>
      {label && htmlSafe(label)}
      {required && <span>*</span>}
    </label>
  );
};

export default Label;
