import clsx from "clsx";
import React from "react";
import styles from "./styles.module.scss";
type ChartsWrapperProps = {
  children?: React.ReactNode;
  hasTab?: boolean;
};

const ChartContainer: React.FunctionComponent<ChartsWrapperProps> = ({
  children,
  hasTab,
}) => {
  const className = clsx(styles["ChartsContainer"], hasTab && styles["hasTab"]);
  return <div className={className}>{children}</div>;
};

export default ChartContainer;
