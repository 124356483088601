import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";
import { Control } from "react-hook-form";

import styles from "./styles.module.scss";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  control?: Control;
  label?: string;
  copy?: string[];
  small?: string;
}

const SwitchButton = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const classNames = clsx(
      styles["fieldset"],
      !!props.small && styles["inline"]
    );

    return (
      <div className={classNames}>
        {props.label && <h4>{props.label}</h4>}
        <label className={styles.label} htmlFor={props.id}>
          <input {...props} type="checkbox" tabIndex={0} ref={ref} />
          <span></span>
          {props.copy && (
            <div>
              {props.copy.map((p) => (
                <p key={p}>{p}</p>
              ))}
            </div>
          )}
        </label>
      </div>
    );
  }
);

export default React.memo(SwitchButton);
