import React from "react";
import { Radar as Chart } from "react-chartjs-2";

const baseOptions = {
  scale: {
    ticks: { beginAtZero: true, max: 5 },
    pointLabels: {
      fontSize: 12,
    },
  },
  legend: {
    display: true,
    labels: {
      fontSize: 14,
    },
  },
};

type DataSet = {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
};

export type ChartData = {
  labels: string[];
  datasets: DataSet[];
};

type RadarProps = {
  children?: React.ReactNode;
  data: ChartData;
  options?: any;
};

const Radar: React.FunctionComponent<RadarProps> = ({ data, options }) => {
  return (
    <React.Fragment>
      <Chart data={data} options={options ? options : baseOptions} />
    </React.Fragment>
  );
};

export default Radar;
