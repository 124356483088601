import React from "react";

import Button from "./Button";
import { ButtonsType } from "./constants";
import { ButtonRadioProps } from "./types";

const ButtonRadio: React.FunctionComponent<ButtonRadioProps> = ({
  id,
  index,
  onClick,
  option,
  tabIndex,
  value,
  ...props
}) => {
  const handleId = () => {
    if (value && value === option.value) return id;
    if (index === 0) return id;
  };
  const handleTabIndex = () => {
    if (value && value === option.value) return 0;
    if (value && value !== option.value) return -1;
    return tabIndex;
  };
  return (
    <Button
      {...props}
      id={handleId()}
      label={option.label}
      statement={option.statement ? option.statement : ""}
      onClick={() => {
        if (value === option.value) return;
        onClick(option.value);
      }}
      selected={value === option.value}
      tabIndex={handleTabIndex()}
      type={ButtonsType.RADIO}
    />
  );
};

export default React.memo(ButtonRadio);
