import React from "react";
import SortingItem from "./ScoringItem";
import styles from "./styles.module.scss";

type FormItemType = {
  code: string;
  id: number;
  name: string;
};

type ProgressPracticeType = {
  complete: boolean;
  currentScore: number;
  id: number;
  name: string;
  code: string;
  started: boolean;
};

export type ProgressType = {
  capabilityProgress: ProgressPracticeType[];
  maturityProgress: ProgressPracticeType[];
};

type ScoringProps = {
  title: string;
  progress: ProgressType;
  currentPractice?: FormItemType | null;
  children?: React.ReactNode;
  capabilities: FormItemType[];
  maturities: FormItemType[];
};

const Scoring: React.FunctionComponent<ScoringProps> = ({
  title,
  progress,
  currentPractice,
}) => {
  return (
    <section className={styles["progress"]}>
      <h3>{title}</h3>
      <nav>
        <ul>
          <li>
            <h2>Capability</h2>
            <SortingItem
              data={progress.capabilityProgress}
              activePractice={currentPractice}
            />
          </li>
          {!!progress.maturityProgress?.length && (
            <li>
              <h2>Maturity</h2>
              <SortingItem
                data={progress.maturityProgress}
                activePractice={currentPractice}
              />
            </li>
          )}
        </ul>
      </nav>
    </section>
  );
};

export default Scoring;
