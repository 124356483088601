import React from "react";

import Button, { ButtonStyle, ButtonType } from "components/Button";
import QuestionsDebug from "modules/debug/questions";
import ButtonsDebug from "modules/debug/buttons";
import { Debug } from "modules/debug/types";
import * as Form from "components/Form";
import yup from "modules/validation";
import Section from "components/Section";

type TemplateProps = {
  children?: React.ReactNode;
  debug: Debug;
  handleSubmit: (data: Debug) => void;
  // eslint-disable-next-line
  schema: yup.ObjectSchema<any>;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  debug,
  handleSubmit,
  schema,
}) => {
  return (
    <Section>
      <h1>Debug</h1>
      <Form.Wrapper
        onSubmit={handleSubmit}
        schema={schema}
        state={debug}
        mode={"all"}
      >
        {({ control }) => (
          <React.Fragment>
            <QuestionsDebug control={control} />

            <QuestionsDebug control={control} readOnly={true} />

            <QuestionsDebug control={control} disabled={true} />

            <ButtonsDebug />

            <ButtonsDebug readOnly={true} />

            <ButtonsDebug disabled={true} />

            <Button
              label="Submit"
              style={ButtonStyle.PRIMARY}
              type={ButtonType.SUBMIT}
            />
          </React.Fragment>
        )}
      </Form.Wrapper>
    </Section>
  );
};

export default Template;
