import React from "react";
import { useController, Control } from "react-hook-form";

import { OptionType, SelectPrimitive } from "components/Inputs/Select";
import { QuestionTemplateProps } from "../QuestionTemplate/types";
import QuestionTemplate from "../QuestionTemplate";
import Debug from "../QuestionTemplate/Debug";
import { Expand } from "modules/typescript";
import { SelectProps } from "components/Inputs/Select/types";

type QuestionSelectProps = Expand<
  {
    control: Control;
    options?: OptionType[];
  } & Omit<QuestionTemplateProps, "answered" | "children" | "invalid"> &
    Omit<SelectProps, "id" | "invalid">
>;

const QuestionSelect: React.FunctionComponent<QuestionSelectProps> = ({
  control,
  name,
  options,
  required,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });
  return (
    <QuestionTemplate
      answered={field.value && !invalid}
      invalid={invalid}
      name={name}
      required={required}
      {...props}
    >
      <SelectPrimitive
        invalid={invalid}
        options={options}
        {...props}
        {...field}
      />
      {process.env.NODE_ENV === "development" && <Debug value={field.value} />}
    </QuestionTemplate>
  );
};

export default React.memo(QuestionSelect);
