import React, { useState, useEffect, useCallback } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";

import {
  AssessmentProfileStatesType,
  OrganizationParams,
  FormDataType,
  ScopeNoteType,
} from "./types";
import { ProgressBarPageType } from "components/Progress/Simple/index";
import { useModal } from "modules/hooks/modal";
import { progressPages } from "./constants";

import { useAuth } from "modules/hooks/auth";

import { api, API } from "modules/api";
import {
  ErrorMessagesType,
  assessmentErrorMessages,
  organizationErrorMessages,
} from "modules/messages";
import {
  GetOrganizationResponseResult,
  Organization,
} from "modules/api/endpoints/organizationGet";
import { AssessmentGetResponseResult } from "modules/api/endpoints/assessmentGet";

import Template from "./Template";
import UseLockAssessment from "routes/questions/hooks/useLockAssessment";
import { getErrorMessages } from "modules/messages/helpers";
import MetaTitle from "components/MetaTitle";

enum FormType {
  CREATE = "CREATE",
  EDIT = "EDIT",
}

const AssessmentProfile: React.FunctionComponent = () => {
  const { roles } = useAuth();
  const [organization, setOrganization] = useState<Organization>(
    {} as Organization
  );
  const [assessmentProfile, setAssessmentProfile] =
    useState<AssessmentProfileStatesType>(() => {
      if (roles.length === 1) {
        return {
          assessmentType: roles[0],
          certified: false,
          name: "",
          selectedScope: "",
        };
      }
      return {} as AssessmentProfileStatesType;
    });
  const [fullAssessment, setFullAssessment] =
    useState<AssessmentGetResponseResult>({} as AssessmentGetResponseResult);
  const [formType, setFormType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingAssessment, setIsSavingAssessment] = useState(false);
  const [hasScope, setHasScope] = useState(roles.length === 1);
  const [displayNote, setDisplayNote] = useState<boolean>(false);
  const [hasAssessmentStarted, setHasAssessmentStarted] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<FormDataType>({
    assessmentType: "",
    selectedScope: "",
    endorsed: false,
    certified: false,
  });

  const [endorsementNoteCopy, setEndorsementNoteCopy] = useState<string[]>([]);

  const [scopeNoteCopy, setScopeNoteCopy] = useState<ScopeNoteType>(
    {} as ScopeNoteType
  );

  const [progressBarPages, setProgressBarPages] = useState<
    ProgressBarPageType[]
  >([]);

  const {
    params: { organizationId, assessmentId },
  } = useRouteMatch<OrganizationParams>();
  const dialog = useModal();
  const history = useHistory();

  useEffect(() => {
    return () => {
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (organizationId) {
      const fetchOrganization = async () => {
        try {
          const response: GetOrganizationResponseResult = await api(
            API.GET_ORGANIZATION(organizationId)
          ).then((res) => res.data);

          if (!response && isCancelled) history.push("/");

          setFormType(FormType.CREATE);
          setProgressBarPages(() => [...progressPages]);
          setOrganization(response.organization);
          setIsLoading(false);
        } catch (error: any) {
          const messages = getErrorMessages(
            organizationErrorMessages,
            error?.response?.status
          );
          await dialog({
            type: "error",
            catchOnCancel: true,
            title: messages.title,
            description: messages.description,
          }).finally(() => apiErrorRedirection(error?.response?.status));
        }
      };

      fetchOrganization();
    }
    return () => {
      isCancelled = true;
    };
  }, [organizationId]);

  useEffect(() => {
    let isCancelled = false;
    if (!isValidId(assessmentId)) {
      return;
    }
    const fetchAssessment = async () => {
      try {
        const response: AssessmentGetResponseResult = await api(
          API.GET_ASSESSMENT(assessmentId)
        ).then((res) => res.data);

        if (!response && isCancelled) history.push("/");

        const { name, certified, selectedScope, assessmentType, status } =
          response;

        const patchData = {
          name,
          certified,
          selectedScope,
          assessmentType,
        };

        setFullAssessment(response);
        setOrganization(response.organization);
        setDisplayNote(certified);
        setFormData(() => {
          return {
            assessmentType: assessmentType,
            selectedScope: selectedScope,
            endorsed: false,
            certified: certified,
          };
        });
        setAssessmentProfile(patchData);

        if (selectedScope) {
          setHasScope(true);
        }

        if (status === "Active") {
          setHasAssessmentStarted(true);
        }

        setProgressBarPages(() => {
          const patchPages = [...progressPages];
          const displayReviewNav =
            response.perspectives.length > 0 && response.threads.length > 0;

          if (displayReviewNav) {
            return patchPages.map((p) => {
              return {
                ...p,
                isEnabled: true,
                isComplete: p.id !== 1,
              };
            });
          }

          return patchPages.map((p) => {
            return {
              ...p,
              isEnabled: true,
            };
          });
        });

        setFormType(FormType.EDIT);
        setIsLoading(false);
      } catch (error: any) {
        const messages = getErrorMessages(
          assessmentErrorMessages,
          error?.response?.status
        );
        await dialog({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description,
        }).finally(() => apiErrorRedirection(error?.response?.status));
      }
    };

    fetchAssessment();
    return () => {
      isCancelled = true;
    };
  }, [assessmentId]);

  const isValidId = useCallback((idValue: string) => {
    return idValue;
  }, []);

  const apiErrorRedirection = (error: number) => {
    if (error && error === 401) {
      window.location.assign("https://www.axelos.com/");
      return;
    }
    history.push("/");
  };

  const handleEndorsementNoteCopy = (scope: string) => {
    let copy: string[];

    switch (scope) {
      case "Comprehensive":
        copy = [
          "Include an ITIL® Maturity Certificate with this assessment",
          "By selecting this option, you also confirm you have received consent from the end customer to share data with Axelos.",
          "Please note that certification is subject to an additional fee.",
        ];
        break;
      case "Capability":
        copy = [
          "Include an ITIL® Capability Assessment – Statement of Result",
          "By selecting this option, you also confirm you have received consent from the end customer to share data with Axelos.",
          "Please note that statements of results are subject to an additional fee.",
        ];
        break;
      case "Maturity":
        copy = [
          "Include an ITIL® Service Value System Assessment – Statement of Result",
          "By selecting this option, you also confirm you have received consent from the end customer to share data with Axelos.",
          "Please note that statements of results are subject to an additional fee.",
        ];
        break;
      default:
        copy = [
          "Request independent validation for this assessment",
          "By selecting this option, you also confirm you have received consent from the end customer to share data with Axelos.",
          "Please note that validation by Axelos is subject to an additional fee.",
        ];
    }

    setEndorsementNoteCopy(copy);
  };

  const handleScopeNoteCopy = (scope: string) => {
    const scopeInfo = {
      title: "",
      text: "",
    };

    switch (scope) {
      case "Comprehensive":
        scopeInfo.title = "Comprehensive definition";
        scopeInfo.text =
          "An assessment that considers the capabilities of seven or more practices and the maturity of the service value system (SVS)";
        break;
      case "Capability":
        scopeInfo.title = "Capability definition";
        scopeInfo.text =
          "An assessment that only addresses the capability of one or more selected practices, and does not assess the maturity of the service value system (SVS)";
        break;
      case "Maturity":
        scopeInfo.title = "Maturity definition";
        scopeInfo.text =
          "An assessment that considers the capabilities of six or fewer practices and the maturity of the service value system (SVS)";
        break;
      default:
        scopeInfo.title = "";
        scopeInfo.text = "";
        break;
    }
    setScopeNoteCopy(scopeInfo);
  };

  const handleSaveAndExit: SubmitHandler<AssessmentProfileStatesType> = async (
    data
  ) => {
    setIsSavingAssessment(true);

    if (formType === FormType.CREATE) {
      try {
        const response = await createAssessment(data);
        if (response) {
          history.push({
            pathname: "/",
          });
        }
      } catch (error) {
        setIsSavingAssessment(false);
        alert("Something went wrong, please try again...");
      }
    }

    if (formType === FormType.EDIT) {
      try {
        const response = await modifyAssessment(data);
        if (response) {
          history.push({
            pathname: "/",
          });
        }
      } catch (error) {
        setIsSavingAssessment(false);
        alert("Something went wrong, please try again...");
      }
    }
  };

  const random = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min)) + min;

  const onSubmit: SubmitHandler<AssessmentProfileStatesType> = async (
    data: AssessmentProfileStatesType
  ) => {
    if (formType === FormType.CREATE) {
      try {
        const response = await createAssessment(data);
        if (response) {
          const basePath = `/assessments/${response.data.id}/scope/`;
          const path =
            data.assessmentType === "ITIL" ? `${basePath}itil` : basePath;
          history.push({ pathname: path });
        }
      } catch (error) {
        alert("Something went wrong, please try again...");
      }
    }

    if (formType === FormType.EDIT) {
      try {
        const response = await modifyAssessment(data);
        if (response) {
          history.push({
            pathname: `/assessments/${assessmentId}/scope`,
          });
        }
      } catch (error) {
        alert("Something went wrong, please try again...");
      }
    }
  };

  const modifyAssessment = async ({
    assessmentType,
    name,
    certified,
    selectedScope,
  }: AssessmentProfileStatesType) => {
    const { perspectives, threads, selectedLevel } = fullAssessment;
    const editedData = {
      assessmentType,
      certified,
      name,
      perspectives,
      threads,
      selectedLevel,
      selectedScope,
    };
    return api(API.PUT_ASSESSMENT(editedData, assessmentId));
  };

  const createAssessment = async (data: AssessmentProfileStatesType) => {
    return api(API.POST_ASSESSMENT(data, organizationId));
  };
  const meta = !assessmentId ? "- New Organization" : "";
  return (
    <React.Fragment>
      <MetaTitle
        title={`Assessment Profile Set-up ${meta} | Axelos Maturity Assessment Tool`}
      />
      <Template
        isLoading={isLoading}
        formStates={assessmentProfile}
        displayNote={displayNote}
        organization={organization}
        setDisplayNote={setDisplayNote}
        handleSubmit={onSubmit}
        handleSaveAndExit={handleSaveAndExit}
        isSavingAssessment={isSavingAssessment}
        hasScope={hasScope}
        hasAssessmentStarted={hasAssessmentStarted}
        assessmentId={fullAssessment?.id}
        progressBarPages={progressBarPages}
        setFormData={setFormData}
        formData={formData}
        endorsementNoteCopy={endorsementNoteCopy}
        handleEndorsementNoteCopy={handleEndorsementNoteCopy}
        handleScopeNoteCopy={handleScopeNoteCopy}
        scopeNoteCopy={scopeNoteCopy}
      />
    </React.Fragment>
  );
};

export default AssessmentProfile;
