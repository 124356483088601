import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import styles from "./../styles.module.scss";

type DropOutMenuProps = {
  children?: React.ReactNode;
};

const DropOutMenu: React.FunctionComponent<DropOutMenuProps> = ({
  children,
}) => {
  const menuRef = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const closeIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        menuRef.current &&
        e.target instanceof Element &&
        !menuRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", closeIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeIfClickedOutside);
    };
  }, [isOpen]);

  const isOpenModifierStyle = clsx({ [styles.openMenu]: isOpen });

  return (
    <nav className={styles.DropOutMenu} aria-expanded={isOpen} ref={menuRef}>
      <span className={isOpenModifierStyle}></span>
      <button onClick={() => setIsOpen(!isOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className={isOpenModifierStyle}>{children}</div>
    </nav>
  );
};

export default DropOutMenu;
