import React from "react";
import clsx from "clsx";
import { ErrorMessage } from "@hookform/error-message";

import { QuestionTemplateProps } from "./types";
import Error from "./Error";
import Info from "./Info";
import Label from "./Label";
import Status from "./Status";

import styles from "./styles.module.scss";

const QuestionTemplate: React.FunctionComponent<QuestionTemplateProps> = ({
  answered,
  children,
  disabled,
  id,
  info,
  invalid,
  required,
  label,
  name,
  readOnly,
  shownStatus,
  diagnostic,
  row,
}) => {
  const className = clsx(
    styles["question"],
    diagnostic && styles["diagnostic"],
    disabled && styles["disabled"],
    readOnly && styles["read-only"],
    row && styles["row"]
  );
  return (
    <div className={className}>
      {shownStatus && <Status required={shownStatus} answered={answered} />}
      <Label
        disabled={disabled}
        id={id || name}
        label={label}
        readOnly={readOnly}
        required={required}
      />
      {info && <Info info={info} />}
      <div>{children}</div>
      {!disabled && invalid && <ErrorMessage as={<Error />} name={name} />}
    </div>
  );
};

export default React.memo(QuestionTemplate);
