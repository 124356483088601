import React from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type FieldsetProps = {
  children?: React.ReactNode;
  title?: string;
  column?: boolean;
  assessmentSetup?: boolean;
};

const Fieldset: React.FunctionComponent<FieldsetProps> = ({
  children,
  title,
  column,
  assessmentSetup,
}) => {
  const className = clsx(
    styles["fieldset"],
    assessmentSetup && styles["assessment-setup"]
  );
  return (
    <fieldset className={className}>
      {title && (
        <legend>
          <h2>{title}</h2>
        </legend>
      )}
      {column ? (
        <div className={styles["fieldset-column"]}>{children}</div>
      ) : (
        children
      )}
    </fieldset>
  );
};

export default React.memo(Fieldset);
