import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { api, API } from "modules/api";
import { Organization } from "modules/api/endpoints/organizationGet";

import { Answers, State } from "./constants";
import { CertifiedThreadsCode } from "routes/assessments/Scope/constants";
import {
  StateType,
  QuestionType,
  AnswerType,
  ActiveQuestion,
  ProgressType,
  BaseItem,
  AssessmentResponse,
  AnswersType,
  QuestionsParam,
  FormType,
  DiagnosticType,
  AssessmentStatus,
} from "./types";

import {
  ErrorMessagesType,
  assessmentErrorMessages,
  genericErrorMessages,
} from "modules/messages";

import { useModal } from "modules/hooks/modal";

import Template from "./Template";
import UseLockAssessment from "./hooks/useLockAssessment";
import { getAnswerScore, getAnswerType } from "modules/utils";
import { getErrorMessages } from "modules/messages/helpers";
import { generateMetaTitle } from "./utils/questionsMetaTitles";
import MetaTitle from "components/MetaTitle";

const Route: React.FunctionComponent = () => {
  const [formType, setFormType] = useState<string | null>(null);
  const [formState, setFormState] = useState<StateType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [assessment, setAssessment] = useState<AssessmentResponse | null>(null);
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [answers, setAnswers] = useState<AnswersType[]>([]);
  const [answersIds, setAnswersIds] = useState<AnswersType[]>([]);
  const [activeQuestion, setActiveQuestion] = useState<ActiveQuestion>(
    {} as ActiveQuestion
  );
  const [sendRequest, setSendRequest] = useState(false);
  const [postAnswers, setPostAnswers] = useState<AnswerType[]>([]);

  const [isFirstAnswerSubmitted, setIsFirstAnswerSubmitted] = useState(false);
  const [isThreadDescoped, setIsThreadDescoped] = useState(false);
  const [hasThreadDescopedChanged, setHasThreadDescopedChanged] =
    useState(false);
  const [isDescoping, setIsDescoping] = useState(false);

  const [threadsData, setThreadsData] = useState<BaseItem[]>([]);
  const [perspectivesData, setPerspectivesData] = useState<BaseItem[]>([]);

  const [currentThread, setCurrentThread] = useState<BaseItem>({} as BaseItem);
  const [currentPerspective, setCurrentPerspective] = useState<BaseItem>(
    {} as BaseItem
  );
  const [progressBar, setProgressBar] = useState<ProgressType>(
    {} as ProgressType
  );

  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

  const [noteState, setNoteState] = useState<string | null>(null);

  const errorConfirmation = useModal();

  const [isActive, setIsActive] = useState(false);

  const {
    params: { assessmentId, perspectiveCode, threadCode },
  } = useRouteMatch<QuestionsParam>();
  const history = useHistory();

  useEffect(() => {
    setIsActive(false);
    const fetchHelperData = async () => {
      try {
        const [perspectives, threads] = await Promise.all([
          api(API.GET_PERSPECTIVES()).then((res) => res.data),
          api(API.GET_THREADS()).then((res) => res.data),
        ]);

        setThreadsData(threads);
        setPerspectivesData(perspectives);
        setCurrentThread(() => {
          return threads.filter((t: BaseItem) => t.code === threadCode)[0];
        });
        setCurrentPerspective(() => {
          return perspectives.filter(
            (p: BaseItem) => p.code === perspectiveCode
          )[0];
        });
      } finally {
        setIsActive(true);
      }
    };
    fetchHelperData();

    return () => {
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    };
  }, []);

  useEffect(() => {
    if (sendRequest) {
      const callAPI = async () => {
        try {
          if (formType === FormType.CREATE || hasThreadDescopedChanged) {
            setIsFirstAnswerSubmitted(() => true);

            const postAnswersWithNoId = postAnswers.map((a) => {
              const id = 0;
              return { ...a, id };
            });

            const response = await api(
              API.POST_ANSWER(
                hasThreadDescopedChanged ? postAnswersWithNoId : postAnswers,
                assessmentId
              )
            ).then((res) => res.data);

            if (response) {
              const newAnswersIds = response.answers;

              const updatedAnswersIds = answers.map((a) => {
                const responseQuestionId = newAnswersIds.filter(
                  (answer: any) => answer.questionId === a.questionId
                )[0].id;
                return { ...a, id: responseQuestionId };
              });

              setFormType(() => FormType.UPDATE);
              setAnswers(() => updatedAnswersIds);
              setAnswersIds(() => updatedAnswersIds);
              setProgressBar(() => response.progress);
              setIsFirstAnswerSubmitted(() => false);
              setHasThreadDescopedChanged(() => false);
              return;
            }
            return;
          }

          if (formType === FormType.UPDATE) {
            const updatedIds = postAnswers.map((p) => {
              const { id } = answersIds.filter(
                (a) => a.questionId === p.questionId
              )[0];

              return {
                ...p,
                id,
              };
            });

            const response = await api(
              API.POST_ANSWER(updatedIds, assessmentId)
            ).then((res) => res.data);

            if (response) {
              setProgressBar(() => response.progress);
            }
            return;
          }

          if (formType === FormType.EDIT && !hasThreadDescopedChanged) {
            const response = await api(
              API.POST_ANSWER(postAnswers, assessmentId)
            ).then((res) => res.data);

            if (response) {
              setProgressBar(() => response.progress);
            }
          }
        } catch (error) {
          await errorConfirmation({
            type: "error",
            catchOnCancel: true,
            title: genericErrorMessages.badRequest.title,
            description: genericErrorMessages.badRequest.description,
          }).finally(() => apiErrorRedirection());
        }
      };

      callAPI();

      setSendRequest(() => false);
    }
  }, [sendRequest]);

  useEffect(() => {
    let isCancelled = false;

    if (isAssessmentValid(assessmentId)) {
      const fetchAssessment = async () => {
        try {
          const response = await api(API.GET_ASSESSMENT(assessmentId)).then(
            (assessmentResponse) => assessmentResponse.data
          );

          if (!response.data && isCancelled) history.push("/");

          setAssessment(() => response);
        } catch (error: any) {
          const messages = getErrorMessages(
            assessmentErrorMessages,
            error?.response?.status
          );
          await errorConfirmation({
            type: "error",
            catchOnCancel: true,
            title: messages.title,
            description: messages.description,
          }).finally(() => history.push("/"));
        }
      };

      fetchAssessment();
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let isCancelled = false;

    if (!assessment) return;

    const fetchQuestions = async () => {
      const filteredPerspective = assessment?.perspectives.filter(
        (p) => p.code === perspectiveCode
      )[0];
      const filteredThread = assessment?.threads.filter(
        (t) => t.code === threadCode
      )[0];

      if (!filteredPerspective || !filteredThread) {
        await errorConfirmation({
          type: "error",
          catchOnCancel: true,
          title: genericErrorMessages.badRequest.title,
          description: genericErrorMessages.badRequest.description,
        }).finally(() => apiErrorRedirection());

        return;
      }

      setActiveQuestion({
        id: filteredPerspective.id,
        perspective: filteredPerspective.name,
        thread: filteredThread.name,
      });

      if (isActive) {
        setCurrentThreadAndPerspective();
      }

      try {
        const progressResponse = await api(
          API.GET_ASSESSMENT_PROGRESS(assessmentId)
        ).then((res) => res.data);

        if (isActive) {
          setCurrentThreadAndPerspective();
        }
        setProgressBar(progressResponse);

        setIsThreadDescoped(() => {
          return progressResponse.perspectivesProgress
            .filter((pp: any) => pp.name === filteredPerspective.name)[0]
            .threads.filter((t: any) => t.name === filteredThread.name)[0]
            .descoped;
        });
      } catch (error: any) {
        await errorConfirmation({
          type: "error",
          catchOnCancel: true,
          title: genericErrorMessages.badRequest.title,
          description: genericErrorMessages.badRequest.description,
        }).finally(() => apiErrorRedirection(error?.response?.status));
      }

      if (filteredPerspective && filteredThread) {
        const notesResponse = await api(
          API.GET_P3M3_NOTE(
            Number(assessmentId),
            filteredPerspective.id,
            filteredThread.id
          )
        )
          .then((res) => res.data)
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.warn(err);
          });

        const assessmentNote = notesResponse ? notesResponse.body : "";

        await api(
          API.GET_QUESTIONS(
            assessmentId,
            `?p=${filteredPerspective.id}&t=${filteredThread.id}`
          )
        )
          .then((response) => {
            if (response.data?.length === 0 && isCancelled) {
              history.push("/");
            }
            setQuestions(() => response.data);
            setFormType(() => FormType.CREATE);

            // Get Diagnostics
            const diagnosticsResponse = response.data.map(
              (res: any) => res.diagnostics
            );

            // Concat Diagnostics
            const mergedDiagsnostics = [].concat(...diagnosticsResponse);

            const diagnosticsObjects = convertArrayToObject(
              mergedDiagsnostics,
              "code"
            );

            // Check if there's answered question
            if (response.data[0].answer) {
              setFormType(() => FormType.EDIT);

              const { selectedLevel } = assessment;
              const selectedLevelAnswer =
                response.data[selectedLevel - 1].answer.selection;
              const selectedLevelAnswerValue =
                getAnswerValue(selectedLevelAnswer);

              type answersDataResponse = {
                answer: {
                  selection: string;
                  id: number;
                  questionId: number;
                  score: number;
                };
                level: number;
              };

              const answersData = response.data.map(
                (question: answersDataResponse) => {
                  const anwserNumber = getAnswerValue(
                    question.answer.selection
                  );

                  let enabled = false;
                  let readonly = true;
                  let prevAnswer: string | null = null;
                  let nextAnswer: string | null = null;

                  if (question.level > 1) {
                    prevAnswer =
                      response.data[question.level - 2].answer.selection;
                  }

                  if (question.level < 5) {
                    nextAnswer = response.data[question.level].answer.selection;
                  }

                  if (question.level === selectedLevel) {
                    enabled = true;
                    readonly = false;
                  }

                  if (question.level < selectedLevel) {
                    if (
                      selectedLevelAnswerValue !== "" &&
                      selectedLevelAnswerValue === "1" &&
                      anwserNumber === "1"
                    ) {
                      enabled = false;
                      readonly = true;
                    }

                    if (
                      (nextAnswer && nextAnswer === Answers.PARTIALLY) ||
                      nextAnswer === Answers.RARELY
                    ) {
                      enabled = true;
                      readonly = false;
                    }
                  }

                  if (question.level > selectedLevel) {
                    if (anwserNumber === "1") {
                      enabled = true;
                      readonly = false;
                    }
                    if (anwserNumber === "2") {
                      enabled = true;
                      readonly = false;
                    }
                    if (prevAnswer && prevAnswer === Answers.FULLY) {
                      enabled = true;
                      readonly = false;
                    }
                  }

                  if (question.level > selectedLevel && question.level < 5) {
                    if (
                      anwserNumber === "1" ||
                      anwserNumber === "2" ||
                      anwserNumber === "3"
                    ) {
                      enabled = true;
                      readonly = false;
                    }
                  }

                  if (question.level === 5 && question.level > selectedLevel) {
                    if (prevAnswer === Answers.FULLY) {
                      enabled = true;
                      readonly = false;
                    }
                  }

                  return {
                    level: question.level,
                    answer: anwserNumber,
                    label: `level${question.level}`,
                    enabled,
                    readonly,
                    id: question.answer.id,
                    questionId: question.answer.questionId,
                  };
                }
              );

              const formData = {
                level1: answersData[0].answer,
                level2: answersData[1].answer,
                level3: answersData[2].answer,
                level4: answersData[3].answer,
                level5: answersData[4].answer,
              };

              const answeredDiagnostics = mergedDiagsnostics.reduce(
                (obj, item: DiagnosticType) => {
                  return {
                    ...obj,
                    [item["code"]]: item["isPositive"],
                  };
                },
                {}
              );

              const fetchedData = {
                ...formData,
                ...answeredDiagnostics,
                assessmentNote,
              };

              setFormState(() => fetchedData);
              setAnswers(() => answersData);
              setIsLoading(false);

              return;
            }

            // Set new form states
            setFormState({
              ...State,
              ...diagnosticsObjects,
              assessmentNote,
            });

            setAnswers(() => createBaseAnswersState(response.data));
            setIsLoading(false);
          })
          .catch((error) => {
            if (!isCancelled) {
              errorConfirmation({
                type: "error",
                catchOnCancel: true,
                title: genericErrorMessages.badRequest.title,
                description: genericErrorMessages.badRequest.description,
              }).finally(() => apiErrorRedirection(error?.response?.status));
            }
          });

        return;
      }

      history.push("/");
    };

    fetchQuestions();

    return () => {
      isCancelled = true;
    };
  }, [assessment, perspectiveCode, threadCode, isActive]);

  useEffect(() => {
    if (progressBar) {
      const { perspectivesProgress } = progressBar;

      const checkAllAnswers = perspectivesProgress?.every((p) => {
        const answeredThreads = p.threads.filter((t) => t.answered);
        const discopedThreads = p.threads.filter((t) => t.descoped);

        const totalAnsweredThreads = p.totalThreads - discopedThreads.length;

        if (totalAnsweredThreads === answeredThreads.length) return true;
      });

      setAllQuestionsAnswered(checkAllAnswers);
    }
  }, [progressBar]);

  useEffect(() => {
    if (noteState == null) return;

    const timer = setTimeout(async () => {
      try {
        const payload = {
          assessmentId: assessment ? assessment.id : 0,
          body: noteState,
          threadId: currentThread.id,
          perspectiveId: currentPerspective.id,
        };
        await api(API.POST_P3M3_NOTE(payload)).then((res) => res.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [noteState]);

  const getAnswerValue = (answer: string | null) => {
    if (!answer) return null;
    switch (answer) {
      case Answers.FULLY:
        return "1";
      case Answers.PARTIALLY:
        return "2";
      case Answers.RARELY:
        return "3";
      case Answers.NA:
      default:
        return "";
    }
  };

  const createBaseAnswersState = (
    questionsSet: QuestionType[]
  ): AnswersType[] => {
    return questionsSet.map((q) => {
      return {
        level: Number(q.level),
        answer: "",
        label: `level${q.level}`,
        enabled: false,
        readonly: true,
        id: q.answer ? q.answer.id : 0,
        questionId: q.id,
      };
    });
  };

  const convertArrayToObject = (array: string[], key: any) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: "",
      };
    }, initialValue);
  };

  const handleSelectedAnswer = (currentAnswer: AnswersType) => {
    const assessmentLevel = assessment?.selectedLevel;
    const lowerLevels = answers.filter((a) => a.level < currentAnswer.level);
    const higherLevels = answers.filter((a) => a.level > currentAnswer.level);

    if (currentAnswer.answer === "1" && assessmentLevel) {
      if (
        assessmentLevel >= currentAnswer.level &&
        lowerLevels.length &&
        currentAnswer.level !== 1
      ) {
        lowerLevels.forEach((l) => {
          l.enabled = false;
          l.readonly = true;
        });
      }

      if (higherLevels.length) {
        higherLevels[0].enabled = true;
        higherLevels[0].readonly = false;
      }
    }

    if (currentAnswer.answer === "2" || currentAnswer.answer === "3") {
      if (assessmentLevel === currentAnswer.level) {
        higherLevels.forEach((h) => {
          h.enabled = false;
          h.readonly = true;
        });

        if (assessmentLevel === 1) {
          higherLevels.forEach((h) => {
            h.enabled = false;
            h.readonly = true;
          });
        }

        if (assessmentLevel !== 1 && currentAnswer.level !== 1) {
          lowerLevels.forEach((l) => {
            l.enabled = false;
            l.readonly = true;
          });
          const lastItemLowerLevels = lowerLevels[lowerLevels.length - 1];
          lastItemLowerLevels.enabled = true;
          lastItemLowerLevels.readonly = false;
        }
      }

      if (assessmentLevel && assessmentLevel < currentAnswer.level) {
        higherLevels.forEach((h) => {
          h.enabled = false;
          h.readonly = true;
        });
      }

      if (
        assessmentLevel !== 1 &&
        lowerLevels &&
        lowerLevels[lowerLevels.length - 1]
      ) {
        lowerLevels[lowerLevels.length - 1].enabled = true;
        lowerLevels[lowerLevels.length - 1].readonly = false;
      }
    }

    const newArr = [...lowerLevels, ...higherLevels, currentAnswer].sort(
      (a, b) => a.level - b.level
    );

    setAnswers(() => newArr);
  };

  const apiErrorRedirection = (error = 400) => {
    if (error && error === 401) {
      window.location.assign("https://www.axelos.com/");
      return;
    }
    history.push("/");
  };

  const isAssessmentValid = useCallback((assemt: string) => {
    return !!assemt;
  }, []);

  const setCurrentThreadAndPerspective = () => {
    setCurrentThread(() => {
      return threadsData.filter((t: BaseItem) => t.code === threadCode)[0];
    });

    setCurrentPerspective(() => {
      return perspectivesData.filter(
        (p: BaseItem) => p.code === perspectiveCode
      )[0];
    });
  };

  const handlePostAnswer = useCallback(
    async (data: typeof State) => {
      const { level1, level2, level3, level4, level5 } = data;

      const ansersArr = questions.map((question) => {
        let answer;

        switch (question.level) {
          case 1:
            answer = level1;
            break;
          case 2:
            answer = level2;
            break;
          case 3:
            answer = level3;
            break;
          case 4:
            answer = level4;
            break;
          case 5:
            answer = level5;
            break;
          default:
            answer = null;
            break;
        }

        return {
          id: question.answer ? question.answer.id : 0,
          questionId: question.id,
          selection: answer === null ? null : getAnswerType(answer),
          score: answer ? getAnswerScore(getAnswerType(answer)) : 0,
        };
      });
      setPostAnswers(ansersArr);
      setSendRequest(true);
    },
    [answers, answersIds]
  );

  const handleSidebar = (question: ActiveQuestion) => {
    setActiveQuestion(question);
  };

  const handleReturnToResults = () => {
    history.push(`/assessments/${assessmentId}/result`);
  };

  const handleDescopeAndRescopeThread = async (descope: boolean) => {
    if (descope === true) await handleDescopeThread();
    if (descope === false) await handleRescopeThread();
  };

  const handleDescopeThread = async () => {
    try {
      setIsDescoping(() => true);

      await api(
        API.POST_DESCOPE_THREAD(
          assessmentId,
          currentPerspective.id,
          currentThread.id
        )
      );

      setAnswers((answersState) => {
        const newAnswers = [...answersState];

        newAnswers.forEach((a) => {
          a.answer = "";
          a.enabled = false;
          a.readonly = true;
          a.id = 0;
        });

        return newAnswers;
      });

      setFormType(() => FormType.CREATE);
      setIsThreadDescoped(() => true);
      await getProgress();
      setIsDescoping(() => false);
    } catch (error) {
      errorConfirmation({
        type: "error",
        title: genericErrorMessages.badRequest.title,
        description: genericErrorMessages.badRequest.description,
      });
      setIsDescoping(() => false);
    }
  };

  const handleRescopeThread = async () => {
    try {
      setIsDescoping(() => true);
      await api(
        API.POST_RESCOPE_THREAD(
          assessmentId,
          currentPerspective.id,
          currentThread.id
        )
      );
      setHasThreadDescopedChanged(() => true);
      setFormType(() => FormType.CREATE);
      setIsThreadDescoped(() => false);
      await getProgress();
      setIsDescoping(() => false);
    } catch (error) {
      errorConfirmation({
        type: "error",
        title: genericErrorMessages.badRequest.title,
        description: genericErrorMessages.badRequest.description,
      });
      setIsDescoping(() => false);
    }
  };

  const getProgress = async () => {
    try {
      const response = await api(
        API.GET_ASSESSMENT_PROGRESS(assessmentId)
      ).then((res) => res.data);

      if (response) setProgressBar(() => response);
    } catch (error) {
      errorConfirmation({
        type: "error",
        title: genericErrorMessages.badRequest.title,
        description: genericErrorMessages.badRequest.description,
      });
    }
  };

  const handleNotes = (note: string | null) => {
    setNoteState((oldNote) => {
      if (oldNote === note) {
        return null;
      }
      return note;
    });
  };

  const handleProgressBarNavigation = (perspective: string, thread: string) => {
    const filteredPerspective = perspectivesData.filter(
      (p) => p.name === perspective
    )[0].code;
    const filteredThread = threadsData.filter((t) => t.name === thread)[0].code;
    history.push(
      `/assessments/${assessmentId}/questions/${filteredPerspective}/${filteredThread}`
    );
  };
  const mandatoryThreadDescope = () => {
    if (assessment?.certified && CertifiedThreadsCode.includes(threadCode)) {
      return true;
    }
    return false;
  };

  const isArchived = assessment ? assessment?.isArchived : false;
  const location = useLocation();
  const questionRoute = location.pathname.substring(
    location.pathname.lastIndexOf("questions/") + 10
  );
  const meta = generateMetaTitle(questionRoute);
  return (
    <React.Fragment>
      <MetaTitle title={meta} />
      <Template
        isLoading={isLoading}
        isArchived={isArchived}
        assessmentId={assessment ? assessment.id : 0}
        questions={questions}
        answers={answers}
        assessmentName={assessment ? assessment.name : "Not Provided"}
        currentLevel={assessment ? assessment.selectedLevel : 0}
        handleSelectedAnswer={handleSelectedAnswer}
        handlePostAnswer={handlePostAnswer}
        formState={formState ? formState : State}
        activeIndex={activeQuestion}
        handleSidebar={handleSidebar}
        organization={
          assessment ? assessment.organization : ({} as Organization)
        }
        progress={progressBar}
        isAssessmentLocked={assessment?.status === AssessmentStatus.COMPLETED}
        handleReturnToResults={handleReturnToResults}
        isFirstAnswerSubmitted={isFirstAnswerSubmitted}
        handleDescopeAndRescopeThread={handleDescopeAndRescopeThread}
        currentThread={currentThread}
        currentPerspective={currentPerspective}
        handleProgressBarNavigation={handleProgressBarNavigation}
        isThreadDescoped={isThreadDescoped}
        isDescoping={isDescoping}
        showSummaryButton={allQuestionsAnswered}
        assessmentCode={mandatoryThreadDescope}
        threadsData={threadsData}
        perspectivesData={perspectivesData}
        handleNotes={handleNotes}
      />
    </React.Fragment>
  );
};

export default Route;
