import React from "react";
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormMethods,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "modules/validation";

type Mode = {
  onBlur: "onBlur";
  onChange: "onChange";
  onSubmit: "onSubmit";
  all: "all";
};

type FormPrimitiveProps<T> = {
  children?: (methods: UseFormMethods<T>) => React.ReactNode;
  onSubmit: SubmitHandler<T>;
  schema?: yup.AnyObjectSchema;
  state: DefaultValues<T>;
  mode?: keyof Mode;
  shouldUnregister?: boolean;
};

function FormPrimitive<T>({
  children,
  onSubmit,
  schema,
  state,
  mode = "onSubmit",
  shouldUnregister = true,
}: FormPrimitiveProps<T>): JSX.Element {
  const methods = useForm<T>({
    mode: mode,
    defaultValues: state,
    resolver: schema ? yupResolver(schema) : undefined,
    shouldUnregister: shouldUnregister,
  });
  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        {children && children(methods)}
      </form>
    </FormProvider>
  );
}

export default FormPrimitive;
