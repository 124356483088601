import React from "react";

import { OrganizationType } from "modules/api/endpoints/organizationGet";
import { PerspectiveType } from "modules/api/endpoints/perspectivesGet";

import Panel from "./Panel";

import Headings from "components/Headings";
import Loading from "components/Loading";
import Section from "components/Section";

type TemplateProps = {
  children?: React.ReactNode;
  isLoading: boolean;
  organizations: OrganizationType[];
  perspectives: PerspectiveType[];
  handleHeadlingTitle: () => string;
  roles: string[];
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  organizations,
  handleHeadlingTitle,
  perspectives,
  roles,
}) => (
  <Section large>
    {!isLoading && (
      <React.Fragment>
        <Headings title={handleHeadlingTitle()} />
        {organizations.map((org) => (
          <Panel
            key={org.organization.id}
            {...org}
            perspectives={perspectives}
            roles={roles}
          />
        ))}
      </React.Fragment>
    )}
    {isLoading && <Loading noBackground />}
  </Section>
);

export default Template;
