import { ThunkMiddleware } from "redux-thunk";
import { logger } from "redux-logger";
import { Middleware } from "redux";

import { isAdmin } from "modules/admin";

const middleware = (): Array<ThunkMiddleware | Middleware> => {
  const middlewareArr = [];
  isAdmin && middlewareArr.push(logger);
  return middlewareArr;
};

export default middleware;
