import React from "react";
import { Control } from "react-hook-form";

import { Debug } from "./types";
import * as Form from "components/Form";
import * as Question from "components/Questions";
import { ButtonsFormat } from "components/Inputs/Buttons/constants";

const options = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },
  {
    label: "Extra Long Option 4",
    value: "option4",
  },
];

const switchOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

type QuestionsProps = {
  control: Control<Debug>;
  disabled?: boolean;
  readOnly?: boolean;
};

const Questions: React.FunctionComponent<QuestionsProps> = ({
  control,
  disabled,
  readOnly,
}) => {
  const handleTitle = () => {
    if (disabled) return " - Disabled";
    if (readOnly) return " - Read Only";

    return "";
  };
  return (
    <React.Fragment>
      <Form.Fieldset title={`Questions${handleTitle()}`}>
        <Question.QuestionText
          control={control}
          disabled={disabled}
          label="Text"
          name={`questionText${readOnly ? "Readonly" : ""}`}
          placeholder="Please enter text"
          readOnly={readOnly}
        />

        <Question.QuestionText
          control={control}
          disabled={disabled}
          info="<strong>Info text</strong> string"
          label="Text"
          name={`questionTextInfo${readOnly ? "Readonly" : ""}`}
          placeholder="Please enter text"
          readOnly={readOnly}
        />

        <Question.QuestionSelect
          control={control}
          disabled={disabled}
          label="Select"
          name={`questionSelect${readOnly ? "Readonly" : ""}`}
          options={options}
          readOnly={readOnly}
        />

        <Question.QuestionRadio
          control={control}
          disabled={disabled}
          format={ButtonsFormat.HORIZONTAL}
          label="Radio Horizontal"
          name={`questionRadioHorizontal${readOnly ? "Readonly" : ""}`}
          options={options}
          readOnly={readOnly}
        />

        <Question.QuestionRadio
          control={control}
          disabled={disabled}
          format={ButtonsFormat.VERTICAL}
          label="Radio Vertical"
          name={`questionRadioVertical${readOnly ? "Readonly" : ""}`}
          options={options}
          readOnly={readOnly}
        />

        <Question.QuestionCheckbox
          control={control}
          disabled={disabled}
          format={ButtonsFormat.HORIZONTAL}
          label="Checkbox Horizontal"
          name={`questionCheckboxHorizontal${readOnly ? "Readonly" : ""}`}
          options={options}
          readOnly={readOnly}
        />

        <Question.QuestionCheckbox
          control={control}
          disabled={disabled}
          format={ButtonsFormat.VERTICAL}
          label="Checkbox Vertical"
          name={`questionCheckboxVertical${readOnly ? "Readonly" : ""}`}
          options={options}
          readOnly={readOnly}
        />

        <Question.QuestionSwitch
          control={control}
          disabled={disabled}
          label="Switch"
          name={`questionSwitch${readOnly ? "Readonly" : ""}`}
          options={switchOptions}
          readOnly={readOnly}
        />

        <Question.QuestionSwitch
          control={control}
          disabled={disabled}
          label="Switch Simple"
          name={`questionSwitchSimple${readOnly ? "Readonly" : ""}`}
          options={switchOptions}
          readOnly={readOnly}
          simple={true}
        />
      </Form.Fieldset>
      {!disabled && !readOnly && (
        <Form.Fieldset title="Questions Required & Answered">
          <Question.QuestionText
            control={control}
            disabled={disabled}
            label="Required"
            name="questionRequired"
            required={true}
          />
          <Question.QuestionText
            control={control}
            disabled={disabled}
            label="Answered"
            name="questionAnswered"
            required={true}
          />
        </Form.Fieldset>
      )}
    </React.Fragment>
  );
};

export default Questions;
