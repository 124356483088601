import React from "react";
import { Control, FieldErrors } from "react-hook-form";

import * as Form from "components/Form";
import Select, { OptionType } from "components/Inputs/Select";
import Input from "components/Inputs/Input";
import Checkbox from "components/Inputs/Checkbox";

type QuestionOrganizationProfileProps = {
  sizes: OptionType[];
  countries: OptionType[];
  industries: OptionType[];
  error?: FieldErrors;
  control: Control;
  register: React.Ref<HTMLInputElement> | undefined;
};

const QuestionOrganizationProfile: React.FC<
  QuestionOrganizationProfileProps
> = ({ sizes, countries, industries, error, control, register }) => {
  return (
    <>
      <Form.Control
        isInvalid={!!error && error["name"]}
        error={error && error["name"] ? error["name"] : null}
        label="Organization name"
        fieldId="name"
        required
      >
        <Input control={control} name="name" placeholder="Organization name" />
      </Form.Control>

      <Form.Control label="Business unit" fieldId="businessUnit">
        <Input
          control={control}
          name="businessUnit"
          placeholder="Business Unit"
        />
      </Form.Control>

      <Form.Control
        required
        label="Location"
        fieldId="country"
        isInvalid={!!error && error["country"]}
        error={error && error["country"] ? error["country"] : null}
      >
        <Select
          control={control}
          name="country"
          options={countries}
          placeholder="Select location"
        />
      </Form.Control>

      <Form.Control
        label="Number of employees"
        fieldId="size"
        required
        isInvalid={!!error && error["size"]}
        error={error && error["size"] ? error["size"] : null}
      >
        <Select
          control={control}
          name="size"
          options={sizes}
          placeholder="Select organization size"
        />
      </Form.Control>

      <Form.Control
        label="Industry"
        fieldId="industry"
        required
        isInvalid={!!error && error["industry"]}
        error={error && error["industry"] ? error["industry"] : null}
      >
        <Select
          control={control}
          name="industry"
          options={industries}
          placeholder="Select industries"
        />
      </Form.Control>

      <Form.Control>
        <Checkbox
          name="benchmarkConsent"
          description="true"
          id="benchmarkConsent"
          ref={register}
          type="checkbox"
          label="All data collected on this screen will be used for benchmarking purposes only and will be anonymized. If you do not wish this data to be included for benchmarking purposes, please indicate here."
        />
      </Form.Control>
    </>
  );
};

export default QuestionOrganizationProfile;
