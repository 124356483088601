import React from "react";

import styles from "./styles.module.scss";

import Checkbox from "components/Inputs/Checkbox";

type DescopeProps = {
  title: string;
  isThreadDescoped: boolean;
  handleOnChange: (check: boolean) => void;
  valid: boolean;
};

function DescopeThread({
  title,
  isThreadDescoped,
  handleOnChange,
  valid,
}: DescopeProps): JSX.Element {
  return (
    <div className={styles["descope-wrapper"]}>
      <h2>{title}</h2>
      {!valid && (
        <Checkbox
          name="descopeCheckbox"
          label={"Descope for this perspective"}
          type="checkbox"
          checked={isThreadDescoped}
          onChange={() => handleOnChange(!isThreadDescoped)}
        />
      )}
    </div>
  );
}

export default DescopeThread;
