import yup from "modules/validation";
import Messages from "modules/validation/messages";

export const schema = yup.object({
  questionAnswered: yup.string().required(),
  questionAnsweredReadonly: yup.string().required(),

  questionCheckboxHorizontal: yup
    .array()
    .of(yup.string().required())
    .required(),
  questionCheckboxVertical: yup.array().of(yup.string().required()).required(),
  questionCheckboxHorizontalReadonly: yup
    .array()
    .of(yup.string().required())
    .required(),
  questionCheckboxVerticalReadonly: yup
    .array()
    .of(yup.string().required())
    .required(),

  questionRadioHorizontal: yup.string().required(),
  questionRadioVertical: yup.string().required(),
  questionRadioHorizontalReadonly: yup.string().required(),
  questionRadioVerticalReadonly: yup.string().required(),

  questionRequired: yup.string().required(),
  questionRequiredOnly: yup.string().required(),

  questionSelect: yup.string().required(),
  questionSelectReadonly: yup.string().required(),

  questionSwitch: yup.boolean().required(Messages.OPTION),
  questionSwitchReadonly: yup.boolean().required(Messages.OPTION),

  questionSwitchSimple: yup.boolean().required(Messages.OPTION),
  questionSwitchReadonlySimple: yup.boolean().required(Messages.OPTION),

  questionText: yup.string().required(),
  questionTextReadonly: yup.string().required(),
  questionTextInfo: yup.string().required(),
  questionTextInfoReadonly: yup.string().required(),
});
