import React from "react";

type ErrorProps = {
  children?: React.ReactNode;
};
const Error: React.FunctionComponent = ({ children }: ErrorProps) => {
  return <div aria-live="polite">{children}</div>;
};

export default Error;
