import yup from "modules/validation";

export const OrganizationSchema = yup.object().shape({
  name: yup.string().required(),
  size: yup.string().required(),
  country: yup.string().required(),
  businessUnit: yup.string().notRequired(),
  industry: yup.string().required(),
  benchmarkConsent: yup.bool().notRequired(),
});
