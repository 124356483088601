import React from "react";
import { useController, Control } from "react-hook-form";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import { InputPrimitive } from "components/Inputs/Input";
import QuestionTemplate from "../QuestionTemplate";
import Debug from "../QuestionTemplate/Debug";
import { Expand } from "modules/typescript";
import { InputProps } from "components/Inputs/Input/types";

type QuestionTextProps = Expand<
  {
    control: Control;
  } & Omit<QuestionTemplateProps, "answered" | "children" | "invalid"> &
    Omit<InputProps, "id" | "invalid">
>;

const QuestionText: React.FunctionComponent<QuestionTextProps> = ({
  control,
  name,
  required,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });
  return (
    <QuestionTemplate
      answered={field.value && !invalid}
      invalid={invalid}
      name={name}
      required={required}
      {...props}
    >
      <InputPrimitive invalid={invalid} {...props} {...field} />
      {process.env.NODE_ENV === "development" && <Debug value={field.value} />}
    </QuestionTemplate>
  );
};

export default React.memo(QuestionText);
