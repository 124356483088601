import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  OrganizationStatesType,
  OrganizationParams,
  FormHelpersType,
  FormOptionType,
  FormStateType,
} from "./types";
import { FormType } from "./enum";

import { api, API } from "modules/api";
import { OrganizationPostRequest } from "modules/api/endpoints/organizationPost";
import { GetOrganizationResponse } from "modules/api/endpoints/organizationGet";
import { GetOrganizationsResponse } from "modules/api/endpoints/organizationsGet";
import {
  GetHelpersResponse,
  HelpersItemType,
} from "modules/api/endpoints/helpersGet";

import {
  ErrorMessagesType,
  organizationErrorMessages,
  genericErrorMessages,
} from "modules/messages";

import { useAuth } from "modules/hooks/auth";

import Template from "./Template";
import { useModal } from "modules/hooks/modal";
import { getErrorMessages } from "modules/messages/helpers";
import MetaTitle from "components/MetaTitle";

const OrganizationProfile: React.FunctionComponent = () => {
  const [state, setState] = useState<FormStateType>({} as FormStateType);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingAssessment, setIsCreatingAssessment] = useState(false);
  const [isSavingOrganization, setIsSavingOrganization] = useState(false);
  const [formType, setFormType] = useState(FormType.CREATE);
  const [hasNoCompany, setHasNoCompany] = useState(false);
  const [countriesState, setCountriesState] = useState<FormHelpersType>(
    {} as FormHelpersType
  );
  const [industriesState, setIndustriesState] = useState<FormHelpersType>(
    {} as FormHelpersType
  );
  const [sizesState, setsizesState] = useState<FormHelpersType>(
    {} as FormHelpersType
  );

  const {
    params: { id },
  } = useRouteMatch<OrganizationParams>();
  const { user } = useAuth();
  const dialog = useModal();
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;
    const fetchHelpersData = async () => {
      try {
        const [countries, industries, sizes] = await Promise.all([
          api(API.GET_COUNTRIES()).then((res: GetHelpersResponse) => res.data),
          api(API.GET_INDUSTRIES()).then((res: GetHelpersResponse) => res.data),
          api(API.GET_SIZES()).then((res: GetHelpersResponse) => res.data),
        ]);

        if (isCancelled) return;

        setCountriesState(() => {
          return {
            data: countries,
            formOptions: getFormHelperOptions(countries),
          };
        });
        setIndustriesState(() => {
          return {
            data: industries,
            formOptions: getFormHelperOptions(industries),
          };
        });
        setsizesState(() => {
          return {
            data: sizes,
            formOptions: getFormHelperOptions(sizes),
          };
        });
      } catch (error: any) {
        await dialog({
          type: "error",
          catchOnCancel: true,
          title: genericErrorMessages.badRequest.title,
          description: genericErrorMessages.badRequest.description,
        }).finally(() => apiErrorRedirection(error?.response?.status));
      }
    };
    fetchHelpersData();
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    const fetchOrganizations = async () => {
      setIsLoading(true);

      if (!id) {
        try {
          const organizationsResponse = await api(API.GET_ORGANIZATIONS()).then(
            (response: GetOrganizationsResponse) => response.data
          );

          setFormType(FormType.CREATE);

          if (!organizationsResponse.length) {
            setHasNoCompany(true);
          }

          setState(() => {
            return {
              formState: {
                name: "",
                size: "",
                country: "",
                businessUnit: "",
                industry: "",
                benchmarkConsent: false,
              },
            };
          });

          setIsLoading(false);
          return;
        } catch (error: any) {
          const messages = getErrorMessages(
            organizationErrorMessages,
            error?.response?.status
          );
          await dialog({
            type: "error",
            catchOnCancel: true,
            title: messages.title,
            description: messages.description,
          }).finally(() => history.push("/"));
        }
      }

      if (!validateOrganizationId()) return history.push("/");

      try {
        const organizationResponse = await api(API.GET_ORGANIZATION(id)).then(
          (response: GetOrganizationResponse) => response.data
        );

        if (organizationResponse) {
          setState(() => {
            const { organization } = organizationResponse;
            return {
              organization,
              formState: {
                name: organization.name,
                size: organization.size.id.toString(),
                industry: organization.industry.id.toString(),
                country: organization.country.id.toString(),
                businessUnit: organization.businessUnit,
                benchmarkConsent: organization.benchmarkConsent,
              },
            };
          });
          setFormType(FormType.EDIT);
          setIsLoading(false);

          return;
        }
      } catch (error: any) {
        const messages = getErrorMessages(
          organizationErrorMessages,
          error?.response?.status
        );
        await dialog({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description,
        }).finally(() => history.push("/"));
      }
    };

    fetchOrganizations();
  }, [id]);

  function validateOrganizationId() {
    return id !== "0";
  }

  const apiErrorRedirection = (error = 400) => {
    if (error && error === 401) {
      window.location.assign("https://www.axelos.com/");
      return;
    }
    history.push("/");
  };

  const getFormHelperOptions = (arr: HelpersItemType[]): FormOptionType[] => {
    return arr.map((a) => {
      return {
        label: a.name,
        value: a.id.toString(),
      };
    });
  };

  async function createOrganization(data: OrganizationPostRequest) {
    return api(API.POST_ORGANIZATION(data)).then((postResponse) => {
      if (postResponse.status === 201) return postResponse.data.id;
    });
  }

  const handleGoBack = useCallback(() => {
    history.push({
      pathname: "/",
    });
  }, []);

  const handleHeadingsTitle = useCallback(() => {
    if (hasNoCompany)
      return `Welcome, ${user.firstName ? user.firstName : "User"}`;

    if (formType === FormType.EDIT) return "Edit organization details";

    return "Organization details";
  }, [user, formType, hasNoCompany]);

  const handleNewAssessment = useCallback(
    async (data: OrganizationStatesType) => {
      const updatedFormData = {
        ...data,
        country: getFilteredItem(countriesState.data, Number(data.country)),
        size: getFilteredItem(sizesState.data, Number(data.size)),
        industry: getFilteredItem(industriesState.data, Number(data.industry)),
      };
      try {
        setIsCreatingAssessment(true);
        const result = await createOrganization(updatedFormData);
        if (result) {
          localStorage.setItem("@P3M3:lastOpenItem", result.toString());
          history.push(`/organizations/${result}/assessment`);
        }
      } catch (error) {
        setIsCreatingAssessment(false);
        // Missing error handling;
        alert("Something went wrong, please try again");
      }
    },
    [countriesState, sizesState, industriesState]
  );

  const getFilteredItem = (arr: HelpersItemType[], itemId: number) => {
    return arr.filter((a) => a.id === itemId)[0];
  };

  const handleCreateOrganization = async (data: OrganizationStatesType) => {
    const updatedFormData = {
      ...data,
      country: getFilteredItem(countriesState.data, Number(data.country)),
      size: getFilteredItem(sizesState.data, Number(data.size)),
      industry: getFilteredItem(industriesState.data, Number(data.industry)),
    };
    try {
      setIsSavingOrganization(true);
      const response = await createOrganization(updatedFormData);
      if (response) {
        history.push("/");
      }
    } catch (error) {
      setIsSavingOrganization(false);
      // Missing error handling;
      alert("Something went wrong, please try again");
    }
  };

  const handleEditOrganization = async (data: OrganizationStatesType) => {
    const updatedFormData = {
      ...data,
      country: getFilteredItem(countriesState.data, Number(data.country)),
      size: getFilteredItem(sizesState.data, Number(data.size)),
      industry: getFilteredItem(industriesState.data, Number(data.industry)),
    };
    try {
      setIsSavingOrganization(true);
      const response = await api(API.PUT_ORGANIZATION(updatedFormData, id));
      if (response) {
        history.push("/");
      }
    } catch (error) {
      setIsSavingOrganization(false);
      // Missing error handling;
      alert("Something went wrong, please try again");
    }
  };
  const meta = formType === FormType.EDIT ? "Edit " : "";
  return (
    <React.Fragment>
      <MetaTitle
        title={`${meta}Organization Details | Axelos Maturity Assessment Tool`}
      />
      <Template
        isLoading={isLoading}
        isCreatingAssessment={isCreatingAssessment}
        isSavingOrganization={isSavingOrganization}
        countriesData={countriesState.formOptions}
        industriesData={industriesState.formOptions}
        sizesData={sizesState.formOptions}
        formStates={state.formState}
        formType={formType}
        hasNoCompany={hasNoCompany}
        handleHeadingsTitle={handleHeadingsTitle}
        country={state.organization?.country.name}
        handleNewAssessment={handleNewAssessment}
        handleCreateOrganization={handleCreateOrganization}
        handleEditOrganization={handleEditOrganization}
        handleGoBack={handleGoBack}
      />
    </React.Fragment>
  );
};

export default OrganizationProfile;
