import { ErrorMessagesType, ErrorMessageType } from "modules/messages";

type getErrorMessagesProps = (
  errorMessages: ErrorMessagesType,
  error: number
) => ErrorMessageType;

export const getErrorMessages: getErrorMessagesProps = (
  errorMessages: ErrorMessagesType,
  error = 400
) => {
  switch (error) {
    case 401:
      return errorMessages.unauthorized;
    case 404:
      return errorMessages.notFound;
    default:
      return errorMessages.badRequest;
  }
};
