import { StatusCodes } from "http-status-codes";
import sleep from "sleep-promise";
import retry from "async-retry";
import axios from "axios";

import { ApiEndpoint, DefaultApiResponse } from "./types";
import { isAdmin } from "modules/admin";

export { API } from "./constants";

const RETRIES = isAdmin ? 1 : 3;

const errorCodes = [
  StatusCodes.BAD_REQUEST,
  StatusCodes.UNAUTHORIZED,
  StatusCodes.UNPROCESSABLE_ENTITY,
  StatusCodes.NOT_FOUND,
];

export const api = async (
  connection: ApiEndpoint
): Promise<DefaultApiResponse> => {
  process.env.NODE_ENV === "development" && (await sleep(0));
  const response = await retry(
    async (bail) => {
      try {
        return await axios({ ...connection });
      } catch (error: any) {
        if (
          error.response &&
          error.response.status &&
          errorCodes.includes(error.response.status)
        ) {
          return bail(error);
        }
        throw error;
      }
    },
    {
      retries: RETRIES,
    }
  );
  return response as DefaultApiResponse;
};
