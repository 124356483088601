import { useState, useEffect } from "react";
import { API, api } from "modules/api";
import {
  archiveAssessment,
  unarchiveAssessment,
} from "modules/utils/ArchiveAssessments";

import {
  AssessmentType,
  Assessment,
  AssessmentFormat,
  AssessmentScope,
} from "../types";

export function useAssessments() {
  const [assessments, setAssessments] = useState<AssessmentType | null>(null);
  const [tabAssessments, setTabAssessments] = useState<Assessment[]>([]);
  const [lockedAssessments, setLockedAssessments] = useState<number[]>([]);
  const [tabItilAssessments, setItilTabAssessments] = useState<Assessment[]>(
    []
  );

  useEffect(() => {
    if (tabItilAssessments.length === 0 && tabAssessments.length === 0) return;
    setLockedAssessments(() => {
      const itilLockedAssessments = tabItilAssessments
        .filter((a) => a.locked)
        .map((a) => a.id);
      const p3m3LockedAssessments = tabAssessments
        .filter((a) => a.locked)
        .map((a) => a.id);

      return [...itilLockedAssessments, ...p3m3LockedAssessments];
    });
  }, [tabItilAssessments, tabAssessments]);

  const handleIsLocked = async (id: number) => {
    try {
      const response = await api(API.POST_ASSESSMENT_LOCK(id)).then(
        (res) => res.data
      );
      if (response) {
        setLockedAssessments((oldIds) => {
          return oldIds.filter((i) => i !== id);
        });
        return true;
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        setLockedAssessments((oldIds) => {
          return [...oldIds, id];
        });
        return false;
      }
    }
    return false;
  };

  const handleArchiveToggle = (
    theAssessment: Assessment,
    type: AssessmentFormat
  ) => {
    // Contact the api to swap the values.
    if (theAssessment.isArchived) {
      unarchiveAssessment(theAssessment.id);
      theAssessment.isArchived = false;
    } else {
      archiveAssessment(theAssessment.id);
      theAssessment.isArchived = true;
    }

    if (assessments === null) {
      return;
    }

    // Refill the tab, by type, after the mutated assessment
    const rearranged = reBinAssessments(assessments[type]);
    if (type === AssessmentFormat.ITIL) {
      setAssessments({
        ITIL: rearranged,
        P3M3: assessments.P3M3,
      });
      setItilTabAssessments(
        tabItilAssessments.filter((a) => a.id !== theAssessment.id)
      );
    }
    if (type === AssessmentFormat.P3M3) {
      setAssessments({
        ITIL: assessments.ITIL,
        P3M3: rearranged,
      });
      setTabAssessments(
        tabAssessments.filter((a) => a.id !== theAssessment.id)
      );
    }
  };

  // Ensure any archived assessments are in the archive bin and
  // any previous archived assessments go into the correct status
  const reBinAssessments = (selectedAssessments: AssessmentScope) => {
    const assessmentsFromArchive = selectedAssessments.archived.filter(
      (a) => !a.isArchived
    );
    const newArchive = Object.values(selectedAssessments)
      .flatMap((a) => a)
      .filter((a) => a.isArchived);

    return {
      created: filterAssessmentsByStatus("Created", [
        ...selectedAssessments.created,
        ...assessmentsFromArchive,
      ]),
      active: filterAssessmentsByStatus("Active", [
        ...selectedAssessments.active,
        ...assessmentsFromArchive,
      ]),
      drafted: filterAssessmentsByStatus("Drafted", [
        ...selectedAssessments.drafted,
        ...assessmentsFromArchive,
      ]),
      completed: filterAssessmentsByStatus("Completed", [
        ...selectedAssessments.completed,
        ...assessmentsFromArchive,
      ]),
      archived: newArchive,
    };
  };

  const filterAssessmentsByStatus = (
    status: string,
    assessmentArr: Assessment[]
  ) => assessmentArr.filter((a) => !a.isArchived && a.status === status);

  return [
    assessments,
    tabAssessments,
    tabItilAssessments,
    setAssessments,
    setTabAssessments,
    setItilTabAssessments,
    lockedAssessments,
    setLockedAssessments,
    handleIsLocked,
    handleArchiveToggle,
  ] as const;
}
