import React from "react";

import Button from "./Button";
import { ButtonsType, SelectAllOption } from "./constants";
import { ButtonCheckboxProps } from "./types";

const ButtonCheckbox: React.FunctionComponent<ButtonCheckboxProps> = ({
  id,
  index,
  onClick,
  option,
  tabIndex,
  value,
  selectAll,
  options,
  ...props
}) => {
  const optionsArr = options ? options.map((o) => o.value) : [];
  const isReadonly = props.readonly ? props.readonly : !!option.isRequired;
  const requiredOptons = options
    ? options
        .filter((o) => o.isRequired)
        .map((o) => {
          return o.value;
        })
    : [];

  const handleClick = () => {
    if (selectAll) {
      onClick(
        value.includes(option.value)
          ? [...requiredOptons]
          : [...optionsArr, option.value]
      );
      return;
    }
    onClick(
      value.includes(option.value)
        ? value.filter(
            (item: string) =>
              item !== option.value && item !== SelectAllOption.value
          )
        : [...value, option.value]
    );
  };

  return (
    <Button
      {...props}
      id={index === 0 ? id : undefined}
      label={option.label}
      readOnly={isReadonly}
      onClick={() => handleClick()}
      selected={value.includes(option.value)}
      tabIndex={tabIndex}
      type={ButtonsType.CHECKBOX}
    />
  );
};

export default React.memo(ButtonCheckbox);
