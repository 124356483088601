export const convertDate = (date: string, short?: boolean): string | null => {
  if (!date) return null;

  const newDate = new Date(date);

  if (short) {
    return new Intl.DateTimeFormat("en-GB", {
      month: "short",
      day: "2-digit",
    }).format(newDate);
  }
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(newDate);
};
