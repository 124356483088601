import React from "react";

import styles from "./styles.module.scss";

type HeadingsCopy = {
  title?: string;
  subtitle?: string;
};

const Headings: React.FunctionComponent<HeadingsCopy> = ({
  children,
  title,
  subtitle,
}) => {
  return (
    <header className={styles["headings-wrapper"]}>
      <div className={styles.headings}>
        {title && <h1>{title}</h1>}
        {subtitle && <p>{subtitle}</p>}
      </div>
      {children}
    </header>
  );
};

export default Headings;
