/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { api, API } from "modules/api";

import { AssessmentGetResponse } from "modules/api/endpoints/assessmentGet";
import { AssessmentPutRequest } from "modules/api/endpoints/assessmentPut";

import {
  CapabilityType, GetCapabilitiesResponse
} from "modules/api/endpoints/capabilitiesGet";
import {
  MaturityType, GetMaturitiesResponse
} from "modules/api/endpoints/maturitiesGet";

import { Organization } from "modules/api/endpoints/organizationGet";

import { ProgressBarPageType } from "components/Progress/Simple/index";
import { ErrorMessagesType, assessmentErrorMessages } from "modules/messages";
import { ButtonStyle } from "components/Button";
import { capabilities, servicePractice, technicalPractice, ProgressPages, allPractices, maturities } from "./constants";

import { useModal } from "modules/hooks/modal";

import Template from "./Template";

import {
  AssessmentItilScopeStatesType,
  ItilFormStateType,
  ItilStateType,
} from "../types";
import UseLockAssessment from "routes/questions/hooks/useLockAssessment";


interface AssessmentParams {
  assessmentId: string;
}

const AssessmentScope: React.FunctionComponent = () => {
  const {
    params: { assessmentId },
  } = useRouteMatch<AssessmentParams>();

  const [
    fullAssessmentState,
    setFullAssessmentState,
  ] = useState<AssessmentItilScopeStatesType>({} as AssessmentItilScopeStatesType);

  const [formStates, setFormStates] = useState<ItilStateType>({} as ItilStateType);
  const [organization, setOrganization] = useState<Organization>(
    {} as Organization
  );
  const [capabilitiesData, setCapabilitiesData] = useState<CapabilityType[]>([])
  const [maturitiesData, setMaturitiesData] = useState<MaturityType[]>([])

  const [isCertified, setIsCertified] = useState(false);

  const [progressBarPages, setProgressBarPages] = useState<
    ProgressBarPageType[]
  >([...ProgressPages]);

  const [isLoading, setIsLoading] = useState(true);

  const scopeValidation = useModal();

  const history = useHistory();

  useEffect(() => {
    return () => {
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    };
  }, []);

  useEffect(() => {
    if (!validateAssessmentId()) return history.push("/");

    const getAssessmentAndSetStates = async () => {

      const [
        assessment,
        capabilitiesDataResponse,
        maturitiesDataResponse,
      ] = await Promise.all([
        api(API.GET_ASSESSMENT(assessmentId)).then(
          (res: AssessmentGetResponse) => res.data
        ),
        api(API.GET_CAPABILITIES()).then(
          (res: GetCapabilitiesResponse) => res.data
        ),
        api(API.GET_MATURITIES()).then((res: GetMaturitiesResponse) => res.data),
      ]);

      setFullAssessmentState(assessment);
      setCapabilitiesData(capabilitiesDataResponse);
      setMaturitiesData(maturitiesDataResponse);
      setOrganization(assessment.organization);

      const { selectedScope } = assessment;

      const isCapability = selectedScope === "Capability";

      const continualImprovementID = 2;

      const filteredCapabilities = isCapability
        ? capabilitiesDataResponse
        : capabilitiesDataResponse.filter(c => c.id !== continualImprovementID);

      const all = filteredCapabilities.reduce((o, key) => ({ ...o, [key.code]: "N/A" }), {});

      setFormStates(() => {
        if (!!assessment.capabilities.length) {
          return { ...all, ...assessment.capabilities.reduce((o, key) => ({ ...o, [key.code]: key.selectionMode.toString() }), {}) }
        }
        return all;
      })

      setIsLoading(false)
    }

    getAssessmentAndSetStates();
  }, [assessmentId]);

  function validateAssessmentId() {
    return !!assessmentId;
  }

  const handleGoBack = (): void => {
    history.push(`/assessments/${assessmentId}/profile/itil`);
  };

  const onSubmit = async (data: ItilStateType) => {
    const { selectedScope, name, endorsed, id, assessmentType } = fullAssessmentState;

    const updatedAssessment: AssessmentItilScopeStatesType = {
      id,
      name,
      endorsed,
      selectedScope,
      assessmentType,
    };

    const mappedCapabilities = Object.keys(data).map((key) => {
      return data[key]
    }).filter(value => value !== "N/A");

    const capabilitiesKeyAndValues = Object.keys(data)
      .map((key) => {
        return { key, value: data[key] };
      })
      .filter((item) => item.value !== "N/A");

    const capabilitiesWithValues = capabilitiesKeyAndValues.map(i => {
      const capabilityFound = capabilitiesData.find((item) => item.code === i.key);
      if (capabilityFound) {
        return {
          id: capabilityFound.id,
          name: capabilityFound.name,
          selectionMode: Number(i.value)
        }
      }
      return { id: 0, name: '', selectionMode: 0 }
    })

    const filteredCapabilitiesWithValues = capabilitiesWithValues.filter(i => i.id !== 0);

    if (selectedScope === "Capability") {
      if (mappedCapabilities.length < 1) {
        await scopeValidation({
          type: "error",
          catchOnCancel: true,
          title: "Please, select the minimum required.",
          description: "To proceed you need to pick at least 1 item.",
        });
        return;
      }
      updatedAssessment.capabilities = filteredCapabilitiesWithValues;
    }

    if (selectedScope === "Comprehensive" || selectedScope === "Maturity") {
      const continualImprovmentCapability = capabilitiesData.find(cp => cp.id === 2);

      if (continualImprovmentCapability && filteredCapabilitiesWithValues) {
        const updatedCapabilities = [...filteredCapabilitiesWithValues, {
          id: continualImprovmentCapability.id,
          name: continualImprovmentCapability.name,
          selectionMode: 1,
        }]
        updatedAssessment.capabilities = updatedCapabilities;
      }

      updatedAssessment.maturities = maturitiesData.map(md => {
        return {
          id: md.id,
          name: md.name,
          isSelected: true,
        }
      })
    }

    if (selectedScope === "Comprehensive") {
      if (mappedCapabilities.length <= 5) {
        await scopeValidation({
          type: "error",
          catchOnCancel: true,
          title: "Please, select the minimum required.",
          description: "To proceed you need to pick at least 6 items.",
        });
        return;
      }
    }

    if (selectedScope === "Maturity") {
      if (mappedCapabilities.length > 5) {
        const response = await scopeValidation({
          type: "info",
          cancelButtonStyle: ButtonStyle.SECONDARY,
          cancelButtonLabel: "Create Comprehensive",
          catchOnCancel: true,
          displayCancelButton: true,
          title: "Create a Comprehensive assessment?",
          description: "Assessing more than seven practices will create a comprehensive assessment",
        }).then(() => true).catch(() => false);

        if (response) return;

        if (!response) {
          updatedAssessment.selectedScope = "Comprehensive";
        }
      }
    }

    await modifyAssessmentScope(updatedAssessment)
  };

  const modifyAssessmentScope = async (data: AssessmentPutRequest) => {

    try {
      const response = await api(API.PUT_ASSESSMENT(data, assessmentId));
      if (response) {
        history.push(`/assessments/${assessmentId}/review/itil`);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Template
      isLoading={isLoading}
      isCertified={isCertified}
      handleSubmit={onSubmit}
      handleGoBack={handleGoBack}
      formStates={formStates}
      organization={organization}
      selectedScope={fullAssessmentState.selectedScope}
      progressBarPages={progressBarPages}
      assessmentId={fullAssessmentState.id}
      capabilitiesData={capabilitiesData}
      maturitiesData={maturitiesData}
    />
  );
};

export default AssessmentScope;
