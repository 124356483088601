/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { useController, Control } from "react-hook-form";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import TextArea from "components/Inputs/TextArea";
import QuestionTemplate from "../QuestionTemplate";
import Debug from "../QuestionTemplate/Debug";
import { Expand } from "modules/typescript";
import { InputProps } from "components/Inputs/Input/types";

type QuestionTextProps = Expand<
  {
    control: Control;
    handleOnChange: (evt: string | null) => void;
  } & Omit<QuestionTemplateProps, "answered" | "children" | "invalid"> &
  Omit<InputProps, "id" | "invalid">
>;

const QuestionTextArea: React.FunctionComponent<QuestionTextProps> = ({
  control,
  name,
  required,
  placeholder,
  handleOnChange,
  ...props
}) => {

  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });

  return (
    <QuestionTemplate
      answered={field.value && !invalid}
      invalid={invalid}
      name={name}
      required={required}
      {...props}
    >
      <TextArea
        id={name}
        rows={6}
        placeholder={placeholder}
        {...props}
        {...field}
        handleSetState={handleOnChange}
      />
      {process.env.NODE_ENV === "development" && <Debug value={field.value} />}
    </QuestionTemplate>
  );
};

export default React.memo(QuestionTextArea);
