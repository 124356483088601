import React from "react";
import styles from "./styles.module.scss";
type TableContainerProps = {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
};

const TableContainer: React.FunctionComponent<TableContainerProps> = ({
  children,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.TableContainer}>
      <div className={styles["TableContainer__header"]}>
        <h2>{title}</h2>
        {subtitle ? <p>{subtitle}</p> : ""}
      </div>
      <div className={styles["TableContainer__body"]}>{children}</div>
    </div>
  );
};

export default TableContainer;
