import React from "react";
import clsx from "clsx";
import styles from "../styles.module.scss";
import Loading from "components/Loading";

type WrapperProps = {
  children?: React.ReactNode;
  isLoading: boolean;
  isOpen: boolean;
  handleFetchAssessment: () => void;
};

const PanelWrapper = React.forwardRef<HTMLDivElement, WrapperProps>(
  ({ children, isLoading, isOpen, handleFetchAssessment }, ref) => {
    const className = clsx(styles["panel"], isOpen && styles["is-open"]);
    return (
      <div
        className={className}
        ref={ref}
        onClick={() => {
          if (!isOpen) {
            handleFetchAssessment();
          }
        }}
      >
        {children}
        {isLoading && <Loading />}
      </div>
    );
  }
);

export default React.memo(PanelWrapper);
