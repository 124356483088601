import React from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type ActionsProps = {
  isLoading?: boolean;
};

const Actions: React.FC<ActionsProps> = ({ children, isLoading }) => {
  const classes = clsx(styles.actions, isLoading && styles["is-loading"]);
  return <div className={classes}>{children}</div>;
};

export default Actions;
