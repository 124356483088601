import React, { useCallback } from "react";
import clsx from "clsx";

import { ButtonsType, SelectAllOption } from "./constants";

import styles from "./styles.module.scss";
import ButtonRadio from "./ButtonRadio";
import ButtonCheckbox from "./ButtonCheckbox";
import ButtonSwitch from "./ButtonSwitch";
import {
  ButtonProps,
  ButtonsProps,
  ButtonRadioProps,
  ButtonCheckboxProps,
  ButtonSwitchProps,
} from "./types";

const Button = React.memo<ButtonProps>(({ type, ...props }) => {
  switch (type) {
    case ButtonsType.RADIO:
      return <ButtonRadio {...(props as ButtonRadioProps)} />;
    case ButtonsType.CHECKBOX:
      return <ButtonCheckbox {...(props as ButtonCheckboxProps)} />;
    case ButtonsType.SWITCH:
      return <ButtonSwitch {...(props as ButtonSwitchProps)} />;
    default:
      return null;
  }
});

export const Buttons = React.memo(
  React.forwardRef<HTMLButtonElement | HTMLDivElement, ButtonsProps>(
    (
      {
        format,
        onBlur,
        onChange,
        options,
        type,
        selectAll,
        handleSelectedValue,
        row,
        ...props
      },
      ref
    ) => {
      const className = clsx(
        styles["buttons"],
        styles[`${type}`],
        styles[`${format}`],
        selectAll && styles["select-all"],
        row && styles["row"]
      );

      const handleBlur = useCallback(() => {
        onBlur();
      }, []);

      const handleClick = useCallback((value) => {
        onChange(value);
        if (handleSelectedValue) handleSelectedValue(value);
      }, []);

      return (
        <ul className={className} onBlur={handleBlur}>
          {options?.length && type === ButtonsType.CHECKBOX ? (
            <>
              {selectAll && (
                <li>
                  <Button
                    selectAll={selectAll}
                    index={99}
                    onClick={handleClick}
                    option={SelectAllOption}
                    options={options}
                    readonly={props.readOnly}
                    type={type}
                    {...props}
                  />
                </li>
              )}
              {options.map((option, index) => (
                <li key={option.label}>
                  <Button
                    index={index}
                    onClick={handleClick}
                    option={option}
                    type={type}
                    readonly={
                      props.readOnly ? props.readOnly : option.isRequired
                    }
                    {...props}
                  />
                </li>
              ))}
            </>
          ) : (
            options?.map((option, index) => (
              <li key={option.label}>
                <Button
                  index={index}
                  onClick={handleClick}
                  option={option}
                  type={type}
                  {...props}
                />
              </li>
            ))
          )}
        </ul>
      );
    }
  )
);

export default Buttons;
