import React, { useState } from "react";
import { Control } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import clsx from "clsx";

import { api, API } from "modules/api";

import * as Question from "components/Questions";
import * as Tooltip from "components/Tooltip";
import { ReactComponent as ChevronDownIcon } from "modules/theme/icons/icon-chevron-down.svg";

import styles from "./styles.module.scss";

type DiagnosticType = {
  id: number;
  code: string;
  statement: string;
  isPositive: string | null;
};

type DiagnosticProps = {
  diagnostics: DiagnosticType[];
  control: Control;
  readOnly?: boolean;
};

type questiosnParam = {
  assessmentId: string;
  perspectiveCode: string;
  threadCode: string;
};

const switchOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const Diagnostic = React.forwardRef<HTMLInputElement, DiagnosticProps>(
  ({ diagnostics, control, readOnly }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const className = clsx(styles["diagnostic"], isOpen && styles["is-open"]);

    const {
      params: { assessmentId },
    } = useRouteMatch<questiosnParam>();

    const handleSelectedValue = async (
      evt: boolean | string,
      diagnosticId: number
    ) => {
      const request = {
        diagnosticId,
        isPositive: evt === "" ? null : evt,
      };

      await api(
        API.PUT_QUESTION_DIAGNOSTIC(
          request,
          assessmentId,
          diagnosticId.toString()
        )
      );
    };

    return (
      <div className={className}>
        <h3 onClick={() => setIsOpen(!isOpen)} tabIndex={0}>
          <span>
            Optional diagnostic attributes
            {isOpen && (
              <span
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                }}
              >
                <Tooltip.Info>
                  <p>Good practice is present ({String.fromCharCode(10003)})</p>
                  <p>Improvement needed (!)</p>
                </Tooltip.Info>
              </span>
            )}
          </span>
          <ChevronDownIcon />
        </h3>

        {diagnostics && isOpen && (
          <div className={styles.dropdown}>
            {diagnostics.map((d) => (
              <Question.QuestionSwitch
                key={d.id}
                control={control}
                label={d.statement}
                name={`${d.code}`}
                options={switchOptions}
                simple
                readOnly={readOnly}
                handleSelectedValue={(evt) => handleSelectedValue(evt, d.id)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default React.memo(Diagnostic);
