export const capabilities = [
  { name: "Continual Improvement", id: 11, code: "CAPCOI" },
  { name: "Architecture management", id: 12, code: "CAPARM" },
  { name: "Information security management", id: 13, code: "CAPISM" },
  { name: "Knowledge management", id: 14, code: "CAPKLM" },
  { name: "Measurement and reporting", id: 15, code: "CAPMAR" },
  { name: "Organizational change management", id: 16, code: "CAPOCM" },
  { name: "Portfolio management", id: 17, code: "CAPPTM" },
  { name: "Project management", id: 18, code: "CAPPRM" },
  { name: "Relationship management", id: 19, code: "CAPRLM" },
  { name: "Risk management", id: 20, code: "CAPRIM" },
  { name: "Service financial management", id: 21, code: "CAPSFM" },
  { name: "Strategy management", id: 22, code: "CAPSTM" },
  { name: "Supplier management", id: 23, code: "CAPSUM" },
  { name: "Workforce and talent management", id: 24, code: "CAPWTM" },
];

export const servicePractice = [
  { name: "Availability management", id: 25, code: "SVCAVM" },
  { name: "Business analysis", id: 26, code: "SVCBUA" },
  { name: "Capacity and performance management", id: 27, code: "SVCCPM" },
  { name: "Change enablement", id: 28, code: "SVCCGE" },
  { name: "Incident management", id: 29, code: "SVCICM" },
  { name: "IT asset management", id: 30, code: "SVCIAM" },
  { name: "Monitoring and event management", id: 31, code: "SVCMEM" },
  { name: "Problem management", id: 32, code: "SVCPRM" },
  { name: "Release management", id: 33, code: "SVCRLM" },
  { name: "Service catalogue management", id: 34, code: "SVCSCTM" },
  { name: "Service configuration management", id: 35, code: "SVCSCFM" },
  { name: "Service continuity management", id: 36, code: "SVCSCOM" },
  { name: "Service design", id: 37, code: "SVCSDG" },
  { name: "Service desk", id: 38, code: "SVCSDE" },
  { name: "Service level management", id: 39, code: "SVCSLM" },
  { name: "Service request management", id: 40, code: "SVCSRM" },
  { name: "Service validation and testing", id: 41, code: "SVCSVT" },
];

export const technicalPractice = [
  { name: "Deployment management", id: 42, code: "TECDPM" },
  { name: "Infrastructure and platform management", id: 43, code: "TECIPM" },
  { name: "Software development and management", id: 44, code: "TECSDM" },
];

export const BaseOptions = [
  { label: "Primary", value: "1" },
  { label: "Supporting", value: "2" },
  { label: "N/A", value: "N/A" },
];

export const maturities = [
  { name: "Guiding Principles", id: 51, code: "MATGDP" },
  { name: "Governance", id: 52, code: "MATGOV" },
  { name: "Service Value Chain", id: 53, code: "MATSVC" },
  { name: "Practices", id: 54, code: "MATPCT" },
  { name: "Continual Improvement", id: 55, code: "MATCIM" },
];

export const allPractices = [
  ...capabilities,
  ...servicePractice,
  ...technicalPractice,
];

export const ProgressPages = [
  {
    id: 1,
    isEnabled: true,
    isActive: false,
    isComplete: true,
    name: "Assessment details",
    route: "profile/itil",
  },
  {
    id: 2,
    isEnabled: true,
    isActive: true,
    isComplete: false,
    name: "Scope details",
    route: "scope/itil",
  },
  {
    id: 3,
    isEnabled: true,
    isActive: false,
    isComplete: false,
    name: "Review profile",
    route: "review/itil",
  },
];
