import React from "react";

import { ReactComponent as CertifiedIcon } from "modules/theme/icons/icon-certified-green.svg";

import Headings from "components/Headings";
import { Organization } from "modules/api/endpoints/organizationGet";
import Button, { ButtonStyle, ButtonType, ButtonSize } from "components/Button";

import { Items, ProgressType, ProgressItem } from "./index";

import Section from "components/Section";
import Sidebar from "components/Sidebar";
import Loading from "components/Loading";
import Note from "components/Notes";

import styles from "./styles.module.scss";

import OrganizationDetails from "components/Headings/OrganizationDetails";
import PageWrapper from "components/Wrapper";

type TemplateProps = {
  isLoading: boolean;
  isArchived: boolean;
  perspectives: Items[];
  threads: Items[];
  organization: Organization;
  displayCertifyAssessment: boolean;
  scope: string;
  name: string;
  handleNavegate: (perspective: string, thread: string) => void;
  handleCertify: () => void;
  progress: ProgressType;
  isAssessmentSubmitable: boolean;
  isSubmitingAssessment: boolean;
  handleSubmitAssessment: () => void;
  isAssessmentLocked: string;
  handleThreadStatus: (item: ProgressItem) => string;
  handleUnarchive: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  isArchived,
  organization,
  scope,
  name,
  displayCertifyAssessment,
  handleNavegate,
  handleCertify,
  progress,
  isAssessmentSubmitable,
  isSubmitingAssessment,
  handleSubmitAssessment,
  isAssessmentLocked,
  handleThreadStatus,
  handleUnarchive,
}) => {
  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <Section>
            <>
              <Headings title={name} subtitle={"Assessment progress summary"} />
              <PageWrapper>
                <div className={styles.tableWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <th>{scope}</th>
                        <th>
                          <span>
                            Current decimal score:{" "}
                            {progress.currentOverallScore.toFixed(2)}
                          </span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {progress.perspectivesProgress.map((pp) => {
                        const descopedThreads = pp.threads.filter(
                          (t) => t.descoped
                        );
                        return (
                          <React.Fragment key={pp.id}>
                            <tr className={styles.perspectiveHead}>
                              <th>{pp.name}</th>
                              <th>
                                <span>
                                  Current decimal score:{" "}
                                  {pp.currentScore.toFixed(2)}
                                </span>
                                <span>
                                  {pp.answeredThreads}/
                                  {pp.totalThreads - descopedThreads.length}{" "}
                                  Threads complete
                                </span>
                              </th>
                              <th></th>
                            </tr>
                            <tr className={styles.bodyHead}>
                              <th>Threads</th>
                              <th>Current score</th>
                              <th></th>
                            </tr>
                            {pp.threads.map((t) => {
                              return (
                                <tr
                                  key={pp.id + t.name}
                                  className={handleThreadStatus(t)}
                                >
                                  <td>{t.name}</td>
                                  <td>{t.descoped ? "Descoped" : t.score}</td>
                                  <td>
                                    <Button
                                      label="Edit"
                                      style={ButtonStyle.PRIMARY}
                                      size={ButtonSize.INLINE}
                                      onClick={() =>
                                        handleNavegate(pp.name, t.name)
                                      }
                                      disabled={isArchived}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </PageWrapper>
              <div className={styles.pageActions}>
                <Button
                  disabled={
                    isAssessmentLocked === "Completed" || isArchived
                      ? true
                      : !isAssessmentSubmitable
                  }
                  onClick={handleSubmitAssessment}
                  isLoading={isSubmitingAssessment}
                  label="Submit assessment"
                  style={ButtonStyle.PRIMARY}
                  type={ButtonType.BUTTON}
                />
              </div>
            </>
          </Section>
          <Sidebar>
            <OrganizationDetails
              name={organization.name}
              country={organization.country.name}
              businessUnit={organization.businessUnit}
            ></OrganizationDetails>
            {isArchived && (
              <Note>
                <p>
                  This assessment has been archived.
                  <strong
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={handleUnarchive}
                  >
                    &nbsp;Click here&nbsp;
                  </strong>
                  to restore if you would like to continue working on it.
                </p>
              </Note>
            )}
            {displayCertifyAssessment && (
              <>
                <Note>
                  <CertifiedIcon />
                  <h3>Want to certify this assessment? </h3>
                  <p>
                    By selecting this option you confirm you have received
                    consent from the end customer to share data with AXELOS.
                  </p>
                  <p>
                    Certifying this assessment will require all perspectives and
                    four mandatory threads to be assessed.
                  </p>
                  <p>
                    Please note that certification is subject to a fee. More
                    information available here:{" "}
                    <a href="https://eu-assets.contentstack.com/v3/assets/blt637b065823946b12/bltce88ffa4d3ed241b/Axelos_Maturity_Level_Certification_2021.pdf">
                      link
                    </a>
                  </p>
                  <Button
                    label="Certify assessment"
                    style={ButtonStyle.PRIMARY}
                    type={ButtonType.BUTTON}
                    onClick={handleCertify}
                    disabled={isArchived}
                  />
                </Note>
              </>
            )}
          </Sidebar>
        </>
      )}
      {isLoading && <Loading noBackground />}
    </React.Fragment>
  );
};

export default Template;
