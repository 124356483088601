import React from "react";
import { useController, Control } from "react-hook-form";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import QuestionTemplate from "../QuestionTemplate";
import Debug from "../QuestionTemplate/Debug";
import { Expand } from "modules/typescript";
import Buttons from "components/Inputs/Buttons";
import {
  ButtonsFormat,
  ButtonsType,
} from "components/Inputs/Buttons/constants";
import { OptionType } from "components/Inputs/Buttons/types";

type QuestionRadioProps = Expand<
  {
    control: Control;
    format?: ButtonsFormat;
    options?: OptionType[];
    selectedValue?: (value: number | string) => void;
  } & Omit<QuestionTemplateProps, "answered" | "children" | "invalid">
>;

const QuestionRadio: React.FunctionComponent<QuestionRadioProps> = ({
  control,
  format,
  name,
  required,
  selectedValue,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });

  if (selectedValue) selectedValue(field.value);

  return (
    <QuestionTemplate
      answered={field.value && !invalid}
      invalid={invalid}
      name={name}
      required={required}
      {...props}
    >
      <Buttons
        format={format}
        invalid={invalid}
        type={ButtonsType.RADIO}
        {...props}
        {...field}
      />
      {/* {process.env.NODE_ENV === "development" && <Debug value={field.value} />} */}
    </QuestionTemplate>
  );
};

export default React.memo(QuestionRadio);
