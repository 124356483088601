import React from "react";

import { htmlSafe } from "modules/utils";

import styles from "../styles.module.scss";

type InfoProps = {
  info: string | JSX.Element;
};

const Info: React.FunctionComponent<InfoProps> = ({ info }) => {
  return (
    <div className={styles["info"]}>
      <React.Fragment>
        {typeof info === "string" ? htmlSafe(info) : info}
      </React.Fragment>
    </div>
  );
};

export default React.memo(Info);
