import yup from "modules/validation";

export function getAssessmentProfileSchema(
  hasSelectedScope: boolean
): yup.AnyObjectSchema {
  return yup.object().shape({
    assessmentType: yup.string().required(),
    certified: yup.bool().notRequired(),
    endorsed: yup.bool().notRequired(),
    name: yup.string().required(),
    selectedScope: hasSelectedScope
      ? yup.mixed().notRequired()
      : yup.mixed().required(),
  });
}
