import React from "react";
import { HelmetProvider } from "react-helmet-async";

import { AuthProvider } from "./auth";
import { ModalProvider } from "./modal";

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <HelmetProvider>
        <ModalProvider>{children}</ModalProvider>
      </HelmetProvider>
    </AuthProvider>
  );
};

export default AppProvider;
