import React from "react";

import styles from "./styles.module.scss";

type OrganizationDetailsProps = {
  name: string;
  country: string;
  businessUnit: string;
};

const OrganizationDetails: React.FunctionComponent<
  OrganizationDetailsProps
> = ({ name, country, businessUnit }) => {
  return (
    <div className={styles["organization-details"]}>
      <h4>{name ? name : "Not Provided"}</h4>
      <p>
        Location: <strong>{country ? country : "Not Provided"}</strong>
      </p>
      <p>
        Business unit:{" "}
        <strong>{businessUnit ? businessUnit : "Not Provided"}</strong>
      </p>
    </div>
  );
};

export default OrganizationDetails;
