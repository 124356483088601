import React from "react";

import { OrganizationSchema } from "./schema";
import { OrganizationStatesType, FormOptionType } from "./types";
import { FormType } from "./enum";

import * as Form from "components/Form";
import * as Question from "components/Questions";
import Headings from "components/Headings";
import Section from "components/Section";
import OrganizationDetails from "components/Headings/OrganizationDetails";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import Loading from "components/Loading";
import Note from "components/Notes";
import Sidebar from "components/Sidebar";

type TemplateProps = {
  children?: React.ReactNode;
  isLoading: boolean;
  isSavingOrganization: boolean;
  isCreatingAssessment: boolean;
  countriesData: FormOptionType[];
  industriesData: FormOptionType[];
  sizesData: FormOptionType[];
  formStates: OrganizationStatesType;
  formType: string;
  hasNoCompany: boolean;
  country?: string;
  handleHeadingsTitle: () => string;
  handleCreateOrganization: (data: OrganizationStatesType) => void;
  handleEditOrganization: (data: OrganizationStatesType) => void;
  handleNewAssessment: (data: OrganizationStatesType) => void;
  handleGoBack: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  isSavingOrganization,
  isCreatingAssessment,
  countriesData,
  industriesData,
  sizesData,
  formStates,
  formType,
  hasNoCompany,
  country,
  handleCreateOrganization,
  handleEditOrganization,
  handleNewAssessment,
  handleGoBack,
  handleHeadingsTitle,
}) => {
  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <Section>
            <Headings title={handleHeadingsTitle()} />
            <Form.Wrapper
              onSubmit={handleCreateOrganization}
              schema={OrganizationSchema}
              state={formStates}
              mode={"all"}
            >
              {({
                control,
                register,
                errors,
                formState: { isValid, isDirty },
                getValues,
              }) => {
                return (
                  <>
                    <Form.Fieldset
                      column
                      title={
                        formType === FormType.CREATE
                          ? "Create an organization profile"
                          : ""
                      }
                    >
                      <Question.QuestionOrganizationProfile
                        error={errors}
                        control={control}
                        register={register}
                        sizes={sizesData}
                        countries={countriesData}
                        industries={industriesData}
                      />
                    </Form.Fieldset>

                    <Form.Actions>
                      <>
                        {formType === FormType.EDIT ? (
                          <>
                            <Button
                              label="Go Back"
                              style={ButtonStyle.NEGATIVE}
                              type={ButtonType.BUTTON}
                              onClick={handleGoBack}
                            />
                            <Button
                              disabled={!isValid || !isDirty}
                              label="Save changes"
                              style={ButtonStyle.PRIMARY}
                              type={ButtonType.BUTTON}
                              onClick={() =>
                                handleEditOrganization(getValues())
                              }
                              isLoading={isSavingOrganization}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              disabled={!isValid || !isDirty}
                              label="Save organization"
                              style={ButtonStyle.SECONDARY}
                              type={ButtonType.BUTTON}
                              onClick={() =>
                                handleCreateOrganization(getValues())
                              }
                              isLoading={isSavingOrganization}
                            />
                            <Button
                              disabled={!isValid || !isDirty}
                              label="Create assessment"
                              style={ButtonStyle.PRIMARY}
                              type={ButtonType.BUTTON}
                              onClick={() => handleNewAssessment(getValues())}
                              isLoading={isCreatingAssessment}
                            />
                          </>
                        )}
                      </>
                    </Form.Actions>
                  </>
                );
              }}
            </Form.Wrapper>
          </Section>
          <Sidebar>
            <>
              {hasNoCompany && (
                <Note>
                  <p>
                    Before you can start your assessment you will need to create
                    an organization profile for the organization to be assessed.
                  </p>
                </Note>
              )}
              {formType === FormType.EDIT && (
                <OrganizationDetails
                  name={formStates.name}
                  country={country ? country : ""}
                  businessUnit={formStates.businessUnit}
                />
              )}
            </>
          </Sidebar>
        </React.Fragment>
      )}
      {isLoading && <Loading noBackground />}
    </React.Fragment>
  );
};

export default Template;
