export const generateMetaTitle = (path: string) => {
  let title;
  // perspectives
  const assetManagement = "Asset Management",
    assurance = "Assurance",
    behaviours = "Behaviours",
    commercialManagementCommissioner = "Commercial Management, Commissioner",
    commercialManagementDeliverer = "Commercial Management, Deliverer",
    informationKnowledgeManagement = "Information and Knowledge Management",
    infrastructureTools = "Infrastructure and Tools",
    modelIntegration = "Model Integration",
    organization = "Organization",
    planning = "Planning",
    process = "Process",
    standards = "Standards",
    techniques = "Techniques";

  // threads
  const organizationalGovernance = "Organizational Governance",
    managementControl = "Management Control",
    financeManagement = "Finance Management",
    benefitsManagement = "Benefits Management",
    stakeholderManagement = "Stakeholder Management",
    resourceManagement = "Resource Management",
    riskManagement = "Risk Management";

  const titleEnd = "| Axelos Maturity Assessment Tool";
  switch (path) {
    case "OG/AM":
      title = `${assetManagement} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/ASS":
      title = `${assurance} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/BHV":
      title = `${behaviours} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/CB":
      title = `${commercialManagementCommissioner} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/CS":
      title = `${commercialManagementDeliverer} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/IKM":
      title = `${informationKnowledgeManagement} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/INFT":
      title = `${infrastructureTools} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/MI":
      title = `${modelIntegration} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/ORG":
      title = `${organization} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/PLN":
      title = `${planning} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/PRO":
      title = `${process} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/STD":
      title = `${standards} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "OG/TCH":
      title = `${techniques} - ${organizationalGovernance} ${titleEnd}`;
      break;
    case "MC/AM":
      title = `${assetManagement} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/ASS":
      title = `${assurance} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/BHV":
      title = `${behaviours} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/CB":
      title = `${commercialManagementCommissioner} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/CS":
      title = `${commercialManagementDeliverer} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/IKM":
      title = `${informationKnowledgeManagement} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/INFT":
      title = `${infrastructureTools} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/MI":
      title = `${modelIntegration} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/ORG":
      title = `${organization} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/PLN":
      title = `${planning} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/PRO":
      title = `${process} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/STD":
      title = `${standards} - ${managementControl} ${titleEnd}`;
      break;
    case "MC/TCH":
      title = `${techniques} - ${managementControl} ${titleEnd}`;
      break;
    case "FM/AM":
      title = `${assetManagement} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/ASS":
      title = `${assurance} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/BHV":
      title = `${behaviours} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/CB":
      title = `${commercialManagementCommissioner} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/CS":
      title = `${commercialManagementDeliverer} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/IKM":
      title = `${informationKnowledgeManagement} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/INFT":
      title = `${infrastructureTools} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/MI":
      title = `${modelIntegration} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/ORG":
      title = `${organization} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/PLN":
      title = `${planning} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/PRO":
      title = `${process} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/STD":
      title = `${standards} - ${financeManagement} ${titleEnd}`;
      break;
    case "FM/TCH":
      title = `${techniques} - ${financeManagement} ${titleEnd}`;
      break;
    case "BM/AM":
      title = `${assetManagement} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/ASS":
      title = `${assurance} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/BHV":
      title = `${behaviours} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/CB":
      title = `${commercialManagementCommissioner} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/CS":
      title = `${commercialManagementDeliverer} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/IKM":
      title = `${informationKnowledgeManagement} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/INFT":
      title = `${infrastructureTools} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/MI":
      title = `${modelIntegration} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/ORG":
      title = `${organization} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/PLN":
      title = `${planning} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/PRO":
      title = `${process} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/STD":
      title = `${standards} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "BM/TCH":
      title = `${techniques} - ${benefitsManagement} ${titleEnd}`;
      break;
    case "SM/AM":
      title = `${assetManagement} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/ASS":
      title = `${assurance} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/BHV":
      title = `${behaviours} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/CB":
      title = `${commercialManagementCommissioner} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/CS":
      title = `${commercialManagementDeliverer} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/IKM":
      title = `${informationKnowledgeManagement} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/INFT":
      title = `${infrastructureTools} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/MI":
      title = `${modelIntegration} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/ORG":
      title = `${organization} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/PLN":
      title = `${planning} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/PRO":
      title = `${process} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/STD":
      title = `${standards} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "SM/TCH":
      title = `${techniques} - ${stakeholderManagement} ${titleEnd}`;
      break;
    case "ReM/AM":
      title = `${assetManagement} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/ASS":
      title = `${assurance} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/BHV":
      title = `${behaviours} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/CB":
      title = `${commercialManagementCommissioner} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/CS":
      title = `${commercialManagementDeliverer} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/IKM":
      title = `${informationKnowledgeManagement} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/INFT":
      title = `${infrastructureTools} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/MI":
      title = `${modelIntegration} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/ORG":
      title = `${organization} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/PLN":
      title = `${planning} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/PRO":
      title = `${process} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/STD":
      title = `${standards} - ${resourceManagement} ${titleEnd}`;
      break;
    case "ReM/TCH":
      title = `${techniques} - ${resourceManagement} ${titleEnd}`;
      break;
    case "RiM/AM":
      title = `${assetManagement} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/ASS":
      title = `${assurance} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/BHV":
      title = `${behaviours} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/CB":
      title = `${commercialManagementCommissioner} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/CS":
      title = `${commercialManagementDeliverer} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/IKM":
      title = `${informationKnowledgeManagement} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/INFT":
      title = `${infrastructureTools} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/MI":
      title = `${modelIntegration} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/ORG":
      title = `${organization} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/PLN":
      title = `${planning} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/PRO":
      title = `${process} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/STD":
      title = `${standards} - ${riskManagement} ${titleEnd}`;
      break;
    case "RiM/TCH":
      title = `${techniques} - ${riskManagement} ${titleEnd}`;
      break;
    default:
      title = "Axelos Maturity Assessment Tool";
  }
  return title;
};
