import React from "react";

import styles from "./styles.module.scss";

type WrapperProps = {
  title: string;
  children?: React.ReactNode;
};

const Wrapper: React.FunctionComponent<WrapperProps> = ({
  children,
  title,
}) => {
  return (
    <section className={styles["progress"]}>
      <h3>{title}</h3>
      {children && (
        <nav>
          <ul>{children}</ul>
        </nav>
      )}
    </section>
  );
};

export default React.memo(Wrapper);
