import React, { useRef, useLayoutEffect, useState } from "react";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { ReactComponent as DownloadSVG } from "modules/theme/icons/buttons/download.svg";
import { ReactComponent as PostiveSVG } from "modules/theme/icons/icon-positive.svg";
import { ReactComponent as ImprovementSVG } from "modules/theme/icons/icon-improvement.svg";
import styles from "./styles.module.scss";
import { exportComponentAsJPEG } from "react-component-export-image";
type TableGroupWrapperProps = {
  children?: React.ReactNode;
  positive?: boolean;
  gmTagId?: string;
};

const TableGroupWrapper: React.FunctionComponent<TableGroupWrapperProps> = ({
  children,
  positive,
  gmTagId,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    if (componentRef.current) {
      setDimensions({
        width: componentRef.current.offsetWidth,
        height: componentRef.current.offsetHeight,
      });
    }
  }, []);
  return (
    <div className={styles.TableGroupWrapper}>
      <div className={styles.TableDownloadContainer} ref={componentRef}>
        <h2 className={styles["TableGroupWrapper__title"]}>
          {positive ? (
            <>
              <span className={"TableGroupWrapper__title-icon"}>
                <PostiveSVG />
              </span>
              Positive attributes
            </>
          ) : (
            <>
              <span className={"TableGroupWrapper__title-icon"}>
                <ImprovementSVG />
              </span>
              Areas for improvement
            </>
          )}
        </h2>
        {children}
      </div>
      <div className={styles["TableGroupWrapper__image-export"]}>
        <Button
          label="Download image"
          style={ButtonStyle.SECONDARY}
          type={ButtonType.BUTTON}
          id={gmTagId}
          onClick={() =>
            exportComponentAsJPEG(componentRef, {
              fileName: "Diagnostics",
              html2CanvasOptions: {
                scrollX: 10,
                width: dimensions.width + 20,
              },
            })
          }
        >
          <DownloadSVG />
        </Button>
      </div>
    </div>
  );
};

export default TableGroupWrapper;
