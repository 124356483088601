/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { api, API } from "modules/api";
import { AssessmentGetResponseResult } from "modules/api/endpoints/assessmentGet";

import { ProgressBarPageType } from "components/Progress/Simple/index";
import { ProgressPagesItil } from "../constants";

import { ErrorMessagesType, assessmentErrorMessages } from "modules/messages";
import { useModal } from "modules/hooks/modal";

import { allPractices } from "../../Scope/Itil/constants";
import { AssessmentScopeStatesType } from "../../Scope/types";

import Template from "./Template";
import UseLockAssessment from "routes/questions/hooks/useLockAssessment";
import { getErrorMessages } from "modules/messages/helpers";

type assessmentParam = {
  assessmentId: string;
};

type CapabilityType = {
  code: string;
  id: number;
  name: string;
  selectionMode: number;
};

const ItilReviewAssessment: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [assessment, setAssessment] = useState<AssessmentScopeStatesType>(
    {} as AssessmentScopeStatesType
  );
  const [progressBarPages, setProgressBarPages] = useState<
    ProgressBarPageType[]
  >([]);

  const [activePractices, setActivePractices] = useState<CapabilityType[]>([]);

  const errorConfirmation = useModal();

  const {
    params: { assessmentId }
  } = useRouteMatch<assessmentParam>();

  const history = useHistory();

  useEffect(() => {
    return () => {
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (!isAssessmentValid(assessmentId)) {
      return history.push("/");
    }

    const getAssessmentAndSetStates = async () => {
      try {
        const response = await api(API.GET_ASSESSMENT(assessmentId)).then(
          (res) => res.data
        );
        if (!response && isCancelled) return history.push("/");

        setActivePractices(() => {
          return response.capabilities.sort(
            (a: any, b: any) => a.selectionMode - b.selectionMode
          );
        });

        setAssessment(response);
        setProgressBarPages(() => {
          const patchPages = [...ProgressPagesItil];
          return patchPages.map((p) => {
            return {
              ...p,
              isEnabled: true,
              isComplete: p.id !== 3
            };
          });
        });
        setIsLoading(false);
      } catch (error: any) {
        const messages = getErrorMessages(
          assessmentErrorMessages,
          error?.response?.status
        );
        await errorConfirmation({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description
        }).finally(() => apiErrorRedirection(error?.response?.status));
      }
    };
    getAssessmentAndSetStates();
    return () => {
      isCancelled = true;
    };
  }, [assessmentId]);

  const apiErrorRedirection = (error = 400) => {
    if (error && error === 401) {
      window.location.assign("https://www.axelos.com/");
      return;
    }
    history.push("/");
  };

  const isAssessmentValid = useCallback((assemt: string) => {
    return !!assemt;
  }, []);

  const handleStartAssessment = () => {
    if (activePractices) {
      history.push(
        `/itil/assessments/${assessmentId}/questions/${activePractices[0]?.code}/`
      );
    }
  };

  return (
    <Template
      isLoading={isLoading}
      assessment={assessment}
      handleStartAssessment={handleStartAssessment}
      progressBarPages={progressBarPages}
      activePractices={activePractices}
    />
  );
};

export default ItilReviewAssessment;
