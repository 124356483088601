import { Answers } from "routes/questions/constants";

export const getAnswerType = (selection: string): string => {
  switch (selection) {
    case "1":
      return Answers.FULLY;
    case "2":
      return Answers.PARTIALLY;
    case "3":
      return Answers.RARELY;
    case "":
    default:
      return Answers.NA;
  }
};

export const getAnswerScore = (selection: string): number => {
  switch (selection) {
    case Answers.FULLY:
      return 1;
    case Answers.PARTIALLY:
      return 0.5;
    case Answers.RARELY:
    case Answers.NA:
    default:
      return 0;
  }
};
