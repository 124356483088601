export type { ErrorMessagesType, ErrorMessageType } from "./types";

export const assessmentErrorMessages = {
  unauthorized: {
    title: "Sorry, access denied.",
    description: "You have no credentials to access this assessment.",
  },
  badRequest: {
    title: "Sorry, something went wrong...",
    description: "Please, try again later or get in touch",
  },
  notFound: {
    title: "Assessment not found.",
    description: "The assessment you are trying to access does not exist.",
  },
};

export const organizationErrorMessages = {
  unauthorized: {
    title: "Sorry, your credentials has expired.",
    description: "Please, try to login again or get in touch",
  },
  badRequest: {
    title: "Sorry, something went wrong...",
    description: "Please, try again later or get in touch",
  },
  notFound: {
    title: "Organization not found.",
    description: "The organization you are trying to access does not exist.",
  },
};

export const resultsErrorMessages = {
  unauthorized: {
    title: "Sorry, access denied.",
    description: "You have no credentials to access this assessment.",
  },
  badRequest: {
    title: "Sorry, something went wrong...",
    description: "Please, try again later or get in touch",
  },
  notFound: {
    title: "Result not found.",
    description: "The result you are trying to access does not exist.",
  },
};

export const genericErrorMessages = {
  unauthorized: {
    title: "Sorry, access denied.",
    description: "You have no credentials to access this assessment.",
  },
  badRequest: {
    title: "Sorry, something went wrong...",
    description: "Please, try again later or get in touch",
  },
  notFound: {
    title: "Result not found.",
    description: "The result you are trying to access does not exist.",
  },
};
