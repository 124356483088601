import { api, API } from "modules/api";

type UseLockAssessmentProps = (
  path: string,
  id: number
) => () => Promise<boolean>;

const UseLockAssessment: UseLockAssessmentProps = (path, id) => {
  const validPaths = [
    "/",
    "/organization",
    "/logout",
    `/assessments/${id}/result`,
    `/assessments/${id}/result/itil`,
  ];
  return async () => {
    if (validPaths.includes(path)) {
      try {
        const response = await api(API.DELETE_ASSESSMENT_LOCK(id));
        if (response) {
          sessionStorage.removeItem(`@P3M3:lock:${id}`);
          return true;
        }
      } catch (error) {
        return false;
      }
    }

    return false;
  };
};

export default UseLockAssessment;
