import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkDispatch } from "redux-thunk";
import { createSelectorHook } from "react-redux";
import { createBrowserHistory } from "history";
import { isAdmin } from "modules/admin";

import middleware from "./middleware";
import reducers from "./reducers";

const composeEnhancers = composeWithDevTools({});

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const rootReducer = combineReducers({
  router: routerReducer,
  ...reducers,
});

const store = createStore(
  rootReducer,
  isAdmin
    ? composeEnhancers(
        applyMiddleware(thunk, routerMiddleware, ...middleware())
      )
    : applyMiddleware(thunk, routerMiddleware, ...middleware())
);

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector = createSelectorHook<RootState>();

export type Dispatch = ThunkDispatch<RootState, null, AnyAction>;

export default store;
