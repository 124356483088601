import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as XLSX from "xlsx";
import { api, API } from "modules/api";

import { AssessmentGetResponse } from "modules/api/endpoints/assessmentGet";
import { Organization } from "modules/api/endpoints/organizationGet";
import { ChartData } from "components/Charts/Radar";
import {
  ResultType,
  AssessmentResultGetResponse,
} from "modules/api/endpoints/assessmentResultGet";

import { ErrorMessagesType, resultsErrorMessages } from "modules/messages";
import { useModal } from "modules/hooks/modal";

import { GetHelpersResponse } from "modules/api/endpoints/helpersGet";

import {
  PerspectiveType,
  GetPerspectivesResponse,
} from "modules/api/endpoints/perspectivesGet";

import Template from "./Template";
import { getErrorMessages } from "modules/messages/helpers";
import MetaTitle from "components/MetaTitle";

type ResultsParams = {
  assessmentId: string;
};

enum AssessmentStatus {
  ACTIVE = "Active",
  COMPLETED = "Completed",
}

type AssessmentRoute = {
  perspectiveCode: string;
  threadCode: string;
};

type PerspectiveAndScoreType = {
  perspective: string;
  perspectiveId: string;
  score: string;
};

export type BenchmarkDataType = {
  message: string;
  perspectivesAndScores: PerspectiveAndScoreType[];
};

const AssessmentResult: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState<ResultType>({} as ResultType);
  const [organization, setOrganization] = useState<Organization>(
    {} as Organization
  );
  const [perspectives, setPerspectives] = useState<PerspectiveType[]>([]);
  const [benchmarking, setBenchmarking] = useState<ChartData>({} as ChartData);
  const [isCertified, setIsCertified] = useState(false);
  const [assessmentRoute, setAssessmentRoute] = useState<AssessmentRoute>(
    {} as AssessmentRoute
  );
  const [assessmentName, setAssessmentName] = useState("");
  const [displayBenchmarkingData, setDisplayBenchmarkingData] = useState(true);
  const [banchMarkDataScore, setBanchMarkDataScore] =
    useState<BenchmarkDataType>({} as BenchmarkDataType);

  const errorConfirmation = useModal();

  const {
    params: { assessmentId },
  } = useRouteMatch<ResultsParams>();

  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;
    if (!isAssessmentValid(assessmentId)) {
      return history.push("/");
    }
    const getAssessmentResult = async () => {
      try {
        const [perspectivesData, countries, industries, sizes] =
          await Promise.all([
            api(API.GET_PERSPECTIVES()).then(
              (res: GetPerspectivesResponse) => res.data
            ),
            api(API.GET_COUNTRIES()).then(
              (res: GetHelpersResponse) => res.data
            ),
            api(API.GET_INDUSTRIES()).then(
              (res: GetHelpersResponse) => res.data
            ),
            api(API.GET_SIZES()).then((res: GetHelpersResponse) => res.data),
          ]);

        if (perspectivesData) {
          setPerspectives(perspectivesData);
        }

        const assessment = await api(API.GET_ASSESSMENT(assessmentId)).then(
          (assessmentResponse: AssessmentGetResponse) => assessmentResponse.data
        );

        if (assessment.status !== AssessmentStatus.COMPLETED && isCancelled)
          return history.push("/");

        const response = await api(
          API.GET_ASSESSMENT_RESULT(assessmentId)
        ).then(
          (assessmentResultResponse: AssessmentResultGetResponse) =>
            assessmentResultResponse.data
        );

        if (response) {
          const filteredCountryId = countries.filter(
            (c) => c.id === assessment.organization.country.id
          )[0].id;
          const filteredIndustryId = industries.filter(
            (i) => i.id === assessment.organization.industry.id
          )[0].id;
          const filteredSizeId = sizes.filter(
            (s) => s.id === assessment.organization.size.id
          )[0].id;

          const perspectivesIds = assessment.perspectives.map((p) => `${p.id}`);

          const benchMarkData = await api(
            API.GET_BENCHMARK(
              perspectivesIds,
              filteredCountryId,
              filteredIndustryId,
              filteredSizeId
            )
          ).then((benchMarkResponse) => benchMarkResponse.data);

          setBanchMarkDataScore(benchMarkData);
          setOrganization(assessment.organization);
          setResult(response);
          setIsCertified(assessment.certified);
          setAssessmentName(assessment.name);
          setAssessmentRoute({
            perspectiveCode: assessment.perspectives[0].code,
            threadCode: assessment.threads[0].code,
          });

          setBenchmarking(() => {
            const chartLabels = response.perspectives.map((p) => {
              return p.name;
            });

            const assessmentScore = response.perspectives.map((p) => {
              const roundNumber = p.score.toFixed(2);
              return Number(roundNumber);
            });

            const benchmarkingScore = response.perspectives.map((p) => {
              const benchScoreId = benchMarkData.perspectivesAndScores.filter(
                (bd: PerspectiveAndScoreType) =>
                  Number(bd.perspectiveId) === p.id
              )[0]?.score;
              return !benchScoreId ? 0 : Number(benchScoreId);
            });

            const datasets = [
              {
                label: `${assessment.name} score`,
                data: assessmentScore,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
              },
              {
                label: "Benchmark score",
                data: benchmarkingScore,
                backgroundColor: "rgba(53, 162, 235, 0.2)",
                borderColor: "rgba(53, 162, 235, 1)",
                borderWidth: 1,
              },
            ];

            return {
              labels: chartLabels,
              datasets,
            };
          });

          setIsLoading(false);
        }
      } catch (error: any) {
        const messages = getErrorMessages(
          resultsErrorMessages,
          error?.response?.status
        );
        await errorConfirmation({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description,
        }).finally(() => history.push("/"));
      }
    };

    getAssessmentResult();
    return () => {
      isCancelled = true;
    };
  }, []);

  const isAssessmentValid = useCallback((assemt: string) => {
    return !!assemt;
  }, []);

  const handleViewFullAssessment = () => {
    const { perspectiveCode, threadCode } = assessmentRoute;
    history.push(
      `/assessments/${assessmentId}/questions/${perspectiveCode}/${threadCode}`
    );
  };

  const handleDisplayBenchmarkingData = useCallback(() => {
    setBenchmarking((prevData) => {
      const { labels } = prevData;

      const assessmentScore = result.perspectives.map((p) => {
        const roundNumber = p.score.toFixed(2);
        return Number(roundNumber);
      });

      const benchmarkingScore = result.perspectives.map((p) => {
        const benchScoreId = banchMarkDataScore.perspectivesAndScores.filter(
          (bd: PerspectiveAndScoreType) => Number(bd.perspectiveId) === p.id
        )[0]?.score;
        return !benchScoreId ? 0 : Number(benchScoreId);
      });

      const score = {
        label: `${assessmentName} score`,
        data: assessmentScore,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      };

      const benchmarkScore = {
        label: "Benchmark score",
        data: benchmarkingScore,
        backgroundColor: "rgba(53, 162, 235, 0.2)",
        borderColor: "rgba(53, 162, 235, 1)",
        borderWidth: 1,
      };

      return {
        labels,
        datasets: displayBenchmarkingData
          ? [score]
          : [{ ...score }, { ...benchmarkScore }],
      };
    });

    setDisplayBenchmarkingData((displayData) => !displayData);
  }, [benchmarking]);
  const calcBenchmark = (pId: number, pScore: number, benchmark: any) => {
    const score = (
      pScore -
      Number(
        benchmark?.find((el: any) => Number(el.perspectiveId) === pId)?.score
      )
    ).toFixed(1);
    return score;
  };
  const overallExport = result.perspectives?.map((item) => ({
    Rank: item.rank,
    Perspective: item.name,
    Score: item.score.toFixed(2),
    Benchmark: displayBenchmarkingData
      ? calcBenchmark(
          item.id,
          item.score,
          banchMarkDataScore.perspectivesAndScores
        )
      : "N/A",
  }));
  const threadsExport = result.threads?.map((item) => ({
    Rank: item.rank,
    Thread: item.name,
    Score: item.score.toFixed(2),
  }));

  const improvementAreas = result.diagnostics?.improvement.map((item) => ({
    name: [{ name: item.perspective }],
    threads: item.threads.map((thread) => ({
      Threads: thread.name,
      Statement: thread.items
        .reduce((acc, cur) => `${acc}\n\n${cur.statement}`, "")
        .trim(),
    })),
  }));

  const positiveAreas = result.diagnostics?.positive.map((item) => ({
    name: [{ name: item.perspective }],
    threads: item.threads.map((thread) => ({
      Threads: thread.name,
      Statement: thread.items
        .reduce((acc, cur) => `${acc}\n\n${cur.statement}`, "")
        .trim(),
    })),
  }));

  const notesExport = result.notes?.map((item) => ({
    Perspective: item.perspective,
    Thread: item.thread,
    Note: item.body,
  }));

  const exportToElsx = () => {
    // Sheet 1
    const wf = XLSX.utils.json_to_sheet([]);
    // Titles
    XLSX.utils.sheet_add_json(wf, [{ overalldScores: "Decimal Scores" }], {
      skipHeader: true,
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(wf, [{ threadScores: "Thread Scores" }], {
      skipHeader: true,
      origin: "F1",
    });
    XLSX.utils.sheet_add_json(wf, [{ finalScores: "Final Scores" }], {
      skipHeader: true,
      origin: "J1",
    });
    // Place data on the sheet
    XLSX.utils.sheet_add_json(wf, overallExport, { origin: "A2" });
    XLSX.utils.sheet_add_json(wf, threadsExport, { origin: "F2" });
    XLSX.utils.sheet_add_json(
      wf,
      [
        {
          Assessment: result.overall.decimal.toFixed(2),
          Certification: result.overall.maturity,
        },
      ],
      { skipHeader: true, origin: "J2" }
    );
    const mergeO = { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } };
    const mergeT = { s: { r: 0, c: 5 }, e: { r: 0, c: 7 } };
    const mergeL = { s: { r: 0, c: 9 }, e: { r: 0, c: 10 } };
    /* Merge Cells */
    if (!wf["!merges"]) wf["!merges"] = [];
    wf["!merges"].push(mergeO, mergeT, mergeL);

    // Sheet 2
    const ws = XLSX.utils.json_to_sheet([]);
    if (improvementAreas.length > 0) {
      XLSX.utils.sheet_add_json(ws, [{ title: "Positive areas" }], {
        origin: -1,
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      positiveAreas.forEach((area) => {
        XLSX.utils.sheet_add_json(ws, area.name, {
          origin: -1,
          skipHeader: true,
        });
        XLSX.utils.sheet_add_json(ws, area.threads, { origin: -1 });
        XLSX.utils.sheet_add_json(ws, [{}], { origin: -1 });
      });
    }
    if (improvementAreas.length > 0) {
      XLSX.utils.sheet_add_json(ws, [{ title: "Improvement areas" }], {
        origin: -1,
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      improvementAreas.forEach((area) => {
        XLSX.utils.sheet_add_json(ws, area.name, {
          origin: -1,
          skipHeader: true,
        });
        XLSX.utils.sheet_add_json(ws, area.threads, { origin: -1 });
        XLSX.utils.sheet_add_json(ws, [{}], { origin: -1 });
      });
    }
    if (notesExport.length > 0) {
      XLSX.utils.sheet_add_json(ws, [{ title: "Notes" }], {
        origin: -1,
        skipHeader: true,
      });
      XLSX.utils.sheet_add_json(ws, notesExport, { origin: -1 });
    }
    // Export data
    const wb = {
      Sheets: { Scores: wf, Diagnostics: ws },
      SheetNames: ["Scores", "Diagnostics"],
    };
    const date = new Date();
    const fnDate = `${date.getFullYear()}${
      date.getMonth() + 1
    }${date.getDate()}`;
    const fnName = assessmentName.replace(/\s+/g, "_");
    XLSX.writeFile(wb, `${fnDate}_P3M3_${fnName}.xlsx`);
  };
  return (
    <React.Fragment>
      <MetaTitle title="Assessment Results | Axelos Maturity Assessment Tool" />
      <Template
        isLoading={isLoading}
        diagnostics={result.diagnostics}
        perpectiveScores={result.perspectives}
        threadScores={result.threads}
        organization={organization}
        benchmarking={benchmarking}
        finalScores={result.overall}
        isCertified={isCertified}
        perspectivesData={perspectives}
        handleViewFullAssessment={handleViewFullAssessment}
        assessmentName={assessmentName}
        displayBenchmarkingData={displayBenchmarkingData}
        handleDisplayBenchmarkingData={handleDisplayBenchmarkingData}
        banchMarkDataScore={banchMarkDataScore}
        heatmapScores={result.heatmapScores}
        notes={result.notes}
        exportToElsx={exportToElsx}
      />
    </React.Fragment>
  );
};

export default AssessmentResult;
