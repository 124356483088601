import React from "react";

import Button from "components/Button";

import { ReactComponent as ChevronSVG } from "modules/theme/icons/progress/chevron-down.svg";

export type PerspectiveItem = {
  id: number;
  code: string;
  name: string;
  isSelected: boolean;
};

type PerspectiveHeaderProps = {
  answered?: number;
  open: boolean;
  onClick: () => void;
  total?: number;
  perspective: string;
  currentScore: number;
};

const PerspectiveHeader: React.FunctionComponent<PerspectiveHeaderProps> = ({
  open,
  onClick,
  total,
  answered,
  perspective,
  currentScore,
}) => {
  return open ? (
    <React.Fragment>
      <h4>{perspective}</h4>
      {perspective && (
        <React.Fragment>
          <p>Current decimal score: {currentScore.toFixed(2)}</p>
          <p>
            {answered}/{total} Threads completed
          </p>
        </React.Fragment>
      )}
    </React.Fragment>
  ) : (
    <Button onClick={onClick}>
      <h4>{perspective}</h4>
      {perspective && (
        <React.Fragment>
          <p>Current decimal score: {currentScore.toFixed(2)}</p>
          <p>
            {answered}/{total} Threads completed
          </p>
        </React.Fragment>
      )}
      <ChevronSVG />
    </Button>
  );
};

export default React.memo(PerspectiveHeader);
