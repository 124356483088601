import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import yup from "modules/validation";

import Headings from "components/Headings";
import Section from "components/Section";
import Sidebar from "components/Sidebar";
import OrganizationDetails from "components/Headings/OrganizationDetails";
import * as Progress from "components/Progress";
import Note from "components/Notes";
import * as Question from "components/Questions";
import * as Form from "components/Form";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { ButtonsFormat } from "components/Inputs/Buttons/constants";
import { CapabilityQuestionType } from "modules/api/endpoints/questionsCapabilityGet";
import { unarchiveAssessment } from "modules/utils/ArchiveAssessments";

import clsx from "clsx";

import styles from "./styles.module.scss";

import ItilQuestionAssessment from "components/Questions/ItilAssessment";

import {
  AssessmentType,
  ProgressType,
  QuestionsOptionsType,
  StateType,
  BaseAnswerType,
  FormItemType,
  MatOptionType,
  MaturityQuestionType,
} from "./types";
import Loading from "components/Loading";

type Practice = {
  name: string;
  id: number;
  code: string;
};

type TemplateProps = {
  assessment: AssessmentType;
  formState: StateType;
  currentQuestions: CapabilityQuestionType[];
  handleSetValues: (values: StateType, answers: BaseAnswerType[]) => void;
  handleSetMaturityValues: (values: StateType) => void;
  answers: BaseAnswerType[];
  levelTwoQuestions: QuestionsOptionsType | null;
  levelThreeQuestions: QuestionsOptionsType | null;
  levelFourQuestions: QuestionsOptionsType | null;
  levelFiveQuestions: QuestionsOptionsType | null;
  currentPractice: Practice | undefined | null;
  progress: ProgressType | null;
  isLoading: boolean;
  questionType: string;
  handlePagination: () => void;
  handleSaveAndExit: () => void;
  practiceCode: string;
  paginationButtonLabel: string;
  currentMaturity: FormItemType | null;
  maturityOptions: MatOptionType[];
  currentMaturityPractice: MaturityQuestionType;
  capData: FormItemType[];
  matData: FormItemType[];
  isFirstAnswerSubmitted: boolean;
  handleNotes: (note: string | null) => void;
  isAssessmentCompleted: boolean;
  handleReturnToResults: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  assessment,
  formState,
  handleSetValues,
  handleSetMaturityValues,
  answers,
  currentPractice,
  progress,
  isLoading,
  questionType,
  handlePagination,
  handleSaveAndExit,
  practiceCode,
  paginationButtonLabel,
  currentQuestions,
  levelTwoQuestions,
  levelThreeQuestions,
  levelFourQuestions,
  levelFiveQuestions,
  maturityOptions,
  currentMaturityPractice,
  capData,
  matData,
  isFirstAnswerSubmitted,
  handleNotes,
  isAssessmentCompleted,
  handleReturnToResults,
}) => {
  const initalArchived = assessment ? assessment.isArchived : false;
  const [isArchivedState, setIsArchivedState] =
    useState<boolean>(initalArchived);
  const history = useHistory();

  const handleCreateOrganization = () => {
    console.log("Free");
  };

  // IsArchivedState was initalising to false, presumably from a first-render
  // undefined assessment state. If the prop is true we need to ensure the state
  // is correct.
  useEffect(() => {
    if (initalArchived && initalArchived !== isArchivedState) {
      setIsArchivedState(initalArchived);
    }
  }, [initalArchived, setIsArchivedState]);

  const schema = yup.object().shape({
    maturity: yup.string().notRequired(),
  });
  const assessmentScopes = [
    { label: "Fully", value: "1" },
    { label: "Rarely/Never", value: "2" },
  ];
  const isReadOnly = isAssessmentCompleted || isArchivedState;

  const handleSubtitle = () => {
    if (assessment?.selectedScope === "Capability") return "";

    return ` - ${questionType}`;
  };

  const handlePracticeTitle = () => {
    if (questionType === "Capability") {
      return currentPractice ? currentPractice.name : "";
    }

    return currentMaturityPractice ? currentMaturityPractice.maturity : "";
  };

  const className = clsx(
    styles["itil"],
    questionType === "Maturity" && styles["maturity"]
  );

  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <Section>
            <Headings
              title={assessment?.name}
              subtitle={`${assessment?.selectedScope}${handleSubtitle()}`}
            >
              {isAssessmentCompleted && (
                <OrganizationDetails
                  name={assessment.organization.name}
                  country={assessment.organization.country.name}
                  businessUnit={assessment.organization.businessUnit}
                />
              )}
            </Headings>
            <Form.Wrapper
              onSubmit={handleCreateOrganization}
              mode={"all"}
              state={formState}
              schema={schema}
            >
              {({ control, watch, getValues }) => {
                const fields = watch();
                const { maturity } = getValues();

                return (
                  <div className={className}>
                    <Form.Fieldset title={handlePracticeTitle()}>
                      {questionType === "Capability" && (
                        <>
                          <div className={styles.heading}>
                            <h3>Level 2</h3>
                          </div>

                          {levelTwoQuestions?.control?.isEnabled &&
                            levelTwoQuestions.questions.map((q) => {
                              return (
                                <ItilQuestionAssessment
                                  key={q.id}
                                  control={control}
                                  options={assessmentScopes}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={q.statement}
                                  name={q.code}
                                  shownStatus={
                                    levelTwoQuestions.control.hasAnyAnsweredItem
                                  }
                                  allQuestionsAnswered={
                                    levelTwoQuestions.control
                                      .allQuestionsAnswered
                                  }
                                  handleSelectedValue={() => {
                                    handleSetValues(getValues(), answers);
                                  }}
                                  isAnswering={
                                    fields[q.code] !== "" &&
                                    isFirstAnswerSubmitted
                                  }
                                  readOnly={
                                    isFirstAnswerSubmitted || isReadOnly
                                  }
                                >
                                  <span className={styles.tag}>
                                    {q.dimension}
                                  </span>
                                </ItilQuestionAssessment>
                              );
                            })}
                          {levelThreeQuestions?.control?.isEnabled && (
                            <>
                              <div className={styles.heading}>
                                <h3>Level 3</h3>
                              </div>
                              {levelThreeQuestions?.questions.map((q) => (
                                <ItilQuestionAssessment
                                  key={q.id}
                                  control={control}
                                  options={assessmentScopes}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={q.statement}
                                  name={q.code}
                                  shownStatus
                                  allQuestionsAnswered={
                                    levelThreeQuestions.control
                                      .allQuestionsAnswered
                                  }
                                  handleSelectedValue={() => {
                                    handleSetValues(getValues(), answers);
                                  }}
                                  readOnly={isReadOnly}
                                >
                                  <span className={styles.tag}>
                                    {q.dimension}
                                  </span>
                                </ItilQuestionAssessment>
                              ))}
                            </>
                          )}
                          {levelFourQuestions?.control?.isEnabled && (
                            <>
                              <div className={styles.heading}>
                                <h3>Level 4</h3>
                              </div>
                              {levelFourQuestions?.questions.map((q) => (
                                <ItilQuestionAssessment
                                  key={q.id}
                                  control={control}
                                  options={assessmentScopes}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={q.statement}
                                  name={q.code}
                                  shownStatus
                                  allQuestionsAnswered={
                                    levelFourQuestions.control
                                      .allQuestionsAnswered
                                  }
                                  handleSelectedValue={() => {
                                    handleSetValues(getValues(), answers);
                                  }}
                                  readOnly={isReadOnly}
                                >
                                  <span className={styles.tag}>
                                    {q.dimension}
                                  </span>
                                </ItilQuestionAssessment>
                              ))}
                            </>
                          )}
                          {levelFiveQuestions?.control?.isEnabled && (
                            <>
                              <div className={styles.heading}>
                                <h3>Level 5</h3>
                              </div>
                              {levelFiveQuestions?.questions.map((q) => (
                                <ItilQuestionAssessment
                                  key={q.id}
                                  control={control}
                                  options={assessmentScopes}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={q.statement}
                                  name={q.code}
                                  shownStatus
                                  allQuestionsAnswered={
                                    levelFiveQuestions.control
                                      .allQuestionsAnswered
                                  }
                                  handleSelectedValue={() => {
                                    handleSetValues(getValues(), answers);
                                  }}
                                  readOnly={isReadOnly}
                                >
                                  <span className={styles.tag}>
                                    {q.dimension}
                                  </span>
                                </ItilQuestionAssessment>
                              ))}
                            </>
                          )}
                        </>
                      )}

                      {(practiceCode === "M-P" || practiceCode === "M-CI") && (
                        <div
                          // eslint-disable-next-line prettier/prettier
                          className={`${styles.capability} ${
                            !currentMaturityPractice.answer && styles.disabled
                            // eslint-disable-next-line prettier/prettier
                          }`}
                        >
                          <label>
                            {practiceCode === "M-P" && // eslint-disable-next-line prettier/prettier
                              !currentMaturityPractice.answer &&
                              "Please assess all capability practices in scope in order to view the score for this component"}
                            {practiceCode === "M-CI" && // eslint-disable-next-line prettier/prettier
                              !currentMaturityPractice.answer &&
                              "Please complete the capability assessment for the Continual Improvement practice in order to view the score for this component"}
                            {currentMaturityPractice.answer &&
                              currentMaturityPractice.statement}
                          </label>
                          {currentMaturityPractice.answer && (
                            <>
                              <p>
                                {currentMaturityPractice.answer.option.name}
                              </p>
                              <div>
                                {
                                  currentMaturityPractice.answer.option
                                    .statement
                                }
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      {questionType === "Maturity" &&
                        practiceCode !== "M-P" &&
                        practiceCode !== "M-CI" && (
                          <ItilQuestionAssessment
                            control={control}
                            options={maturityOptions}
                            format={ButtonsFormat.VERTICAL}
                            label={currentMaturityPractice.statement}
                            name={"maturity"}
                            shownStatus={!!maturity}
                            readOnly={isReadOnly}
                            handleSelectedValue={() =>
                              handleSetMaturityValues(getValues())
                            }
                            isAnswering={isFirstAnswerSubmitted}
                          />
                        )}
                    </Form.Fieldset>
                    <Form.Fieldset>
                      <Question.QuestionTextArea
                        control={control}
                        label="Assessment Notes"
                        name={`assessmentNote`}
                        placeholder="You can add any notes here"
                        handleOnChange={() =>
                          handleNotes(getValues("assessmentNote"))
                        }
                        readOnly={isReadOnly}
                      />
                    </Form.Fieldset>
                    <Form.Actions>
                      <>
                        <Button
                          label="Save and Exit"
                          style={ButtonStyle.SECONDARY}
                          type={ButtonType.BUTTON}
                          onClick={() => handleSaveAndExit()}
                        ></Button>
                        <Button
                          label={paginationButtonLabel}
                          style={ButtonStyle.PRIMARY}
                          type={ButtonType.BUTTON}
                          onClick={() => handlePagination()}
                        ></Button>
                      </>
                    </Form.Actions>
                  </div>
                );
              }}
            </Form.Wrapper>
          </Section>
          <Sidebar>
            {!isAssessmentCompleted && (
              <OrganizationDetails
                name={assessment.organization.name}
                country={assessment.organization.country.name}
                businessUnit={assessment.organization.businessUnit}
              />
            )}
            {isAssessmentCompleted && !isArchivedState && (
              <React.Fragment>
                <div style={{ textAlign: "right", marginBottom: "12px" }}>
                  <Button
                    label="Return to results"
                    style={ButtonStyle.PRIMARY}
                    type={ButtonType.BUTTON}
                    onClick={handleReturnToResults}
                  />
                </div>
                <Note>
                  <p>
                    This is a
                    <strong style={{ fontWeight: "bold" }}>
                      &nbsp;read-only&nbsp;
                    </strong>
                    version of the submitted assessment.
                  </p>
                </Note>
              </React.Fragment>
            )}
            {isArchivedState && (
              <Note>
                <p>
                  This assessment has been archived.
                  <strong
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={async () => {
                      await unarchiveAssessment(assessment.id);
                      setIsArchivedState(false);
                      history.push(`/`);
                    }}
                  >
                    &nbsp;Click here&nbsp;
                  </strong>
                  if you wish to restore it.
                </p>
              </Note>
            )}
            {progress && (
              <Progress.Scoring
                title={"Your Progress"}
                progress={progress}
                currentPractice={currentPractice}
                capabilities={capData}
                maturities={matData}
              />
            )}

            {assessment.endorsed && (
              <Note>
                {assessment.selectedScope === "Comprehensive" && (
                  <p>
                    Please make sure you have evidence at hand for
                    certification.
                  </p>
                )}
                {assessment.selectedScope !== "Comprehensive" && (
                  <p>
                    Please make sure you have evidence at hand to obtain your
                    statement of results.
                  </p>
                )}
              </Note>
            )}
          </Sidebar>
        </>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
