import React from "react";
import { Helmet } from "react-helmet-async";

type metaTitleProps = {
  title: string;
};

const MetaTitle: React.FunctionComponent<metaTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default MetaTitle;
