/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { api, API } from "modules/api";
import { AssessmentGetResponse } from "modules/api/endpoints/assessmentGet";
import {
  PerspectiveType,
  GetPerspectivesResponse
} from "modules/api/endpoints/perspectivesGet";
import { Organization } from "modules/api/endpoints/organizationGet";
import {
  ThreadType,
  GetThreadsResponse
} from "modules/api/endpoints/threadsGet";
import { ProgressBarPageType } from "components/Progress/Simple/index";
import { ErrorMessagesType, assessmentErrorMessages } from "modules/messages";

import { SelectAllOption } from "components/Inputs/Buttons/constants";
import { Levels, CertifiedThreadsCode, ProgressPages } from "./constants";

import { useModal } from "modules/hooks/modal";

import Template from "./Template";

import {
  AssessmentScopeStatesType,
  FormStateType,
  ItemsType,
  OptionType
} from "./types";
import UseLockAssessment from "routes/questions/hooks/useLockAssessment";
import { getErrorMessages } from "modules/messages/helpers";
import MetaTitle from "components/MetaTitle";

interface AssessmentParams {
  assessmentId: string;
}

const AssessmentScope: React.FunctionComponent = () => {
  const [fullAssessmentState, setFullAssessmentState] =
    useState<AssessmentScopeStatesType>({} as AssessmentScopeStatesType);

  const [formStates, setFormStates] = useState<FormStateType>(
    {} as FormStateType
  );
  const [organization, setOrganization] = useState<Organization>(
    {} as Organization
  );
  const [isCertified, setIsCertified] = useState(false);
  const [perspectivesData, setPerspectivesData] = useState<PerspectiveType[]>(
    []
  );
  const [perspectivesOptions, setPerspectivesOptions] = useState<OptionType[]>(
    []
  );
  const [threadsOptions, setThreadsOptions] = useState<OptionType[]>([]);
  const [threadsData, setThreadsData] = useState<ThreadType[]>([]);

  const [progressBarPages, setProgressBarPages] = useState<
    ProgressBarPageType[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);

  const {
    params: { assessmentId }
  } = useRouteMatch<AssessmentParams>();

  const errorConfirmation = useModal();

  const history = useHistory();

  useEffect(() => {
    return () => {
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    };
  }, []);

  useEffect(() => {
    if (!validateAssessmentId()) return history.push("/");

    const getAssessmentAndSetStates = async () => {
      try {
        const [assessment, perspectivesDataResponse, threadDataResponse] =
          await Promise.all([
            api(API.GET_ASSESSMENT(assessmentId)).then(
              (res: AssessmentGetResponse) => res.data
            ),
            api(API.GET_PERSPECTIVES()).then(
              (res: GetPerspectivesResponse) => res.data
            ),
            api(API.GET_THREADS()).then((res: GetThreadsResponse) => res.data)
          ]);

        setFullAssessmentState(assessment);
        setPerspectivesData(perspectivesDataResponse);
        setThreadsData(threadDataResponse);

        const {
          selectedLevel,
          selectedScope,
          threads,
          perspectives,
          certified
        } = assessment;

        const perspectivesArr = makePerspectivesArr(
          certified,
          perspectivesDataResponse,
          perspectives
        );

        if (
          isCertifiedAndHasSameLenght(
            certified,
            perspectivesArr.length,
            perspectivesDataResponse.length
          )
        ) {
          perspectivesArr.push(SelectAllOption.value);
        }

        const threadsArr = certified
          ? [
              ...threads.map((t) => {
                return t.code;
              }),
              ...CertifiedThreadsCode
            ]
          : threads.map((t) => {
              return t.code;
            });

        const projectScopeThreadsLenght = threadDataResponse.length - 1;
        const totalThreadsLenght = threadDataResponse.length;

        if (
          checkScopeTypeAndArrayLengths(
            selectedScope,
            threadsArr.length,
            projectScopeThreadsLenght,
            totalThreadsLenght
          )
        ) {
          threadsArr.push(SelectAllOption.value);
        }

        // Set Perspectives and Threads options;

        const perspectiveOptions = perspectivesDataResponse.map((p) => {
          return { label: p.name, value: p.code, isRequired: certified };
        });

        const threadsOptionsArr = threadDataResponse.map((t) => {
          const isRequired = certified && CertifiedThreadsCode.includes(t.code);

          return { label: t.name, value: t.code, isRequired };
        });

        if (selectedScope === "Project") {
          const itemInx = threadsOptionsArr.findIndex((t) => t.value === "MI");
          threadsOptionsArr.splice(itemInx, 1);
        }

        // Set states

        setProgressBarPages(() => {
          const patchPages = [...ProgressPages];
          const displayReviewNav = checkPerspectivesAndThreads(
            assessment.perspectives.length,
            assessment.threads.length
          );

          if (displayReviewNav) {
            return patchPages.map((p) => {
              return {
                ...p,
                isEnabled: true,
                isComplete: p.id !== 2
              };
            });
          }

          return [...patchPages];
        });

        setPerspectivesOptions(perspectiveOptions);
        setThreadsOptions(threadsOptionsArr);
        setFormStates({
          perspectives: perspectivesArr,
          threads: threadsArr,
          selectedLevel: checkAndConvertLevelToString(selectedLevel)
        });
        setOrganization(assessment.organization);
        setIsCertified(certified);
        setIsLoading(false);
      } catch (error: any) {
        const messages = getErrorMessages(
          assessmentErrorMessages,
          error?.response?.status
        );
        await errorConfirmation({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description
        }).finally(() => apiErrorRedirection(error?.response?.status));
      }
    };

    getAssessmentAndSetStates();
  }, [assessmentId]);

  function validateAssessmentId() {
    return !!assessmentId;
  }

  const apiErrorRedirection = (error = 400) => {
    if (error && error === 401) {
      window.location.assign("https://www.axelos.com/");
      return;
    }
    history.push("/");
  };

  const makePerspectivesArr = (
    certified: boolean,
    responseData: PerspectiveType[],
    currentPerspectives: PerspectiveType[]
  ): string[] => {
    return certified
      ? [
          ...responseData.map((t) => {
            return t.code;
          })
        ]
      : currentPerspectives.map((t) => {
          return t.code;
        });
  };

  const checkAndConvertLevelToString = (level: number) => {
    return level === 0 ? "" : level.toString();
  };

  const isCertifiedAndHasSameLenght = (
    isAssemtCertified: boolean,
    lengthA: number,
    lengthB: number
  ) => {
    return isAssemtCertified || compareArrayLenghts(lengthA, lengthB);
  };

  const checkScopeTypeAndArrayLengths = (
    scopeType: string,
    lengthA: number,
    lengthB: number,
    lenghtC: number
  ) => {
    if (scopeType === "Project") return compareArrayLenghts(lengthA, lengthB);

    return compareArrayLenghts(lengthA, lenghtC);
  };

  const compareArrayLenghts = (arrA: number, arrB: number) => {
    return arrA === arrB;
  };

  const checkPerspectivesAndThreads = (arrA: number, arrB: number) => {
    return arrA > 0 && arrB > 0;
  };

  const getSelectedItems = (
    values: string[],
    data: ItemsType[]
  ): ItemsType[] => {
    const items = [...data];
    return items.filter((d) => {
      if (values.includes(d.code)) {
        d.isSelected = true;
        return d;
      }
    });
  };

  const handleGoBack = (): void => {
    history.push(`/assessments/${assessmentId}/profile`);
  };

  const onSubmit = async (data: FormStateType) => {
    const { threads, perspectives, selectedLevel } = data;
    const filteredThreads = getSelectedItems(threads, threadsData);
    const filteredPerspectives = getSelectedItems(
      perspectives,
      perspectivesData
    );

    const formData: AssessmentScopeStatesType = {
      name: fullAssessmentState ? fullAssessmentState.name : "",
      certified: fullAssessmentState ? fullAssessmentState.certified : false,
      assessmentType: fullAssessmentState
        ? fullAssessmentState.assessmentType
        : "",
      selectedScope: fullAssessmentState
        ? fullAssessmentState.selectedScope
        : "",
      perspectives: filteredPerspectives,
      threads: filteredThreads,
      selectedLevel: Number(selectedLevel)
    };

    try {
      const response = await api(API.PUT_ASSESSMENT(formData, assessmentId));

      if (response) {
        history.push(`/assessments/${assessmentId}/review`);
      }
    } catch (error) {
      errorConfirmation({
        title: "Sorry, something happened, try again...",
        type: "error",
        buttonLabel: "Continue"
      });
    }
  };

  return (
    <React.Fragment>
      <MetaTitle title={`Scope Details | Axelos Maturity Assessment Tool`} />;
      <Template
        isLoading={isLoading}
        isCertified={isCertified}
        levels={Levels}
        perspectivesData={perspectivesOptions}
        threadsData={threadsOptions}
        handleSubmit={onSubmit}
        handleGoBack={handleGoBack}
        formStates={formStates}
        organization={organization}
        selectedScope={fullAssessmentState.selectedScope}
        progressBarPages={progressBarPages}
        assessmentId={fullAssessmentState.id}
      />
    </React.Fragment>
  );
};

export default AssessmentScope;
