import React from "react";
import { FieldError, FieldErrors } from "react-hook-form";
import clsx from "clsx";

import styles from "./styles.module.scss";

type ControlProps = {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  statement?: string;
  isInvalid?: boolean;
  error?: FieldError | FieldErrors;
  required?: boolean;
  label?: string;
  sublabel?: string;
  fieldId?: string;
  readOnly?: boolean;
  selectedLevel?: boolean;
};

const Control: React.FunctionComponent<ControlProps> = ({
  children,
  isInvalid,
  title,
  subtitle,
  error,
  required,
  label,
  sublabel,
  fieldId,
  readOnly,
  selectedLevel,
}) => {
  const className = clsx(
    styles["form-control"],
    isInvalid && styles["is-disabled"],
    readOnly && styles["read-only"],
    selectedLevel && styles["selected-level"]
  );
  return (
    <div className={className}>
      {title && <h4>{title}</h4>}
      {subtitle && <p>{subtitle}</p>}
      {label && (
        <label htmlFor={fieldId}>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      {sublabel && <p className={styles.sublabel}>{sublabel}</p>}
      {children}
      {!!error && <p aria-live="polite">{error.message}</p>}
    </div>
  );
};

export default Control;
