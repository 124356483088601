import React, { useRef } from "react";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import styles from "./styles.module.scss";
import { exportComponentAsJPEG } from "react-component-export-image";
import { ReactComponent as DownloadSVG } from "modules/theme/icons/buttons/download.svg";
type ChartsWrapperProps = {
  children?: React.ReactNode;
  title?: string;
  label?: string;
  benchMark?: boolean;
  gmTagId?: string;
  message?: string;
};

const ChartsItem: React.FunctionComponent<ChartsWrapperProps> = ({
  children,
  title,
  label,
  benchMark,
  gmTagId,
  message,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div ref={componentRef} className={styles.ChartItem}>
        {title && <h3>{title}</h3>}
        {children}
        {label && <p>{label}</p>}
        {message && <p className={styles["table-data-message"]}>{message}</p>}
      </div>
      <div className={styles["download-container"]}>
        <Button
          label="Download image"
          style={ButtonStyle.SECONDARY}
          type={ButtonType.BUTTON}
          benchMarking={benchMark}
          id={gmTagId}
          onClick={() =>
            exportComponentAsJPEG(componentRef, {
              fileName: "Table-Chart",
            })
          }
        >
          <DownloadSVG />
        </Button>
      </div>
    </>
  );
};

export default ChartsItem;
