import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import axios from "axios";

interface User {
  displayName: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  userGuid: string;
}

interface Token {
  token: string;
  expiry: number;
  redirectURI: string;
}

interface AuthState {
  token: Token;
  user: User;
  roles: string[];
}

interface AuthContextData {
  user: User;
  tokenExpireDate: number;
  roles: string[];
  updateUser(user: User): void;
  fetchAuth(token: string): Promise<boolean>;
  fetchRefreshToken: () => Promise<void>;
  logout: () => string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem("@P3M3:token");
    const refreshToken = sessionStorage.getItem("@P3M3:refreshToken");
    const user = sessionStorage.getItem("@P3M3:user");
    const roles = sessionStorage.getItem("@P3M3:roles");

    if (refreshToken && user && roles) {
      const parsedToken = JSON.parse(refreshToken);
      const parsedRoles = JSON.parse(roles);

      const tokenDate = new Date(parsedToken.expiry * 1000);
      const currentDate = new Date();

      if (tokenDate >= currentDate) {
        axios.defaults.headers.authorization = `Bearer ${parsedToken.token}`;
        return {
          token: parsedToken,
          user: JSON.parse(user),
          roles: parsedRoles,
        };
      }
    }

    if (token && user && roles) {
      const parsedToken = JSON.parse(token);
      const parsedRoles = JSON.parse(roles);

      const tokenDate = new Date(parsedToken.expiry * 1000);
      const currentDate = new Date();

      if (tokenDate >= currentDate) {
        axios.defaults.headers.authorization = `Bearer ${parsedToken.token}`;
        return {
          token: parsedToken,
          user: JSON.parse(user),
          roles: parsedRoles,
        };
      }
    }

    return {} as AuthState;
  });

  useEffect(() => {
    const tokenExpireDate = data?.token?.expiry;
    const currentDate = Date.now();
    const offsetTimeout = 1000 * 60 * 5;

    const intervalTime = tokenExpireDate
      ? tokenExpireDate * 1000 - currentDate
      : 1000 * 60 * 25;

    if (intervalTime <= offsetTimeout) {
      fetchRefreshToken();
      return;
    }

    const timer = setTimeout(
      () => {
        fetchRefreshToken();
      },
      tokenExpireDate ? intervalTime - offsetTimeout : intervalTime
    );

    return () => {
      clearTimeout(timer);
    };
  }, [data]);

  const fetchAuth = async (tokenParam: string) => {
    const AUTHHOST = process.env.REACT_APP_API_ROOT;

    try {
      const response = await axios
        .post(
          `${AUTHHOST}/auth`,
          { token: tokenParam },
          {
            withCredentials: true,
          }
        )
        .then((res) => res.data);

      if (response) {
        const { expiry, token, user, redirectURI, roles } = response;

        const responseToken: Token = {
          expiry,
          token,
          redirectURI,
        };

        if (user && token && roles) {
          sessionStorage.setItem("@P3M3:token", JSON.stringify(responseToken));
          sessionStorage.setItem("@P3M3:user", JSON.stringify(user));
          sessionStorage.setItem("@P3M3:roles", JSON.stringify(roles));

          axios.defaults.headers.authorization = `Bearer ${token}`;

          setData({ token: responseToken, user, roles });
        }

        return true;
      }
    } catch (error) {
      return false;
    }

    return false;
  };

  const fetchRefreshToken = async () => {
    const AUTHHOST = process.env.REACT_APP_API_ROOT;

    try {
      await axios
        .post(
          `${AUTHHOST}/auth/refresh`,
          {},
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          const { expiry, token, user, redirectURI } = response.data;

          if (user && token) {
            const responseToken: Token = {
              expiry,
              token,
              redirectURI,
            };

            sessionStorage.setItem(
              "@P3M3:refreshToken",
              JSON.stringify(responseToken)
            );
            axios.defaults.headers.authorization = `Bearer ${token}`;

            // Temp - Needs to add roles on refresh endpoint
            const roles = sessionStorage.getItem("@P3M3:roles");
            const parsedRoles = roles ? JSON.parse(roles) : [];

            setData({ user, token: responseToken, roles: parsedRoles });
          }
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Error trying to get RefreshToken");
    }
  };

  const logout = useCallback(() => {
    sessionStorage.removeItem("@P3M3:token");
    sessionStorage.removeItem("@P3M3:refreshToken");
    sessionStorage.removeItem("@P3M3:user");
    sessionStorage.removeItem("@P3M3:roles");

    if (!data.token.redirectURI) {
      return "https://www.axelos.com/";
    }

    return data.token.redirectURI.includes("http")
      ? data.token.redirectURI
      : `https://${data.token.redirectURI}`;
  }, [data.token]);

  const updateUser = useCallback(
    (user: User) => {
      sessionStorage.setItem("@P3M3:user", JSON.stringify(user));

      setData({
        token: data.token,
        user,
        roles: data.roles,
      });
    },
    [data.token]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        tokenExpireDate: data?.token?.expiry ? data.token.expiry : 0,
        roles: data.roles,
        updateUser,
        fetchAuth,
        fetchRefreshToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
