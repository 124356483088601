import yup from "modules/validation";

export const AssessmentScopeSchema = yup.object().shape({
  selectedLevel: yup.string().required(),
  perspectives: yup.array().min(1),
  threads: yup.array().min(1),

  // perspectives: yup.object().shape({
  //   OG: yup.bool().oneOf([true], "Field must be checked"),
  // }),
});

export const ItilAssessmentScopeSchema = yup.object().shape({
  cap_coi: yup.string().notRequired(),
  cap_arm: yup.string().notRequired(),
  cap_ism: yup.string().notRequired(),
  cap_klm: yup.string().notRequired(),
  cap_mar: yup.string().notRequired(),
  cap_ocm: yup.string().notRequired(),
  cap_ptm: yup.string().notRequired(),
  cap_prm: yup.string().notRequired(),
  cap_rlm: yup.string().notRequired(),
  cap_rim: yup.string().notRequired(),
  cap_sfm: yup.string().notRequired(),
  cap_stm: yup.string().notRequired(),
  cap_sum: yup.string().notRequired(),
  cap_wtm: yup.string().notRequired(),
  svc_avm: yup.string().notRequired(),
  svc_bua: yup.string().notRequired(),
  svc_cpm: yup.string().notRequired(),
  svc_cge: yup.string().notRequired(),
  svc_icm: yup.string().notRequired(),
  svc_iam: yup.string().notRequired(),
  svc_mem: yup.string().notRequired(),
  svc_prm: yup.string().notRequired(),
  svc_rlm: yup.string().notRequired(),
  svc_sctm: yup.string().notRequired(),
  svc_scfm: yup.string().notRequired(),
  svc_scom: yup.string().notRequired(),
  svc_sdg: yup.string().notRequired(),
  svc_sde: yup.string().notRequired(),
  svc_slm: yup.string().notRequired(),
  svc_srm: yup.string().notRequired(),
  svc_svt: yup.string().notRequired(),
  tec_dm: yup.string().notRequired(),
  tec_ipm: yup.string().notRequired(),
  tec_sdm: yup.string().notRequired(),
});
