import React from "react";

import styles from "../styles.module.scss";

type PanelBodyProps = {
  children?: React.ReactNode;
  isOpen: boolean;
};

const PanelBody: React.FunctionComponent<PanelBodyProps> = ({
  children,
  isOpen,
}) => {
  return <div className={styles["panel__body"]}>{isOpen && children}</div>;
};

export default React.memo(PanelBody);
