import React from "react";
import { AssessmentState } from "../../types";

import styles from "../../styles.module.scss";
import { Assessment, AssessmentFormat } from "../../types";
import Button, { ButtonSize, ButtonStyle } from "components/Button";
import { ReactComponent as ArchiveIcon } from "modules/theme/icons/icon-archive.svg";

type ArchiveButtonProps = {
  assessment: Assessment;
  type: AssessmentFormat;
  onChangeArchive: (theAssessment: Assessment, type: AssessmentFormat) => void;
};

const ArchiveButton: React.FunctionComponent<ArchiveButtonProps> = ({
  assessment,
  type,
  onChangeArchive,
}) => {
  if (assessment.isArchived) {
    return (
      <div className={styles.assessment__actionsSettings}>
        <Button
          onClick={() => onChangeArchive(assessment, type)}
          label={"Restore"}
          style={ButtonStyle.SECONDARY}
          size={ButtonSize.SMALL}
        />
      </div>
    );
  }
  return (
    <div className={styles.assessment__actionsSettings}>
      {assessment.status === AssessmentState.COMPLETED && (
        <div className={styles.tooltip}>
          <ArchiveIcon onClick={() => onChangeArchive(assessment, type)} />
          <span>Archive assessment</span>
        </div>
      )}
    </div>
  );
};

export default ArchiveButton;
