import { API, api } from "modules/api";

type ArchiveAPIResult = {
  success: boolean;
  assessmentId: number;
};

export const archiveAssessment = async (
  assessmentId: number
): Promise<ArchiveAPIResult> => {
  try {
    await api(API.POST_ARCHIVE_ASSESSMENT(assessmentId));
    return {
      success: true,
      assessmentId: assessmentId,
    };
  } catch (error) {
    return {
      success: false,
      assessmentId: assessmentId,
    };
  }
};

export const unarchiveAssessment = async (
  assessmentId: number
): Promise<ArchiveAPIResult> => {
  try {
    await api(API.POST_UNARCHIVE_ASSESSMENT(assessmentId));
    return {
      success: true,
      assessmentId: assessmentId,
    };
  } catch (error) {
    return {
      success: false,
      assessmentId: assessmentId,
    };
  }
};
