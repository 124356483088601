import * as yup from "yup";
import Messages from "./messages";

yup.setLocale({
  mixed: {
    notType: Messages.NOT_TYPE,
    oneOf: Messages.ONE_OF,
    required: Messages.REQUIRED,
  },
  string: {
    email: Messages.EMAIL,
  },
  array: {
    min: Messages.MIN,
  },
});

export { default as Messages } from "./messages";

export default yup;
