import { produce } from "immer";

import { Workflow, WorkflowActionType } from "./types";
import { WorkflowAction } from "./constants";

const initialState = {};

function workflowReducer(
  state = initialState,
  action: WorkflowActionType
): Workflow {
  switch (action.type) {
    case WorkflowAction.REQUEST_SUCCESS:
      return produce(state, () => {
        return action.workflow;
      });
    default:
      return state;
  }
}

export default workflowReducer;
