import { Organization } from "modules/api/endpoints/organizationGet";

export type QuestionType = {
  id: number;
  code: string;
  scope: string;
  perspective: string;
  thread: string;
  level: number | string;
  type: string;
  statement: string;
  currentSelection: string | null;
  answer: AnswerType | null;
  diagnostics: DiagnosticType[];
};

export type DiagnosticType = {
  id: number;
  code: string;
  statement: string;
  isPositive: string | null;
};

export type AnswerType = {
  id: number;
  questionId: number;
  score: number;
  selection: string | null;
};

export type ActiveQuestion = {
  id: number;
  perspective: string;
  thread: string;
};

export type progressItems = {
  id: number;
  isComplete: boolean | null;
  isActive: boolean;
  name: string;
  code: string;
};

export type PerspectiveProgressType = {
  id: number;
  isActive?: boolean;
  perspective: string;
  code: string;
  threads: progressItems[];
  score?: number;
};

export type StateType = {
  [key: string]: string | null;
};

export type PerspectivesProgressType = {
  answeredThreads: number;
  currentScore: number;
  id: number;
  name: string;
  totalThreads: number;
  threads: {
    answered: boolean;
    descoped: boolean;
    id: number;
    name: string;
    started: boolean;
  }[];
};

export type ProgressType = {
  maturityScore: number;
  currentOverallScore: number;
  id: number;
  name: string;
  perspectivesProgress: PerspectivesProgressType[];
};

export type BaseItem = {
  id: number;
  code: string;
  name: string;
};

export type QuestionsParam = {
  assessmentId: string;
  perspectiveCode: string;
  threadCode: string;
};

export type Items = {
  id: number;
  code: string;
  name: string;
  isSelected: boolean;
};

export type AssessmentResponse = {
  id: number;
  isArchived: boolean;
  perspectives: Items[];
  threads: Items[];
  selectedLevel: number;
  organization: Organization;
  name: string;
  status: string;
  certified: boolean;
};

export type AnswersType = {
  level: number;
  answer: string;
  enabled: boolean;
  label: string;
  readonly: boolean;
  id: number;
  questionId: number;
};

export enum FormType {
  CREATE = "Create",
  EDIT = "Edit",
  UPDATE = "Update",
}

export enum AssessmentStatus {
  COMPLETED = "Completed",
  ACTIVE = "Active",
}
