export const ProgressPages = [
  {
    id: 1,
    isEnabled: true,
    isActive: false,
    isComplete: true,
    name: "Assessment details",
    route: "profile",
  },
  {
    id: 2,
    isEnabled: true,
    isActive: false,
    isComplete: false,
    name: "Scope details",
    route: "scope",
  },
  {
    id: 3,
    isEnabled: true,
    isActive: true,
    isComplete: false,
    name: "Review profile",
    route: "review",
  },
];

export const ProgressPagesItil = [
  {
    id: 1,
    isEnabled: true,
    isActive: false,
    isComplete: true,
    name: "Assessment details",
    route: "profile/itil",
  },
  {
    id: 2,
    isEnabled: true,
    isActive: false,
    isComplete: false,
    name: "Scope details",
    route: "scope/itil",
  },
  {
    id: 3,
    isEnabled: true,
    isActive: true,
    isComplete: false,
    name: "Review profile",
    route: "review/itil",
  },
];
