import React from "react";
import { useController, Control } from "react-hook-form";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import QuestionTemplate from "../QuestionTemplate";
import Debug from "../QuestionTemplate/Debug";
import { Expand } from "modules/typescript";
import Buttons from "components/Inputs/Buttons";
import { ButtonsType } from "components/Inputs/Buttons/constants";
import { OptionType } from "components/Inputs/Buttons/types";

type QuestionSwitchProps = Expand<
  {
    control: Control;
    options?: OptionType[];
    simple?: boolean;
    handleSelectedValue?: (value: boolean) => void;
  } & Omit<QuestionTemplateProps, "answered" | "children" | "invalid">
>;

const QuestionSwitch: React.FunctionComponent<QuestionSwitchProps> = ({
  control,
  name,
  required,
  simple,
  handleSelectedValue,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });

  return (
    <QuestionTemplate
      answered={field.value && !invalid}
      invalid={invalid}
      name={name}
      required={required}
      diagnostic={simple}
      {...props}
    >
      <Buttons
        invalid={invalid}
        simple={simple}
        handleSelectedValue={handleSelectedValue}
        type={ButtonsType.SWITCH}
        {...props}
        {...field}
      />
      {process.env.NODE_ENV === "development" && <Debug value={field.value} />}
    </QuestionTemplate>
  );
};

export default React.memo(QuestionSwitch);
