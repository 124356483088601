import React, { useRef } from "react";
import clsx from "clsx";

import { TextAreaFormat } from "./constants";
import { TextAreaProps } from "./types";

import styles from "./styles.module.scss";

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoFocus,
      disabled,
      format = TextAreaFormat.TEXT,
      id,
      invalid,
      maxLength,
      name,
      placeholder,
      readOnly,
      rows,
      tabIndex,
      handleSetState,
      onChange,
      ...props
    },
    ref
  ) => {
    const inputProps = {
      format: format,
      maxLength: maxLength,
      placeholder: placeholder,
    };

    const className = clsx(
      styles["textarea"],
      format && styles[`textarea-${format}`],
      disabled && styles["disabled"],
      !disabled && invalid && styles["invalid"]
    );

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event);
      handleSetState(event.target.value);
    };
    return (
      <div className={className}>
        <textarea
          autoFocus={!disabled && autoFocus}
          disabled={disabled}
          id={id}
          maxLength={inputProps.maxLength}
          name={name}
          placeholder={inputProps.placeholder}
          readOnly={readOnly}
          ref={ref}
          rows={rows}
          tabIndex={disabled ? -1 : tabIndex ? tabIndex : 0}
          {...props}
          onChange={handleOnChange}
        />
      </div>
    );
  }
);

export default React.memo(TextArea);
