import React from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type SectionProps = {
  small?: boolean;
  large?: boolean;
};

const Section: React.FC<SectionProps> = ({ small, large, children }) => {
  const className = clsx(
    styles["section"],
    small && styles["small"],
    large && styles["large"]
  );
  return <section className={className}>{children}</section>;
};

export default Section;
