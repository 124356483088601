import React from "react";
import clsx from "clsx";

import { InputProps, InputPrimitiveProps } from "./types";
import { InputMode, InputType } from "./constants";
import { useController } from "react-hook-form";

import styles from "./styles.module.scss";

export { InputMode, InputType } from "./constants";

export const InputPrimitive = React.memo(
  React.forwardRef<HTMLInputElement, InputPrimitiveProps>(
    (
      {
        autoFocus,
        disabled,
        id,
        inputMode = InputMode.TEXT,
        invalid,
        name,
        readOnly,
        tabIndex,
        type = InputType.TEXT,
        value,
        ...props
      },
      ref
    ) => {
      const className = clsx(
        styles["input"],
        disabled && styles["disabled"],
        readOnly && styles["read-only"],
        !disabled && invalid && styles["invalid"]
      );
      const handleTabIndex = () => {
        if (disabled) return -1;
        if (tabIndex) return tabIndex;
        return 0;
      };
      return (
        <div className={className}>
          <input
            autoComplete="off"
            autoFocus={!disabled && autoFocus}
            disabled={disabled}
            id={id || name}
            inputMode={inputMode}
            name={name}
            tabIndex={handleTabIndex()}
            type={type}
            readOnly={readOnly}
            ref={ref}
            value={value || ""}
            {...props}
          />
        </div>
      );
    }
  )
);

const Input: React.FunctionComponent<InputProps> = ({
  control,
  id,
  name,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });
  return <InputPrimitive id={id} invalid={invalid} {...props} {...field} />;
};

export default React.memo(Input);
