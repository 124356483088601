import React from "react";

import * as Form from "components/Form";
import Button, { ButtonSize, ButtonStyle, ButtonType } from "components/Button";
import { ReactComponent as DownloadSVG } from "modules/theme/icons/buttons/download.svg";
import { ReactComponent as ExitSVG } from "modules/theme/icons/buttons/exit.svg";

type ButtonsProps = {
  disabled?: boolean;
  readOnly?: boolean;
};

const Buttons: React.FunctionComponent<ButtonsProps> = ({
  disabled,
  readOnly,
}) => {
  const handleTitle = () => {
    if (disabled) return " - Disabled";
    if (readOnly) return " - Read Only";

    return "";
  };
  return (
    <React.Fragment>
      <Form.Fieldset title={`Buttons${handleTitle()}`}>
        <div>
          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            style={ButtonStyle.PRIMARY}
          />

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}
          />

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            style={ButtonStyle.NEGATIVE}
          />

          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            style={ButtonStyle.PRIMARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            style={ButtonStyle.NEGATIVE}
          >
            <ExitSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            style={ButtonStyle.PRIMARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            style={ButtonStyle.NEGATIVE}
          >
            <ExitSVG />
          </Button>
        </div>
        <div>
          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.PRIMARY}
          />

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.SECONDARY}
          />

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.NEGATIVE}
          />

          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.PRIMARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.SECONDARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.NEGATIVE}
          >
            <ExitSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.PRIMARY}
          >
            <DownloadSVG />
          </Button>
          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.SECONDARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.NEGATIVE}
          >
            <ExitSVG />
          </Button>
        </div>
        <div>
          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.PRIMARY}
          />

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.SECONDARY}
          />

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.NEGATIVE}
          />

          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.PRIMARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.SECONDARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.NEGATIVE}
          >
            <ExitSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.PRIMARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.SECONDARY}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.NEGATIVE}
          >
            <ExitSVG />
          </Button>
        </div>
      </Form.Fieldset>
      <Form.Fieldset title={`Buttons${handleTitle()}`}>
        <div>
          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>
        </div>
        <div>
          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.SMALL}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>
        </div>
        <div>
          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Negative"
            size={ButtonSize.INLINE}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          />

          <Button
            disabled={disabled}
            label="Primary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Secondary"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            label="Negative"
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.PRIMARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.SECONDARY}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>

          <Button
            disabled={disabled}
            readOnly={readOnly}
            size={ButtonSize.INLINE}
            style={ButtonStyle.NEGATIVE}
            type={ButtonType.LINK}
          >
            <DownloadSVG />
          </Button>
        </div>
      </Form.Fieldset>
    </React.Fragment>
  );
};

export default Buttons;
