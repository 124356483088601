enum Messages {
  EMAIL = "Please enter a valid email address.",

  NOT_TYPE = "Please enter a valid ${type}.",

  ONE_OF = "Please select one of the options.",

  OPTION = "Please select an option.",

  REQUIRED = "This Field is required.",

  MIN = "Please select one of the options.",
}

export default Messages;
