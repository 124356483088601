import React from "react";
import { ReactComponent as ButtonSpinner } from "modules/theme/icons/buttons/spinner.svg";
import { ReactComponent as LockerIcon } from "modules/theme/icons/buttons/locker.svg";
import Button, { ButtonStyle, ButtonSize } from "components/Button";

import styles from "../../styles.module.scss";

type LockedActionsProps = {
  assessmentId: number;
  isLocking: boolean;
  setIsLocking: (isLocking: boolean) => void;
  isUnlocked: (id: number) => Promise<boolean>;
};
const LockedActions: React.FunctionComponent<LockedActionsProps> = ({
  assessmentId,
  isLocking,
  setIsLocking,
  isUnlocked,
}) => {
  return (
    <div className={styles.assessment__actionsLocked}>
      {isLocking ? (
        <div className={styles.loading}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      ) : (
        <LockerIcon />
      )}
      <span>
        Locked for editing by another user
        <Button
          onClick={async () => {
            setIsLocking(true);
            const response = await isUnlocked(assessmentId);
            if (response) {
              setIsLocking(false);
              return;
            }
            setIsLocking(false);
          }}
          label="Retry"
          style={ButtonStyle.PRIMARY}
          size={ButtonSize.INLINE}
        />
      </span>
    </div>
  );
};

export default LockedActions;
