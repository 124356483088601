import React from "react";
import clsx from "clsx";

import Button from "components/Button";
import { ReactComponent as TickSVG } from "modules/theme/icons/buttons/tick.svg";

import styles from "./styles.module.scss";

type SimpleItemProps = {
  item: {
    id: number;
    isEnabled: boolean;
    isActive: boolean;
    isComplete: boolean;
    name: string;
    route: string;
  };
  onClick: (route: string) => void;
  hasAssessmentId: boolean;
};

const SimpleItem: React.FunctionComponent<SimpleItemProps> = ({
  onClick,
  item,
}) => {
  const className = clsx(
    styles["progress-section-item"],
    item.isActive && styles["selected"],
    item.isComplete && styles["complete"],
    !item.isComplete && item.isActive && styles["incomplete"]
  );
  return (
    <li className={className}>
      {item.isComplete && <TickSVG />}
      {item.isEnabled ? (
        <Button onClick={() => onClick(item.route)} label={item.name}></Button>
      ) : (
        <span>{item.name}</span>
      )}
    </li>
  );
};

export default SimpleItem;
