import React from "react";

import Button, { ButtonStyle, ButtonType } from "components/Button";

import styles from "../styles.module.scss";

import { ModalType } from "../index";

type ModalActionsType = {
  type: "error" | "info" | "confirm" | "success";
  buttonLabel?: string;
  displayFeedback: boolean;
  buttonStyle?: ButtonStyle;
  isLoading: boolean;
  displayCancelButton?: boolean;
  cancelButtonLabel?: string;
  cancelButtonStyle?: ButtonStyle;
  handleClose: () => void;
  handleSubmit: () => void;
  handleCertify: () => void;
};

export function ModalActions({
  type,
  buttonLabel,
  displayFeedback,
  buttonStyle,
  isLoading,
  displayCancelButton,
  cancelButtonLabel,
  cancelButtonStyle,
  handleClose,
  handleSubmit,
  handleCertify,
}: ModalActionsType): JSX.Element {
  return (
    <div className={styles.ModalBoxBottom}>
      {type === ModalType.CONFIRM && !displayFeedback && (
        <>
          <Button
            label={buttonLabel ? buttonLabel : "Submit"}
            isLoading={isLoading}
            style={buttonStyle ? buttonStyle : ButtonStyle.PRIMARY}
            type={ButtonType.BUTTON}
            onClick={handleCertify}
          />
          <Button
            label="Cancel"
            style={buttonStyle ? buttonStyle : ButtonStyle.NEGATIVE}
            type={ButtonType.BUTTON}
            onClick={handleClose}
          />
        </>
      )}
      {type !== ModalType.CONFIRM && (
        <Button
          label={buttonLabel ? buttonLabel : "Go back"}
          style={buttonStyle ? buttonStyle : ButtonStyle.PRIMARY}
          type={ButtonType.BUTTON}
          onClick={handleSubmit}
        />
      )}
      {displayCancelButton && (
        <Button
          label={cancelButtonLabel ? cancelButtonLabel : "Cancel"}
          style={cancelButtonStyle ? cancelButtonStyle : ButtonStyle.NEGATIVE}
          type={ButtonType.BUTTON}
          onClick={handleClose}
        />
      )}
    </div>
  );
}
