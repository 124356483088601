import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import * as Form from "components/Form";
import * as Progress from "components/Progress";
import * as Questions from "components/Questions";

import yup from "modules/validation";
import { useModal } from "modules/hooks/modal";
import { Organization } from "modules/api/endpoints/organizationGet";
import { State } from "./constants";
import { QuestionType, ActiveQuestion, ProgressType, BaseItem } from "./types";
import * as Question from "components/Questions";

import Headings from "components/Headings";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { ButtonsFormat } from "components/Inputs/Buttons/constants";

import Section from "components/Section";
import Sidebar from "components/Sidebar";
import Loading from "components/Loading";
import Diagnostic from "./Diagnostics";
import OrganizationDetails from "components/Headings/OrganizationDetails";
import Note from "components/Notes";
import DescopeThread from "./Descope";
import QuestionPagination from "./Pagination";
import { unarchiveAssessment } from "modules/utils/ArchiveAssessments";

type AnswersType = {
  level: number;
  answer: string;
  enabled: boolean;
  label: string;
  readonly: boolean;
  id: number;
  questionId: number;
};

export type Item = {
  id: number;
  code: string;
  name: string;
};

type TemplateProps = {
  isLoading: boolean;
  isArchived: boolean;
  questions: QuestionType[];
  currentLevel: number;
  answers: AnswersType[];
  formState: typeof State;
  activeIndex: ActiveQuestion;
  organization: Organization;
  assessmentId: number;
  assessmentName: string;
  progress: ProgressType;
  isAssessmentLocked: boolean;
  currentThread: Item;
  currentPerspective: Item;
  isThreadDescoped: boolean;
  isDescoping: boolean;
  showSummaryButton: boolean;
  isFirstAnswerSubmitted: boolean;
  perspectivesData: BaseItem[];
  threadsData: BaseItem[];
  handleSelectedAnswer: (data: AnswersType) => void;
  handlePostAnswer: (data: typeof State) => Promise<void>;
  handleSidebar: (data: ActiveQuestion) => void;
  handleReturnToResults: () => void;
  handleDescopeAndRescopeThread: (descope: boolean) => void;
  handleProgressBarNavigation: (perspective: string, thread: string) => void;
  assessmentCode: () => boolean;
  handleNotes: (note: string | null) => void;
};

const assessmentScopes = [
  { label: "Fully", value: "1" },
  { label: "Partially ", value: "2" },
  { label: "Rarely/Never", value: "3" },
];

const schema = yup.object().shape({
  level1: yup.string().notRequired(),
  level2: yup.string().notRequired(),
  level3: yup.string().notRequired(),
  level4: yup.string().notRequired(),
  level5: yup.string().notRequired(),
  assessmentNote: yup.string().notRequired(),
});

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  isArchived,
  questions,
  currentLevel,
  answers,
  formState,
  activeIndex,
  organization,
  assessmentId,
  assessmentName,
  isAssessmentLocked,
  isFirstAnswerSubmitted,
  progress,
  currentThread,
  currentPerspective,
  isThreadDescoped,
  isDescoping,
  showSummaryButton,
  perspectivesData,
  threadsData,
  assessmentCode,
  handleSelectedAnswer,
  handlePostAnswer,
  handleProgressBarNavigation,
  handleDescopeAndRescopeThread,
  handleReturnToResults,
  handleSidebar,
  handleNotes,
}) => {
  const descopeConfirmation = useModal();
  const [isArchivedState, setIsArchivedState] = useState<boolean>(isArchived);
  const history = useHistory();
  // IsArchivedState was initalising to false, presumably from a first-render
  // undefined assessment state. If the prop is true we need to ensure the state
  // is correct.
  useEffect(() => {
    if (isArchived && isArchived !== isArchivedState) {
      setIsArchivedState(isArchived);
    }
  }, [isArchived, setIsArchivedState]);

  const handleThreadDescopeDialog = async (evt: boolean) => {
    if (!evt) return;
    return descopeConfirmation({
      type: "info",
      catchOnCancel: true,
      title: "Are you sure?",
      description:
        "Descoping this thread means you will lose any answers related to it.",
      buttonLabel: "Descope Thread",
      displayCancelButton: true,
    })
      .then(() => true)
      .catch(() => false);
  };

  const isReadOnly = isAssessmentLocked || isArchivedState;
  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <Section>
            <Headings
              title={assessmentName}
              subtitle={questions[0].perspective}
            >
              {isReadOnly && (
                <OrganizationDetails
                  name={organization.name}
                  country={organization.country.name}
                  businessUnit={organization.businessUnit}
                />
              )}
            </Headings>
            <Form.Wrapper
              onSubmit={() => null}
              state={formState}
              schema={schema}
              mode="all"
              shouldUnregister={false}
            >
              {({ setValue, getValues, control, watch }) => {
                watch(["level1", "level2", "level3", "level4", "level5"]);

                const handleSetValues = async (
                  level: number,
                  value: string
                ) => {
                  const lowerLevels = answers.filter((a) => a.level < level);
                  const higherLevels = answers.filter((a) => a.level > level);

                  if (value === "1") {
                    if (lowerLevels.length) {
                      lowerLevels.forEach((l) => {
                        setValue(l.label, "1");
                      });
                    }
                    if (higherLevels.length && level >= currentLevel) {
                      setValue(`${higherLevels[0].label}`, null);
                    }
                  }

                  if (value === "2" || value === "3") {
                    if (higherLevels.length) {
                      if (currentLevel <= level) {
                        higherLevels.forEach((h) => {
                          setValue(h.label, "");
                        });
                      }

                      if (level <= currentLevel) {
                        lowerLevels.forEach((l) => {
                          setValue(l.label, null);
                        });
                      }

                      if (currentLevel > level) {
                        const { label } = higherLevels[0];
                        setValue(label, "3");
                      }

                      higherLevels.forEach((h) => {
                        if (h.enabled === true) {
                          setValue(h.label, "3");
                        }
                      });
                    }
                    if (!higherLevels.length) {
                      if (level <= currentLevel) {
                        lowerLevels.forEach((l) => {
                          setValue(l.label, "");
                        });
                        setValue(
                          `${lowerLevels[lowerLevels.length - 1].label}`,
                          null
                        );
                      }
                    }
                  }

                  await handlePostAnswer(getValues());
                };

                return (
                  <>
                    <Form.Fieldset
                      title={isReadOnly ? questions[0].thread : ""}
                    >
                      {!isReadOnly && (
                        <DescopeThread
                          handleOnChange={async (evt) => {
                            const confirmation =
                              await handleThreadDescopeDialog(evt);
                            if (confirmation || !evt) {
                              handleDescopeAndRescopeThread(evt);
                              answers.forEach((answer) =>
                                setValue(answer.label, "")
                              );
                            }
                          }}
                          isThreadDescoped={isThreadDescoped}
                          title={questions[0].thread}
                          valid={assessmentCode()}
                        />
                      )}

                      {questions &&
                        formState &&
                        questions.map((q) => {
                          const isEnabled = (): boolean => {
                            if (isThreadDescoped) return isThreadDescoped;

                            if (isAssessmentLocked) return isAssessmentLocked;

                            if (isArchived) return isArchived;

                            if (isFirstAnswerSubmitted)
                              return isFirstAnswerSubmitted;

                            if (q.level === currentLevel) return false;

                            return !!answers.filter(
                              (a) => a.level === q.level
                            )[0].readonly;
                          };

                          const handleShowStatus = () => {
                            if (q.level === currentLevel && !isThreadDescoped)
                              return true;

                            return !!answers.filter(
                              (a) => a.level === q.level
                            )[0].enabled;
                          };

                          return (
                            <Questions.QuestionAssessment
                              isAnswering={
                                q.level === currentLevel &&
                                isFirstAnswerSubmitted
                              }
                              key={q.id}
                              control={control}
                              format={ButtonsFormat.HORIZONTAL}
                              label={`Level ${q.level}`}
                              info={q.statement}
                              name={`level${q.level}`}
                              options={assessmentScopes}
                              shownStatus={handleShowStatus()}
                              readOnly={isEnabled()}
                              handleSelectedValue={(value) => {
                                const { enabled, readonly, id } =
                                  answers.filter(
                                    (a) => a.questionId === q.id
                                  )[0];

                                handleSelectedAnswer({
                                  level: Number(q.level),
                                  answer: value.toString(),
                                  label: `level${q.level}`,
                                  enabled,
                                  readonly,
                                  questionId: q.id,
                                  id,
                                });

                                handleSetValues(
                                  Number(q.level),
                                  value.toString()
                                );
                              }}
                            >
                              <Diagnostic
                                control={control}
                                diagnostics={q.diagnostics}
                                readOnly={isEnabled()}
                              />
                            </Questions.QuestionAssessment>
                          );
                        })}
                    </Form.Fieldset>
                    <Form.Fieldset>
                      <Question.QuestionTextArea
                        control={control}
                        label="Assessment Notes"
                        name={`assessmentNote`}
                        placeholder="You can add any notes here"
                        handleOnChange={() =>
                          handleNotes(getValues("assessmentNote"))
                        }
                        readOnly={isReadOnly}
                      />
                    </Form.Fieldset>
                    <Form.Actions>
                      <QuestionPagination
                        name="test"
                        perspectives={perspectivesData}
                        threads={threadsData}
                        progress={progress}
                        isAssessmentLocked={isReadOnly}
                        showSummaryButton={showSummaryButton}
                      />
                    </Form.Actions>
                    {isDescoping && <Loading noBackground></Loading>}
                  </>
                );
              }}
            </Form.Wrapper>
          </Section>
          <Sidebar>
            {!isReadOnly && (
              <OrganizationDetails
                name={organization.name}
                country={organization.country.name}
                businessUnit={organization.businessUnit}
              />
            )}
            {!isArchivedState && isAssessmentLocked && (
              <React.Fragment>
                <div style={{ textAlign: "right", marginBottom: "12px" }}>
                  <Button
                    label="Return to results"
                    style={ButtonStyle.SECONDARY}
                    type={ButtonType.BUTTON}
                    onClick={handleReturnToResults}
                  />
                </div>
                <Note>
                  <p>
                    This is a
                    <strong style={{ fontWeight: "bold" }}>
                      &nbsp;read-only&nbsp;
                    </strong>
                    version of the submitted assessment.
                  </p>
                </Note>
              </React.Fragment>
            )}
            {isArchivedState && (
              <Note>
                <p>
                  This assessment has been archived.
                  <strong
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={async () => {
                      await unarchiveAssessment(assessmentId);
                      setIsArchivedState(false);
                      history.push(`/`);
                    }}
                  >
                    &nbsp;Click here&nbsp;
                  </strong>
                  if you wish to restore it.
                </p>
              </Note>
            )}

            <Progress.Overall
              maturityScore={progress.maturityScore}
              decimalScore={progress.currentOverallScore}
            />

            <Progress.Wrapper title="Your Progress">
              {progress.perspectivesProgress.map((p) => {
                return (
                  <Progress.Perspective
                    key={p.id}
                    open={p.id === activeIndex.id}
                    onClick={() =>
                      handleSidebar({
                        id: p.id,
                        perspective: p.name,
                        thread: "test",
                      })
                    }
                    threads={p.threads}
                    answeredThreads={p.answeredThreads}
                    currentScore={p.currentScore}
                    perspective={p.name}
                    perspectiveId={p.id}
                    totalThreads={p.totalThreads}
                    currentThread={currentThread}
                    activePerspective={
                      currentPerspective && currentPerspective.id
                    }
                    handleNavigation={handleProgressBarNavigation}
                  />
                );
              })}
            </Progress.Wrapper>
          </Sidebar>
        </>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
