import React from "react";
import styles from "./../styles.module.scss";

type DropOutItemProps = {
  children?: React.ReactNode;
  onClick?: () => void;
};

const DropOutItem: React.FunctionComponent<DropOutItemProps> = ({
  children,
  onClick,
}) => {
  return (
    <div className={styles.DropOutItem} onClick={onClick}>
      {children}
    </div>
  );
};

export default DropOutItem;
