import React, { useRef } from "react";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { exportComponentAsJPEG } from "react-component-export-image";
import { ReactComponent as DownloadSVG } from "modules/theme/icons/buttons/download.svg";

import styles from "./styles.module.scss";

type BarSet = {
  id: number;
  name: string;
  scores: ScoreType[];
};

type Perspective = {
  id: number;
  name: string;
  code: string;
  scores?: ScoreType[];
};

type ScoreType = {
  level: number;
  score: number;
};

type HeatmapProps = {
  items: BarSet[];
  perspectivesBase: Perspective[];
  title?: string;
};

const Heatmap: React.FunctionComponent<HeatmapProps> = ({
  items,
  perspectivesBase,
  title,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const getMissingPerspectivesAndMergeItems = () => {
    const missingPerspectives = perspectivesBase.reduce<Perspective[]>(
      (accumulator, currentValue) => {
        if (!items.find((item) => item.name === currentValue.name)) {
          accumulator.push(currentValue);
        }
        return accumulator;
      },
      []
    );

    return [...items, ...missingPerspectives];
  };

  return (
    <React.Fragment>
      <div ref={componentRef} className={styles.heatmap}>
        {title && <h3>{title}</h3>}
        <div className={styles.heatmapWrapper}>
          <div className={styles.heatmapLevels}>
            <span>Level</span>
            <ul>
              <li>5</li>
              <li>4</li>
              <li>3</li>
              <li>2</li>
              <li>1</li>
            </ul>
          </div>
          <div className={styles.heatmapBars}>
            {getMissingPerspectivesAndMergeItems().map((item) => {
              if (item.scores) {
                const bars = item.scores.map((s) => {
                  let cname;

                  switch (s.score) {
                    case 1:
                      cname = "fully";
                      break;
                    case 0.5:
                      cname = "partially";
                      break;
                    default:
                      cname = "rare";
                      break;
                  }

                  return (
                    <li className={styles[cname]} key={s.level + item.id} />
                  );
                });

                bars.reverse();

                return <ul key={item.id}>{bars}</ul>;
              }

              return (
                <ul key={item.name}>
                  <span>N/A</span>
                </ul>
              );
            })}
          </div>
        </div>
        <div className={styles.heatmapLabels}>
          <ul>
            {getMissingPerspectivesAndMergeItems().map((item, idx) => (
              <li key={item.name + idx}>{item.name}</li>
            ))}
          </ul>
        </div>
        <div className={styles.heatmapLegend}>
          <div>
            <span></span> Fully
          </div>
          <div>
            <span></span> Partially
          </div>
          <div>
            <span></span> Never
          </div>
        </div>
      </div>
      <span className={styles.legend}>
        Chart based on the average score of each level calculated across all the
        threads in a perspective
      </span>
      <div className={styles["download-container"]}>
        <Button
          label="Download image"
          style={ButtonStyle.SECONDARY}
          type={ButtonType.BUTTON}
          id={"heat-map"}
          onClick={() =>
            exportComponentAsJPEG(componentRef, {
              fileName: "Heatmap",
            })
          }
        >
          <DownloadSVG />
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Heatmap;
