import React from "react";
import clsx from "clsx";

import SimpleItem from "./SimpleItem";

import styles from "./styles.module.scss";

import { useHistory } from "react-router-dom";

type SimpleProps = {
  title: string;
  subtitle: string;
  company?: string;
  assessmentId?: number;
  organizationId?: number;
  pages: ProgressBarPageType[];
};

export type ProgressBarPageType = {
  id: number;
  isEnabled: boolean;
  isActive: boolean;
  isComplete: boolean;
  name: string;
  route: string;
};

const Simple: React.FunctionComponent<SimpleProps> = ({
  company,
  title,
  subtitle,
  assessmentId,
  pages,
}) => {
  const className = clsx(styles["progress-section"]);
  const history = useHistory();

  const handleSectionClick = (route: string) => {
    if (assessmentId) {
      history.push(`/assessments/${assessmentId}/${route}`);
    }
  };

  return (
    <section className={className}>
      {title && <h2>{title}</h2>}
      <div>
        <h4>{subtitle}</h4>
        {company && <p>{company}</p>}
        {pages && (
          <ul>
            {pages.map((page) => (
              <SimpleItem
                key={page.id}
                item={page}
                onClick={handleSectionClick}
                hasAssessmentId={!!assessmentId}
              />
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default Simple;
