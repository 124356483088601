/* eslint-disable prettier/prettier */
import React from "react";

import { ReactComponent as CertifiedIcon } from "modules/theme/icons/icon-certified-green.svg";

import Headings from "components/Headings";
import { Organization } from "modules/api/endpoints/organizationGet";
import Button, { ButtonStyle, ButtonType, ButtonSize } from "components/Button";

import { Items, ProgressType, ProgressItem } from "./index";

import Section from "components/Section";
import Sidebar from "components/Sidebar";
import Loading from "components/Loading";
import Note from "components/Notes";

import styles from "../styles.module.scss";

import OrganizationDetails from "components/Headings/OrganizationDetails";
import PageWrapper from "components/Wrapper";

type TemplateProps = {
  isLoading: boolean;
  isArchived: boolean;
  organization: Organization;
  displayCertifyAssessment: boolean;
  scope: string;
  name: string;
  handleNavegate: (code: string) => void;
  handleCertify: () => void;
  progress: ProgressType;
  isAssessmentSubmitable: boolean;
  isSubmitingAssessment: boolean;
  handleSubmitAssessment: () => void;
  isAssessmentLocked: string;
  handlePracticeStatus: (item: ProgressItem) => string;
  handleUnarchive: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  isArchived,
  organization,
  scope,
  name,
  displayCertifyAssessment,
  handleNavegate,
  handleCertify,
  progress,
  isAssessmentSubmitable,
  isSubmitingAssessment,
  handleSubmitAssessment,
  isAssessmentLocked,
  handlePracticeStatus,
  handleUnarchive,
}) => {
  const externaITILURL =
    "https://eu-assets.contentstack.com/v3/assets/blt637b065823946b12/bltce88ffa4d3ed241b/Axelos_Maturity_Level_Certification_2021.pdf";
  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <Section>
            <>
              <Headings title={name} subtitle={"Assessment progress summary"} />
              <PageWrapper>
                <div className={styles.tableWrapper}>
                  <table className={styles.itil}>
                    <thead>
                      <tr>
                        <th>{scope}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={styles.perspectiveHead}>
                        <th>Capability practices</th>
                        <th>
                          <span>Rank</span>
                        </th>
                        <th>
                          <span>Status</span>
                        </th>
                        <th>
                          <span>Score</span>
                        </th>
                        <th></th>
                      </tr>
                      {progress.capabilityProgress
                        .sort((a, b) => a.mode - b.mode)
                        .map((cap) => {
                          return (
                            <React.Fragment key={cap.id}>
                              <tr className={handlePracticeStatus(cap)}>
                                <td>{cap.name}</td>
                                <th>
                                  {cap.mode === 1 ? "Primary" : "Supporting"}
                                </th>
                                <th>
                                  {cap.complete
                                    ? "Complete"
                                    : cap.started
                                    ? "In progress"
                                    : "Not started"}
                                </th>
                                <th>{cap.currentScore}</th>
                                <th>
                                  <Button
                                    label="Edit"
                                    style={ButtonStyle.PRIMARY}
                                    size={ButtonSize.INLINE}
                                    onClick={() => handleNavegate(cap.code)}
                                    disabled={isArchived}
                                  />
                                </th>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      {scope !== "Capability" && (
                        <tr className={styles.perspectiveHead}>
                          <th>Maturity components</th>
                          <th></th>
                          <th>
                            <span>Status</span>
                          </th>
                          <th>
                            <span>Score</span>
                          </th>
                          <th>
                            <span></span>
                          </th>
                        </tr>
                      )}
                      {scope !== "Capability" &&
                        progress.maturityProgress.map((mat) => {
                          return (
                            <React.Fragment key={mat.id}>
                              <tr className={handlePracticeStatus(mat)}>
                                <td>{mat.name}</td>
                                <th></th>
                                <th>
                                  {mat.complete
                                    ? "Complete"
                                    : mat.started
                                    ? "In progress"
                                    : "Not started"}
                                </th>
                                <th>{mat.currentScore}</th>
                                <th>
                                  <Button
                                    label="Edit"
                                    style={ButtonStyle.PRIMARY}
                                    size={ButtonSize.INLINE}
                                    onClick={() => handleNavegate(mat.code)}
                                    disabled={isArchived}
                                  />
                                </th>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                  {scope !== "Capability" && (
                    <div className={styles.maturityScore}>
                      SVS Maturity Score: {progress.maturityScore}
                    </div>
                  )}
                </div>
              </PageWrapper>
              <div className={styles.pageActions}>
                <Button
                  disabled={
                    isAssessmentLocked === "Completed" || isArchived
                      ? true
                      : !isAssessmentSubmitable
                  }
                  onClick={handleSubmitAssessment}
                  isLoading={isSubmitingAssessment}
                  label="Submit assessment"
                  style={ButtonStyle.PRIMARY}
                  type={ButtonType.BUTTON}
                  />
              </div>
            </>
          </Section>
          <Sidebar>
            <OrganizationDetails
              name={organization.name}
              country={organization.country.name}
              businessUnit={organization.businessUnit}
            ></OrganizationDetails>
            {isArchived && (
              <Note>
                <p>
                  This assessment has been archived.
                  <strong
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={handleUnarchive}
                  >
                    &nbsp;Click here&nbsp;
                  </strong>
                  to restore if you would like to continue working on it.
                </p>
              </Note>
            )}
            {displayCertifyAssessment && (
              <Note>
                <CertifiedIcon />
                {scope === "Comprehensive" && (
                  <>
                    <h3>Want to certify this assessment?</h3>
                    <p>
                      You have the option of certifying this assessment. By
                      selecting this option, you are requesting that this
                      assessment is independently validated by Axelos and you
                      confirm that you have received consent from the end
                      customer to share data with Axelos.
                    </p>
                    <p>
                      Customers will receive an ITIL® Maturity Certificate,
                      which will be valid for three years and is subject to a
                      fee. More information can be found{" "}
                      <a href={externaITILURL} target="_new">
                        here
                      </a>
                    </p>
                  </>
                )}
                {scope === "Capability" && (
                  <>
                    <h3>Want an approved statement of results?</h3>
                    <p>
                      You have the option of receiving an Axelos approved
                      statement of results for this assessment. By selecting
                      this option, you are requesting that this assessment is
                      independently validated by Axelos and you confirm that you
                      have received consent from the end customer to share data
                      with Axelos.
                    </p>
                    <p>
                      Customers will receive an ITIL® Capability Assessment –
                      Statement of result, which will be valid for three years
                      and is subject to a fee. More information can be found{" "}
                      <a href={externaITILURL} target="_new">
                        here
                      </a>
                    </p>
                  </>
                )}
                {scope === "Maturity" && (
                  <>
                    <h3>Want an approved statement of results?</h3>
                    <p>
                      You have the option of receiving an Axelos approved
                      statement of results for this assessment. By selecting
                      this option, you are requesting that this assessment is
                      independently validated by Axelos and you confirm that you
                      have received consent from the end customer to share data
                      with Axelos.
                    </p>
                    <p>
                      Customers will receive an ITIL® Service Value System
                      Assessment – Statement of result, which will be valid for
                      three years and is subject to a fee. More information can
                      be found{" "}
                      <a href={externaITILURL} target="_new">
                        here
                      </a>
                    </p>
                  </>
                )}
                <Button
                  label={
                    scope === "Comprehensive"
                      ? "Certify assessment"
                      : "Request statement"
                  }
                  style={ButtonStyle.PRIMARY}
                  type={ButtonType.BUTTON}
                  onClick={handleCertify}
                  disabled={isArchived}
                />
              </Note>
            )}

            {!displayCertifyAssessment && (
              <Note>
                {scope === "Comprehensive" && (
                  <>
                    <h3>Certify this assessment</h3>
                    <p>
                      You have chosen to receive an ITIL® Maturity Certificate
                      for this assessment. By selecting this option, you are
                      requesting that this assessment is independently validated
                      by Axelos and you confirm that you have received consent
                      from the end customer to share data with Axelos.
                    </p>
                    <p>
                      Certificates will be valid for three years and are subject
                      to a fee. More information can be found{" "}
                      <a href={externaITILURL} target="_new">
                        here
                      </a>
                    </p>
                  </>
                )}
                {scope === "Capability" && (
                  <>
                    <h3>Statement of results</h3>
                    <p>
                      You have chosen to receive an ITIL® Capability Assessment
                      – Statement of result for this assessment. By selecting
                      this option, you are requesting that this assessment is
                      independently validated by Axelos and you confirm that you
                      have received consent from the end customer to share data
                      with Axelos.
                    </p>
                    <p>
                      Statements of result will be valid for three years and are
                      subject to a fee. More information can be found{" "}
                      <a href={externaITILURL} target="_new">
                        here
                      </a>
                    </p>
                  </>
                )}
                {scope === "Maturity" && (
                  <>
                    <h3>Statement of results</h3>
                    <p>
                      You have chosen to receive an ITIL® Service Value System
                      Assessment – Statement of result for this assessment. By
                      selecting this option, you are requesting that this
                      assessment is independently validated by Axelos and you
                      confirm that you have received consent from the end
                      customer to share data with Axelos.
                    </p>
                    <p>
                      Statements of result will be valid for three years and are
                      subject to a fee. More information can be found{" "}
                      <a href={externaITILURL} target="_new">
                        here
                      </a>
                    </p>
                  </>
                )}
              </Note>
            )}
          </Sidebar>
        </>
      )}
      {isLoading && <Loading noBackground />}
    </React.Fragment>
  );
};

export default Template;
