import clsx from "clsx";
import React from "react";

import styles from "./styles.module.scss";

type SidebarProps = {
  large?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({ children, large }) => {
  const classNames = clsx(styles.sidebar, large && styles["large"]);
  return <aside className={classNames}>{children}</aside>;
};

export default Sidebar;
