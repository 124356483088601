import React from "react";
import { useController, Control } from "react-hook-form";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import QuestionTemplate from "../QuestionTemplate";
import Debug from "../QuestionTemplate/Debug";
import { Expand } from "modules/typescript";
import Buttons from "components/Inputs/Buttons";
import {
  ButtonsFormat,
  ButtonsType,
} from "components/Inputs/Buttons/constants";
import { OptionType } from "components/Inputs/Buttons/types";

type QuestionCheckboxProps = Expand<
  {
    control: Control;
    format?: ButtonsFormat;
    options?: OptionType[];
    selectAll?: boolean;
  } & Omit<QuestionTemplateProps, "answered" | "children" | "invalid">
>;

const QuestionCheckbox: React.FunctionComponent<QuestionCheckboxProps> = ({
  control,
  format,
  name,
  required,
  selectAll,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });
  return (
    <QuestionTemplate
      answered={field.value && !invalid}
      invalid={invalid}
      name={name}
      required={required}
      {...props}
    >
      <>
        <Buttons
          selectAll={selectAll}
          format={format}
          invalid={invalid}
          type={ButtonsType.CHECKBOX}
          {...props}
          {...field}
        />
      </>
      {process.env.NODE_ENV === "development" && <Debug value={field.value} />}
    </QuestionTemplate>
  );
};

export default React.memo(QuestionCheckbox);
