/* eslint-disable prettier/prettier */
import React from "react";

import * as TableSet from "components/TableSet";
import * as Charts from "components/Charts";

import Section from "components/Section";
import Sidebar from "components/Sidebar";
import Loading from "components/Loading";
import Button, { ButtonStyle, ButtonType } from "components/Button";

import { FinalScoresType } from "components/TableSet/Table";
import {
  DiagnosticType,
  PerspectiveItem,
  ThreadItem,
  HeatmapScoresType,
  NoteType,
} from "modules/api/endpoints/assessmentResultGet";
import { Organization } from "modules/api/endpoints/organizationGet";

import { PerspectiveType } from "modules/api/endpoints/perspectivesGet";
import { ChartData } from "components/Charts/Radar";
import Headings from "components/Headings";
import Heatmap from "components/Heatmap";
import SwitchButton from "components/Inputs/Switch";

import { BenchmarkDataType } from "./index";

import { ReactComponent as DownloadSVG } from "modules/theme/icons/buttons/download.svg";

type TemplateProps = {
  isLoading: boolean;
  diagnostics: DiagnosticType;
  perpectiveScores: PerspectiveItem[];
  threadScores: ThreadItem[];
  organization: Organization;
  benchmarking: ChartData;
  finalScores: FinalScoresType;
  isCertified: boolean;
  perspectivesData: PerspectiveType[];
  handleViewFullAssessment: () => void;
  exportToElsx: () => void;
  assessmentName: string;
  displayBenchmarkingData: boolean;
  handleDisplayBenchmarkingData: () => void;
  banchMarkDataScore: BenchmarkDataType;
  heatmapScores: HeatmapScoresType[];
  notes: NoteType[];
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  diagnostics,
  perpectiveScores,
  threadScores,
  organization,
  benchmarking,
  finalScores,
  isCertified,
  perspectivesData,
  handleViewFullAssessment,
  assessmentName,
  displayBenchmarkingData,
  handleDisplayBenchmarkingData,
  banchMarkDataScore,
  heatmapScores,
  notes,
  exportToElsx,
}) => {
  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <Section small>
            <Headings title={assessmentName} subtitle="Results" />
            <Charts.Container>
              <Charts.Header organization={organization} />
              <Charts.Item
                title={
                  displayBenchmarkingData ? "Benchmarking" : "Overall scores"
                }
                label={
                  displayBenchmarkingData ? banchMarkDataScore.message : ""
                }
                message={
                  "Scores shown are decimal assessment scores. Maturity scores are not included for benchmarking purposes"
                }
                benchMark={displayBenchmarkingData}
                gmTagId={"radar-chart"}
              >
                <Charts.Radar data={benchmarking} />
              </Charts.Item>

              <Heatmap
                title="Heatmap"
                items={heatmapScores}
                perspectivesBase={perspectivesData}
              />
            </Charts.Container>
            {(diagnostics.positive.length > 0 ||
              diagnostics.improvement.length > 0) && (
              <div style={{ marginBottom: "30px" }}>
                <TableSet.TableContainer
                  title={"Diagnostic attributes breakdown"}
                  subtitle={"Diagnostic statement responses highlights"}
                >
                  {diagnostics.positive.length > 0 && (
                    <TableSet.TableGroupWrapper
                      positive
                      gmTagId={"diagnostic-attributes-positive"}
                    >
                      {diagnostics.positive.map((positiveItem) => {
                        return (
                          <TableSet.Table
                            key={positiveItem.id}
                            hasFullBg={false}
                            grouped
                            tableLabel={positiveItem.perspective}
                            rowsDiagnostics={positiveItem.threads}
                            thead={["Threads", "Statements"]}
                          />
                        );
                      })}
                    </TableSet.TableGroupWrapper>
                  )}

                  {diagnostics.improvement.length > 0 && (
                    <TableSet.TableGroupWrapper
                      gmTagId={"diagnostic-attributes-improvement"}
                    >
                      {diagnostics.improvement.map((improvementItem) => {
                        return (
                          <TableSet.Table
                            key={improvementItem.id}
                            hasFullBg={false}
                            grouped
                            tableLabel={improvementItem.perspective}
                            rowsDiagnostics={improvementItem.threads}
                            thead={["Threads", "Statements"]}
                          />
                        );
                      })}
                    </TableSet.TableGroupWrapper>
                  )}
                </TableSet.TableContainer>
              </div>
            )}
            {notes.length > 0 && (
              <TableSet.TableContainer title={"Notes"}>
                {perspectivesData.map((p) => {
                  const notesPerPerspective = notes.filter(
                    (n) => n.perspective === p.name
                  );
                  if (notes.find((n) => n.perspective === p.name)) {
                    return (
                      <TableSet.Table
                        key={p.id}
                        tableLabel={p.name}
                        grouped
                        rowsNotes={notesPerPerspective}
                        thead={["Note", "Thread"]}
                      />
                    );
                  }
                })}
              </TableSet.TableContainer>
            )}
          </Section>
          <Sidebar large>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "12px",
              }}
            >
              <Button
                label="View full assessment"
                style={ButtonStyle.PRIMARY}
                type={ButtonType.BUTTON}
                onClick={handleViewFullAssessment}
              />
              <div
                style={{
                  marginLeft: "12px",
                }}
              >
                <Button
                  label="Export to Excel"
                  style={ButtonStyle.SECONDARY}
                  type={ButtonType.BUTTON}
                  onClick={exportToElsx}
                >
                  <DownloadSVG />
                </Button>
              </div>
            </div>

            <SwitchButton
              name="benchmarck"
              small="small"
              label="View results with benchmarking"
              checked={displayBenchmarkingData}
              onChange={handleDisplayBenchmarkingData}
            />

            <TableSet.Table
              hasHeading="Final Scores"
              isCertified={isCertified}
              noBg
              tblSmall
              rowsFinalScore={finalScores}
            />
            <TableSet.Table
              hasHeading="Decimal scores"
              hasFullBg
              canDownload
              tblSmall
              thead={["Rank", "Perspective", "Score"]}
              rowsOverall={perpectiveScores}
              banchmarkScore={banchMarkDataScore.perspectivesAndScores}
              displayBenchmark={displayBenchmarkingData}
              gmTagId={"overall-scores"}
            />
            <TableSet.Table
              hasHeading="Thread scores"
              hasFullBg
              canDownload
              tblSmall
              thead={["Rank", "Thread", "Score"]}
              rowsThreads={threadScores}
              gmTagId={"thread-scores"}
            />
          </Sidebar>
        </React.Fragment>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
