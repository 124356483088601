import React from "react";
import { SubmitHandler } from "react-hook-form";

import * as Form from "components/Form";
import * as Question from "components/Questions";
import * as Progress from "components/Progress";

import Headings from "components/Headings";
import Section from "components/Section";
import Sidebar from "components/Sidebar";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { ButtonsFormat } from "components/Inputs/Buttons/constants";
import Loading from "components/Loading";
import Note from "components/Notes";

import { FormStateType, OptionType } from "./types";
import { ProgressBarPageType } from "components/Progress/Simple/index";
import { Organization } from "modules/api/endpoints/organizationGet";
import { AssessmentScopeSchema } from "./schema";
import OrganizationDetails from "components/Headings/OrganizationDetails";

type TemplateProps = {
  isLoading: boolean;
  levels: OptionType[];
  isCertified: boolean;
  handleSubmit: SubmitHandler<FormStateType>;
  handleGoBack: () => void;
  perspectivesData: OptionType[];
  threadsData: OptionType[];
  formStates: FormStateType;
  organization: Organization;
  selectedScope: string;
  progressBarPages: ProgressBarPageType[];
  assessmentId?: number;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  isCertified,
  handleSubmit,
  handleGoBack,
  levels,
  threadsData,
  perspectivesData,
  formStates,
  organization,
  selectedScope,
  progressBarPages,
  assessmentId,
}) => {
  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <Section>
            <Headings
              title="Assessment profile set-up"
              subtitle="Scope details"
            />
            <Form.Wrapper
              onSubmit={handleSubmit}
              state={formStates}
              mode="all"
              schema={AssessmentScopeSchema}
            >
              {({ control, formState: { isSubmitting, isValid } }) => {
                return (
                  <>
                    <Form.Fieldset
                      assessmentSetup
                      title={`Scope: ${selectedScope}`}
                    >
                      <Question.QuestionRadio
                        required
                        control={control}
                        format={ButtonsFormat.HORIZONTAL}
                        label="Please select the most likely level for the organization
                        being assessed. If you are unsure then select Level 2"
                        name={"selectedLevel"}
                        options={levels}
                      />

                      <Question.QuestionCheckbox
                        required
                        selectAll
                        control={control}
                        disabled={false}
                        format={ButtonsFormat.VERTICAL}
                        label="Select the Perspective(s) to be assessed"
                        info="Select one or more of the following options"
                        name="perspectives"
                        options={perspectivesData}
                        readOnly={isCertified}
                      />

                      <Question.QuestionCheckbox
                        selectAll
                        required
                        control={control}
                        disabled={false}
                        format={ButtonsFormat.VERTICAL}
                        label="Select the Thread(s) to be assessed"
                        info="Select one or more of the following options"
                        name="threads"
                        options={threadsData}
                      />
                    </Form.Fieldset>

                    <Form.Actions>
                      <Button
                        label="Go back"
                        style={ButtonStyle.SECONDARY}
                        type={ButtonType.BUTTON}
                        onClick={handleGoBack}
                      />
                      <Button
                        disabled={!isValid}
                        label="Save & Continue"
                        style={ButtonStyle.PRIMARY}
                        type={ButtonType.SUBMIT}
                        isLoading={isSubmitting}
                      />
                    </Form.Actions>
                  </>
                );
              }}
            </Form.Wrapper>
          </Section>
          <Sidebar>
            <OrganizationDetails
              name={organization && organization.name ? organization.name : ""}
              country={
                organization && organization.country
                  ? organization.country.name
                  : ""
              }
              businessUnit={
                organization && organization.businessUnit
                  ? organization.businessUnit
                  : ""
              }
            />
            {isCertified && (
              <Note>
                <h3>Note</h3>
                <p>
                  Your options have been greyed out because you have opted to be
                  certified. You must assess all perspectives and four mandatory
                  threads.
                </p>
              </Note>
            )}
            <Progress.Simple
              title="Your Progress"
              subtitle="Assessment profile set-up"
              company={organization.name}
              pages={progressBarPages}
              assessmentId={assessmentId}
              organizationId={organization.id ? organization.id : 0}
            />
          </Sidebar>
        </>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
