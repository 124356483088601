import React, { useEffect, useState } from "react";
import { Control, useController } from "react-hook-form";

import { QuestionTemplateProps } from "../QuestionTemplate/types";
import QuestionTemplate from "../QuestionTemplate";
import Debug from "../QuestionTemplate/Debug";
import { Expand } from "modules/typescript";
import Buttons from "components/Inputs/Buttons";
import {
  ButtonsFormat,
  ButtonsType,
} from "components/Inputs/Buttons/constants";
import { OptionType } from "components/Inputs/Buttons/types";
import Loading from "components/Loading";
import { useHistory, useRouteMatch } from "react-router-dom";
import { api, API } from "modules/api";
interface OptionStatement extends OptionType {
  statement?: string;
}

type ItilQuestionAssessmentProps = Expand<
  {
    control: Control;
    format?: ButtonsFormat;
    options?: OptionStatement[];
    isAnswering?: boolean;
    allQuestionsAnswered?: boolean;
    handleSelectedValue: (value: number | string) => void;
  } & QuestionTemplateProps
>;
type questiosnParam = {
  assessmentId: string;
};

const ItilQuestionAssessment: React.FunctionComponent<
  ItilQuestionAssessmentProps
> = ({
  control,
  format,
  name,
  required,
  handleSelectedValue,
  children,
  isAnswering,
  allQuestionsAnswered,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });
  const {
    params: { assessmentId },
  } = useRouteMatch<questiosnParam>();
  const history = useHistory();
  const token = sessionStorage.getItem("@P3M3:token");
  // Get User Session Token
  let parsedToken = null;
  if (token) {
    parsedToken = JSON.parse(token);
  }
  const data = { authorization: parsedToken.token };
  const url = API.POST_DELETE_ASSESSMENT_LOCK(Number(assessmentId)).url;
  // Set request content type
  const headers = {
    type: "application/json",
  };
  // set request data
  const blob = new Blob([JSON.stringify(data)], headers);
  // Setup beacon request
  const eventHandler = () => {
    sessionStorage.removeItem(`@P3M3:lock:${assessmentId}`);
    navigator.sendBeacon(url, blob);
  };
  // Check if assessment has been locked already
  const handleIsLocked = async (id: number) => {
    try {
      const response = await api(API.POST_ASSESSMENT_LOCK(id)).then(
        (res) => res.data
      );
      if (response) {
        return true;
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        history.push("/");
        sessionStorage.removeItem(`@P3M3:lock:${assessmentId}`);
        return false;
      }
    }
    return false;
  };
  // Handle user lock
  const checkLockSession = async () => {
    if (sessionStorage.getItem(`@P3M3:lock:${assessmentId}`) === null) {
      sessionStorage.setItem(`@P3M3:lock:${assessmentId}`, "1");
      const response = await handleIsLocked(Number(assessmentId));
      if (response) {
        return true;
      }
    }
  };

  useEffect(() => {
    checkLockSession();
    window.addEventListener("beforeunload", eventHandler, true);
    return () => {
      window.removeEventListener("beforeunload", eventHandler, true);
    };
  }, []);
  return (
    <QuestionTemplate
      answered={
        allQuestionsAnswered != null ? allQuestionsAnswered : field.value
      }
      invalid={invalid}
      name={name}
      required={required}
      {...props}
    >
      <Buttons
        format={format}
        invalid={invalid}
        type={ButtonsType.RADIO}
        handleSelectedValue={handleSelectedValue}
        {...props}
        {...field}
      />
      {children}
      {process.env.NODE_ENV === "development" && <Debug value={field.value} />}
      {isAnswering && <Loading noBackground />}
    </QuestionTemplate>
  );
};

export default ItilQuestionAssessment;
