import React from "react";

import Navigation from "./Navigation";

import styles from "./styles.module.scss";

type TemplateProps = { children?: React.ReactNode };

const Template: React.FunctionComponent<TemplateProps> = ({ children }) => {
  return (
    <React.Fragment>
      <Navigation />
      <main className={styles.main}>{children}</main>
    </React.Fragment>
  );
};

export default Template;
