import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import Button, { ButtonStyle, ButtonType } from "components/Button";
import { ReactComponent as ExitSVG } from "modules/theme/icons/buttons/exit.svg";

import { BaseItem, ProgressType } from "../types";

type Progress = Pick<ProgressType, "perspectivesProgress">;

type QuestionPaginationType = {
  name: string;
  perspectives: BaseItem[];
  threads: BaseItem[];
  progress: Progress;
  isAssessmentLocked: boolean;
  showSummaryButton: boolean;
};

type QuestionsParam = {
  assessmentId: string;
  perspectiveCode: string;
  threadCode: string;
};

const QuestionPagination: React.FC<QuestionPaginationType> = ({
  perspectives,
  threads,
  progress,
  isAssessmentLocked,
  showSummaryButton,
}) => {
  const [nextPerspectiveState, setNextPerspectiveState] =
    useState<BaseItem | null>(null);
  const [nextThreadState, setNextThreadState] = useState<BaseItem | null>(null);
  const [currentPage, setCurrentPage] = useState("Next Thread");

  const {
    params: { assessmentId, perspectiveCode, threadCode },
  } = useRouteMatch<QuestionsParam>();

  const history = useHistory();

  useEffect(() => {
    const currtPerspective = getCurrentPerspective();
    const currtThread = getCurrentThread();

    const currentPerspectiveIdx = getCurrentPerspectiveIndex(
      currtPerspective.name
    );

    const nextPerspective = getNextPerspective(currentPerspectiveIdx);

    const nextPerspectiveFirstThreadCode =
      getNextPerspectiveFirstThreadCode(nextPerspective);

    const currentThreadIdx = getCurrentThreadIdx(
      currentPerspectiveIdx,
      currtThread.name
    );

    const nextThread = getNextThread(currentPerspectiveIdx, currentThreadIdx);

    setNextPerspectiveState(() => {
      if (!nextThread) return nextPerspective;
      return currtPerspective;
    });

    setNextThreadState(() => {
      if (!nextThread && !nextPerspectiveFirstThreadCode) return null;
      if (nextThread) return nextThread;
      return nextPerspectiveFirstThreadCode;
    });

    setCurrentPage(() => {
      if (!nextThread && !nextPerspectiveFirstThreadCode) return "View summary";
      if (!nextThread) return "Next perspective";
      return "Next thread";
    });
  }, []);

  const getCurrentPerspective = () => {
    return perspectives.filter((p) => p.code === perspectiveCode)[0];
  };

  const getCurrentThread = () => {
    return threads.filter((p) => p.code === threadCode)[0];
  };

  const getCurrentPerspectiveIndex = (perspective: string) => {
    return progress.perspectivesProgress.findIndex(
      (i) => i.name === perspective
    );
  };

  const getNextPerspective = (currtPerspectiveIdx: number) => {
    return perspectives.filter((p) => {
      const nextPerspectiveProgress =
        progress.perspectivesProgress[currtPerspectiveIdx + 1];

      return nextPerspectiveProgress && p.name === nextPerspectiveProgress.name;
    })[0];
  };

  const getNextPerspectiveFirstThreadCode = (nextPerspective: BaseItem) => {
    return threads.filter((t) => {
      const nextProgressPerspective = progress.perspectivesProgress.filter(
        (pp) => nextPerspective && pp.name === nextPerspective.name
      )[0];

      if (
        nextProgressPerspective &&
        t.name === nextProgressPerspective.threads[0].name
      )
        return t;
    })[0];
  };

  const getCurrentThreadIdx = (
    currPerspectiveIdx: number,
    currThread: string
  ) => {
    return progress.perspectivesProgress[currPerspectiveIdx].threads.findIndex(
      (t) => t.name === currThread
    );
  };

  const getNextThread = (currPerspectiveIdx: number, currThreadIdx: number) => {
    return threads.filter((t) => {
      const nextThreadProgress =
        progress.perspectivesProgress[currPerspectiveIdx].threads[
          currThreadIdx + 1
        ];

      if (nextThreadProgress && t.name === nextThreadProgress.name) return t;
    })[0];
  };

  const getButtonLabel = () => {
    return showSummaryButton && !isAssessmentLocked
      ? "View Summary"
      : currentPage;
  };

  const handlePagination = () => {
    if (!isAssessmentLocked && showSummaryButton) {
      history.push(`/assessments/${assessmentId}/summary`);
      return;
    }

    if (!nextThreadState && !nextPerspectiveState) {
      if (isAssessmentLocked) {
        history.push(`/assessments/${assessmentId}/result`);
        return;
      }
      history.push(`/assessments/${assessmentId}/summary`);
      return;
    }

    history.push(
      `/assessments/${assessmentId}/questions/${nextPerspectiveState?.code}/${nextThreadState?.code}`
    );
  };

  return (
    <>
      {!isAssessmentLocked && (
        <Button
          label="Save and Exit"
          style={ButtonStyle.SECONDARY}
          type={ButtonType.BUTTON}
          onClick={() => history.push(`/`)}
        >
          <ExitSVG />
        </Button>
      )}

      <Button
        label={getButtonLabel()}
        style={ButtonStyle.PRIMARY}
        type={ButtonType.BUTTON}
        onClick={handlePagination}
      />
    </>
  );
};

export default QuestionPagination;
