/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { API, api } from "modules/api";
import { useAssessments } from "./hooks/useAssessments";
import { Organization } from "modules/api/endpoints/organizationGet";
import { PerspectiveType } from "modules/api/endpoints/perspectivesGet";
import { storeCompanyId, getCompanyId } from "modules/utils/storeCompanyId";

import { tabOptions } from "./types";

import PanelHeader from "./PanelHeader";
import PanelBody from "./PanelBody";
import PanelTab from "./PanelTab";
import PanelTable from "./PanelTable";
import PanelRow from "./PanelRow";
import PanelRowItil from "./PanelRow/Itil";

import PanelWrapper from "./PanelWrapper";
import styles from "./styles.module.scss";

type PanelProps = {
  organization: Organization;
  activeAssessments: number;
  completedAssessments: number;
  createdAssessments: number;
  draftAssessments: number;
  totalAssessments: number;
  perspectives: PerspectiveType[];
  roles: string[];
};

const Wrapper: React.FunctionComponent<PanelProps> = ({
  totalAssessments,
  organization,
  draftAssessments,
  createdAssessments,
  completedAssessments,
  activeAssessments,
  perspectives,
  roles
}) => {
  const [
    assessments,
    tabAssessments,
    tabItilAssessments,
    setAssessments,
    setTabAssessments,
    setItilTabAssessments,
    lockedAssessments,
    setLockedAssessments,
    handleIsLocked,
    handleArchiveToggle,
  ] = useAssessments();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(tabOptions.Active);
  const history = useHistory();
  const companyRef = useRef<HTMLDivElement>(null);


  const executeScroll = () => {
    companyRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  const handleFetchAssessment = async () => {
    localStorage.removeItem("@P3M3:lastOpenItem");
    if (isOpen) {
      setIsOpen(false);
      return;
    }

    setActiveTab(tabOptions.Active);
    if (totalAssessments !== 0) {
      setIsLoading(true);

      try {
        const response = await api(API.GET_ORGANIZATION_ASSESSMENTS(organization.id)).then(
          (res) => res.data
        );
        if (response) {
          setAssessments(response);
          setTabAssessments(() => {
            const { active, created, drafted } = response.P3M3;
            return response ? [...active, ...drafted, ...created] : [];
          });
          setItilTabAssessments(() => {
            const { active, created, drafted } = response.ITIL;
            return response ? [...active, ...drafted, ...created] : [];
          });
          setActiveTab(tabOptions.Active);

          setIsOpen(!isOpen);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  }

  const openLastCompany = async (id: number) => {
    if (organization.id !== id) {
      return;
    }
    if (totalAssessments !== 0) {
      setIsLoading(true);
      await api(API.GET_ORGANIZATION_ASSESSMENTS(id)).then((res) => {
        setAssessments(res.data);
        setTabAssessments(() => {
          const { active, created, drafted } = res.data.P3M3;
          return res.data ? [...active, ...drafted, ...created] : [];
        });
        setItilTabAssessments(() => {
          const { active, created, drafted } = res.data.ITIL;
          return res.data ? [...active, ...drafted, ...created] : [];
        });
        setIsOpen(!isOpen);
        setIsLoading(false);
        executeScroll();
      });
    }
  };

  const handleEditCompany = (companyId: number): void => {
    storeCompanyId(companyId);
    history.push(`organization/${companyId}/edit`);
  };

  const handleCreateAssessment = (organizationId: number): void => {
    storeCompanyId(organizationId);
    history.push(`organizations/${organizationId}/assessment`);
  };

  useEffect(() => {
    openLastCompany(Number(getCompanyId()));
  }, []);

  const isUnlocked = async (id: number) => {
    try {
      const response = await api(API.GET_ASSESSMENT_LOCK(id));
      if (response) {
        setLockedAssessments(oldIds => {
          return [...oldIds, id]
        })
        return false
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        setLockedAssessments(oldIds => {
          return oldIds.filter(i => i !== id)
        })
        return true
      }
    }
    return false
  }

  const handleAssessmentProfile = async (assessmentId: number, type?: string) => {
    const isAssessmentUnLocked = await handleIsLocked(assessmentId);
    if (isAssessmentUnLocked) {
      storeCompanyId(organization.id);
      if (type === "ITIL") {
        history.push(`assessments/${assessmentId}/profile/Itil`)
        return
      }
      history.push(`assessments/${assessmentId}/profile`);
    }
  };

  const handleSelectedTab = (tab: string) => {
    switch (tab) {
      case tabOptions.Active:
        return handleActiveTab();
      case tabOptions.Completed:
        return handleCompletedTab();
      case tabOptions.Archive:
        return handleArchiveTab();
      default:
        return null;
    }
  };

  const handleArchiveTab = () => {
    setTabAssessments(() => {
      return assessments?.P3M3 ? [...assessments.P3M3.archived] : [];
    });
    setItilTabAssessments(() => {
      return assessments?.ITIL ? [...assessments.ITIL.archived] : [];
    });
    setActiveTab(tabOptions.Archive);
  };

  const handleCompletedTab = () => {
    setTabAssessments(() => {
      return assessments?.P3M3 ? [...assessments.P3M3.completed] : [];
    });
    setItilTabAssessments(() => {
      return assessments?.ITIL ? [...assessments.ITIL.completed] : [];
    });
    setActiveTab(tabOptions.Completed);
  };

  const handleActiveTab = () => {
    setTabAssessments(() => {
      return assessments
        ? [
          ...assessments.P3M3?.active,
          ...assessments.P3M3?.drafted,
          ...assessments.P3M3?.created,
        ]
        : [];
    });
    setItilTabAssessments(() => {
      return assessments
        ? [
          ...assessments.ITIL?.active,
          ...assessments.ITIL?.drafted,
          ...assessments.ITIL?.created,
        ]
        : [];
    });

    setActiveTab(tabOptions.Active);
  };

  return (
    <PanelWrapper
      isLoading={isLoading}
      isOpen={isOpen}
      ref={companyRef}
      handleFetchAssessment={handleFetchAssessment}
    >
      <PanelHeader
        organization={organization}
        isOpen={isOpen}
        totalAssessments={totalAssessments}
        draftAssessments={draftAssessments}
        createdAssessments={createdAssessments}
        completedAssessments={completedAssessments}
        activeAssessments={activeAssessments}
        handleFetchAssessment={handleFetchAssessment}
        handleEditCompany={handleEditCompany}
        handleCreateAssessment={handleCreateAssessment}
      />
      <PanelBody isOpen={isOpen}>
        <PanelTab
          activeTab={activeTab}
          tabNav={[tabOptions.Active, tabOptions.Completed, tabOptions.Archive]}
          handleActiveTab={handleSelectedTab}
        />
        {roles?.includes("ITIL") &&
          <PanelTable
            activeTab={activeTab}
            type="ITIL">
            {tabItilAssessments && tabItilAssessments.map((assmt) => (

              <PanelRowItil
                key={assmt.id}
                assessment={assmt}
                organizationId={organization.id}
                handleAssessmentProfile={handleAssessmentProfile}
                handleIsLocked={handleIsLocked}
                isUnlocked={isUnlocked}
                locked={lockedAssessments.includes(assmt.id)}
                onChangeArchive={handleArchiveToggle}
              />
            ))}
            {tabItilAssessments.length === 0 && (
              <tr>
                <td colSpan={4} className={styles.assessment__empty}>
                  {`There are currently no ${activeTab.toLowerCase()} assessments`}
                </td>
              </tr>
            )}
          </PanelTable>
        }

        {roles?.includes("P3M3") &&
          <PanelTable
            perspectivesData={perspectives}
            activeTab={activeTab}
            type="P3M3">
            {tabAssessments.length > 0 &&
              tabAssessments.map((a) => (
                <PanelRow
                  key={a.id}
                  assessment={a}
                  organizationId={organization.id}
                  handleAssessmentProfile={handleAssessmentProfile}
                  perspectivesData={perspectives}
                  handleIsLocked={handleIsLocked}
                  isUnlocked={isUnlocked}
                  locked={lockedAssessments.includes(a.id)}
                  onChangeArchive={handleArchiveToggle}
                />
              ))}
            {tabAssessments.length === 0 && (
              <tr>
                <td colSpan={4} className={styles.assessment__empty}>
                  {`There are currently no ${activeTab.toLowerCase()} assessments`}
                </td>
              </tr>
            )}
          </PanelTable>
        }

      </PanelBody>
    </PanelWrapper>
  );
};

export default Wrapper