import clsx from "clsx";
import React from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./styles.module.scss";

type SortingItemProps = {
  data: {
    complete: boolean;
    currentScore: number;
    id: number;
    name: string;
    code: string;
    started: boolean;
  }[];
  activePractice?: {
    code: string;
    id: number;
    name: string;
  } | null;
};

type QuestionParams = {
  assessmentId: string;
  practiceCode: string;
};

const SortingItem: React.FunctionComponent<SortingItemProps> = ({
  data,
  activePractice,
}) => {
  const history = useHistory();
  const {
    params: { assessmentId, practiceCode },
  } = useRouteMatch<QuestionParams>();
  return (
    <ul className={styles.perspective}>
      {data &&
        data.map((i) => {
          const className = clsx(
            i.name === activePractice?.name && styles["current"],
            !i.complete && i.started && styles["inProgress"],
            i.complete && styles["complete"]
          );
          return (
            <li key={i.id} className={className}>
              <h3
                onClick={() =>
                  history.push(
                    `/itil/assessments/${assessmentId}/questions/${i.code}/`
                  )
                }
              >
                {i.name}
              </h3>
              <p>Current score: {i.currentScore}</p>
              <p>
                {!i.complete && !i.started && "To do"}
                {!i.complete && i.started && "In progress"}
                {i.complete && "Complete"}
              </p>
            </li>
          );
        })}

      {/* <li className={className}>
        <h3>Continual improvement</h3>
        <p>Current score: 2</p>
        <p>Complete</p>
      </li>
      <li className={styles.inProgress}>
        <h3>Continual improvement</h3>
        <p>Current score: 2</p>
        <p>Complete</p>
      </li> */}
    </ul>
  );
};

export default SortingItem;
