import React from "react";
import clsx from "clsx";

import {
  SelectProps,
  SelectPrimitiveProps,
  SelectPrimitiveOptionsProps,
} from "./types";
import { useController } from "react-hook-form";

import { ReactComponent as ChevronDownForm } from "modules/theme/icons/questions/chevron-down.svg";

import styles from "./styles.module.scss";

export type { OptionType } from "./types";

const SelectPrimitiveOptions = React.memo<SelectPrimitiveOptionsProps>(
  ({ options, readOnly }) => (
    <React.Fragment>
      {options?.map((option, key) => (
        <option disabled={readOnly} key={key} value={option.value}>
          {option.label}
        </option>
      ))}
    </React.Fragment>
  )
);

export const SelectPrimitive = React.memo(
  React.forwardRef<HTMLSelectElement, SelectPrimitiveProps>(
    (
      {
        autoFocus,
        disabled,
        id,
        invalid,
        name,
        options,
        placeholder,
        readOnly,
        tabIndex,
        value,
        onChange,
        handleOnChange,
        ...props
      },
      ref
    ) => {
      const className = clsx(
        styles["select"],
        disabled && styles["disabled"],
        readOnly && styles["read-only"],
        !disabled && invalid && styles["invalid"],
        !value && styles["placeholder"]
      );
      const handleTabIndex = () => {
        if (disabled) return -1;
        return tabIndex ? tabIndex : 0;
      };
      const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (handleOnChange) handleOnChange();
      };
      return (
        <div className={className}>
          <select
            autoFocus={!disabled && autoFocus}
            disabled={disabled}
            id={id || name}
            name={name}
            tabIndex={handleTabIndex()}
            ref={ref}
            value={value || ""}
            onChange={(evt) => {
              onChange(evt);
              handleChange(evt);
            }}
            {...props}
          >
            {placeholder !== false && (
              <option disabled={readOnly} value="">
                {placeholder ? placeholder : "Please select"}
              </option>
            )}
            <SelectPrimitiveOptions options={options} readOnly={readOnly} />
          </select>
          <ChevronDownForm />
        </div>
      );
    }
  )
);

const Select: React.FunctionComponent<SelectProps> = ({
  control,
  id,
  name,
  ...props
}) => {
  const {
    field,
    meta: { invalid },
  } = useController({
    name,
    control,
  });
  return <SelectPrimitive id={id} invalid={invalid} {...props} {...field} />;
};

export default Select;
