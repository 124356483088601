import React from "react";
import clsx from "clsx";

import { useRouteMatch } from "react-router-dom";

import PerspectiveHeader from "./PerspectiveHeader";
import PerspectiveItem from "./PerspectiveItem";

import styles from "./styles.module.scss";

type questiosnParam = {
  assessmentId: string;
  perspectiveCode: string;
  threadCode: string;
};

export type Item = {
  answered: boolean;
  descoped: boolean;
  id: number;
  name: string;
  started: boolean;
};

type PerspectiveProps = {
  open: boolean;
  onClick: () => void;
  threads: Item[];
  answeredThreads: number;
  currentScore: number;
  perspective: string;
  totalThreads: number;
  currentThread: {
    id: number;
    code: string;
    name: string;
  };
  activePerspective: number;
  perspectiveId: number;
  handleNavigation: (perspective: string, thread: string) => void;
};

const Perspective: React.FunctionComponent<PerspectiveProps> = ({
  open,
  onClick,
  answeredThreads,
  currentScore,
  perspective,
  threads,
  totalThreads,
  currentThread,
  activePerspective,
  perspectiveId,
  handleNavigation,
}) => {
  const {
    params: { threadCode },
  } = useRouteMatch<questiosnParam>();
  const discopedNumber = threads.filter((t) => t.descoped === true);
  const totalThreadsNumber = totalThreads - discopedNumber.length;
  const isComplete = totalThreadsNumber === answeredThreads;
  const isIncomplete = answeredThreads > 0 && answeredThreads !== totalThreads;
  const hasStarted = threads.filter((t) => !t.descoped && t.started).length;

  const className = clsx(
    styles["progress-section"],
    open && styles["open"],
    !open && styles["closed"],
    open && styles["selected"],
    currentThread && currentThread.code === threadCode && styles["selected"],
    isIncomplete && styles["incomplete"],
    isComplete && styles["complete"],
    hasStarted && styles["incomplete"]
  );
  return (
    <li className={className}>
      <PerspectiveHeader
        answered={answeredThreads}
        open={open}
        onClick={onClick}
        perspective={perspective}
        total={totalThreadsNumber}
        currentScore={currentScore}
      />
      {threads && open && (
        <ul>
          {threads.map((thread) => (
            <PerspectiveItem
              key={thread.id}
              item={thread}
              perspective={perspective}
              onClick={handleNavigation}
              currentThread={currentThread}
              isSelected={activePerspective === perspectiveId}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default React.memo(Perspective);
