/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { api, API } from "modules/api";

import { allPractices } from "routes/assessments/Scope/Itil/constants";
import {
  AssessmentType,
  ProgressType,
  LevelAnswerType,
  QuestionsOptionsType,
  StateType,
  BaseAnswerType,
  AnswerSetValueType,
  MaturityQuestionType,
  MatOptionType,
  MatAnswerType,
  MatOptionAnswerId
} from "./types";

import { GetMaturitiesResponse, MaturityType } from "modules/api/endpoints/maturitiesGet";
import { CapabilityType, GetCapabilitiesResponse } from "modules/api/endpoints/capabilitiesGet";
import { CapabilityQuestionType, CapabilityQuestionGetRequest, CapabilityQuestionResponse, MaturityQuestionResponse } from "modules/api/endpoints/questionsCapabilityGet";

import Template from "./Template";
import UseLockAssessment from "../hooks/useLockAssessment";


type AnswerType = {
  level: number;
  answer: string;
  enabled: boolean;
  label: string;
  readonly: boolean;
  id: number;
  questionId: number;
};

type QuestionParams = {
  assessmentId: string;
  practiceCode: string;
};

type PracticeType =
  | {
    name: string;
    id: number;
    code: string;
  }
  | undefined;


const QuestionsItilScope: React.FunctionComponent = () => {
  const history = useHistory();

  const {
    params: { assessmentId, practiceCode },
  } = useRouteMatch<QuestionParams>();

  const [isLoading, setIsLoading] = useState(true);
  const [assessment, setAssessment] = useState<AssessmentType>({} as AssessmentType)
  const [currentCapability, setCurrentCapability] = useState<CapabilityType | null>(null)
  const [currentQuestions, setCurrentQuestions] = useState<CapabilityQuestionType[]>([])
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);

  const [currentMaturity, setCurrentMaturity] = useState<MaturityType | null>(null)
  const [currMatQuestions, setCurrMatQuestions] = useState<MaturityQuestionType[]>([])
  const [currPracMatQuestion, setCurrPracMatQuestion] = useState<MaturityQuestionType>({} as MaturityQuestionType)
  const [maturityOptions, setMaturityOptions] = useState<MatOptionType[]>([])

  const [levelTwoQuestions, setLevelTwoQuestions] = useState<QuestionsOptionsType | null>(null);
  const [levelThreeQuestions, setLevelThreeQuestions] = useState<QuestionsOptionsType | null>(null);
  const [levelFourQuestions, setLevelFourQuestions] = useState<QuestionsOptionsType | null>(null);
  const [levelFiveQuestions, setLevelFiveQuestions] = useState<QuestionsOptionsType | null>(null);

  const [answers, setAnswers] = useState<BaseAnswerType[]>([]);
  const [answersIds, setAnswersIds] = useState<BaseAnswerType[]>([]);
  const [postAnswers, setPostAnswers] = useState<BaseAnswerType[]>([]);

  const [postMaturityAnswer, setPostMaturityAnswer] = useState<MatAnswerType[]>([]);

  const [formStatus, setFormStatus] = useState("New");

  const [sendRequest, setSendRequest] = useState(false);
  const [sendMaturityRequest, setSendMaturityRequest] = useState(false);
  const [answerMaturityId, setAnswerMaturityId] = useState<MatOptionAnswerId[]>([]);

  const [formState, setFormState] = useState<StateType>({} as StateType);
  const [questionType, setQuestionType] = useState('');

  const [isFirstAnswerSubmitted, setIsFirstAnswerSubmitted] = useState(false);

  const [currentPractice, setCurrentPractice] = useState<PracticeType | null>(null);

  const [paginationButtonLabel, setPaginationButtonLabel] = useState("Next Practice")

  const [progress, setProgress] = useState<ProgressType | null>(null);

  const [capData, setCapData] = useState<CapabilityType[]>([])
  const [matData, setMatData] = useState<CapabilityType[]>([])

  const [noteState, setNoteState] = useState<string | null>(null)

  useEffect(() => {
    return () => {
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    }
  }, [])

  useEffect(() => {
    const updateCall = async () => {
      if (sendRequest) {
        try {
          if (formStatus === "New") {
            setIsFirstAnswerSubmitted(true);
            const response = await api(API.POST_CAPABILITY_ANSWER(postAnswers, assessmentId))
              .then((res: CapabilityQuestionResponse) => res.data);

            if (response) {
              setAnswersIds(response.answers)
              setFormStatus("Update")
              setProgress(() => response.progress)
              setIsFirstAnswerSubmitted(false);
            }
            return;
          }

          if (formStatus === "Update") {
            const updatedIds = postAnswers.map((p) => {
              const { id } = answersIds.filter(
                (a) => a.questionId === p.questionId
              )[0];

              return {
                ...p,
                id,
              };
            });

            const response = await api(API.POST_CAPABILITY_ANSWER(updatedIds, assessmentId))
              .then((res: CapabilityQuestionResponse) => res.data);

            setProgress(() => response.progress)
            return;
          }

          if (formStatus === "Review") {
            const response = await api(API.POST_CAPABILITY_ANSWER(postAnswers, assessmentId))
              .then((res: CapabilityQuestionResponse) => res.data);
            setProgress(() => response.progress)
            return;

          }

        } catch (error) { }

      }
    }

    updateCall()
    setSendRequest(() => false);

  }, [sendRequest])


  useEffect(() => {
    const updateCall = async () => {
      if (sendMaturityRequest) {
        try {

          if (formStatus === "New") {
            setIsFirstAnswerSubmitted(true);
            const response = await api(API.POST_MATURITY_ANSWER(postMaturityAnswer, assessmentId))
              .then((res: MaturityQuestionResponse) => res.data);

            if (response) {
              setAnswerMaturityId(response.answers)
              setFormStatus("Update")
              setProgress(() => response.progress)
              setIsFirstAnswerSubmitted(false);
            }
            return;
          }

          if (formStatus === "Update") {
            const updatedIds = postMaturityAnswer.map((p) => {
              const { id } = answerMaturityId[0]

              return {
                ...p,
                id,
              };
            });

            const response = await api(API.POST_MATURITY_ANSWER(updatedIds, assessmentId))
              .then((res: MaturityQuestionResponse) => res.data);

            setProgress(() => response.progress)
            return;
          }

          if (formStatus === "Review") {
            const response = await api(API.POST_MATURITY_ANSWER(postMaturityAnswer, assessmentId))
              .then((res: CapabilityQuestionResponse) => res.data);
            setProgress(() => response.progress)
            return;

          }
        } catch (error) { }

      }
    }

    updateCall()
    setSendMaturityRequest(() => false);

  }, [sendMaturityRequest])


  useEffect(() => {
    setIsLoading(true)
    setFormStatus("New")
    setPostAnswers([])
    setNoteState(null)
    setCurrentCapability(null)
    setCurrentMaturity(null)

    const fetchData = async () => {
      try {
        const [assessmentData, capabilities, maturities, progressData] = await Promise.all([
          api(API.GET_ASSESSMENT(assessmentId)).then((res) => res.data),
          api(API.GET_CAPABILITIES()).then((res: GetCapabilitiesResponse) => res.data),
          api(API.GET_MATURITIES()).then((res: GetMaturitiesResponse) => res.data),
          api(API.GET_ASSESSMENT_PROGRESS(assessmentId)).then((res) => res.data),
        ]);

        const foundCapability = capabilities.find(cp => cp.code === practiceCode);
        const foundMaturity = maturities.find(mt => mt.code === practiceCode);


        setCapData(capabilities)
        setMatData(maturities)

        setIsAssessmentCompleted(() => {
          return assessmentData.status === "Completed";
        })

        setProgress(() => {
          const hasMaturity =
            assessmentData.selectedScope === "Comprehensive" || assessmentData.selectedScope === "Maturity";
          return {
            capabilityProgress: progressData.capabilityProgress,
            maturityProgress: hasMaturity ? progressData.maturityProgress : []
          }
        })

        if (foundCapability) {
          const capabilityResponse = await api(
            API.GET_CAPABILITY_QUESTIONS(
              assessmentId, { capabilities: foundCapability.id }
            )
          ).then((res: CapabilityQuestionGetRequest) => res.data)

          setCurrentCapability(foundCapability)
          setCurrentQuestions(capabilityResponse)
          setQuestionType("Capability")
          setCurrentPractice(foundCapability)
          setCurrentMaturity(null)

          setPaginationButtonLabel(() => {
            const currCapabilities = progressData.capabilityProgress;
            const lastPracticeItem = currCapabilities[currCapabilities.length - 1]

            if (lastPracticeItem.code === practiceCode) {
              if (assessmentData.selectedScope === "Capability") {
                if (assessmentData.status === "Completed") {
                  return "View Result";
                }
                return "View Summary";
              }
              return "Assess Maturity Components"
            }
            return "Next Practice"
          })
        }

        if (foundMaturity) {
          const maturityResponse = await api(
            API.GET_MATURITY_QUESTIONS(
              assessmentId
            )
          ).then(res => res.data)
          setCurrentMaturity(foundMaturity)
          setCurrMatQuestions(maturityResponse)
          setCurrentPractice(foundMaturity)
          setCurrentCapability(null)

          setQuestionType("Maturity")
          setPaginationButtonLabel(() => {
            const currMaturities = progressData.maturityProgress;
            const lastMaturityItem = currMaturities[currMaturities.length - 1]
            if (lastMaturityItem.code === practiceCode) {
              if (assessmentData.status === "Completed") {
                return "View Result";
              }
              return "View Summary";
            }
            return "Next component"
          })
        }

        setAssessment(assessmentData)

      } catch (error) {
        setIsLoading(false)
      }
    }

    fetchData();
  }, [practiceCode]);

  useEffect(() => {
    if (currentQuestions.length === 0) return;

    const assmtFormState = currentQuestions.reduce((o, key) => {
      return ({ ...o, [key.code]: key.answer ? handleAnswerConvertion(key.answer.selection) : '' })
    }, {})

    setLevelTwoQuestions(() => {
      const questions = currentQuestions.filter(q => q.level === 2);
      return {
        control: {
          isEnabled: true,
          hasAnyAnsweredItem: questions.some(q => q.answer && q.answer.selection !== ""),
          allQuestionsAnswered: questions.every(q => q.answer && q.answer.selection !== "" && q.answer.selection != null)
        },
        questions,
        answers: getAnswers(questions),
      }
    })

    setLevelThreeQuestions(() => {
      const isEnabled = currentQuestions.filter(q => q.level === 2).every(q => q.answer && q.answer.selection === "Fully");
      const questions = currentQuestions.filter(q => q.level === 3);
      return {
        control: {
          isEnabled,
          hasAnyAnsweredItem: questions.some(q => q.answer && q.answer.selection !== ""),
          allQuestionsAnswered: questions.every(q => q.answer && q.answer.selection !== "" && q.answer.selection != null)
        },
        questions,
        answers: getAnswers(questions),
      }
    })

    setLevelFourQuestions(() => {
      const isEnabled = currentQuestions.filter(q => q.level === 3).every(q => q.answer && q.answer.selection === "Fully");
      const questions = currentQuestions.filter(q => q.level === 4);
      return {
        control: {
          isEnabled,
          hasAnyAnsweredItem: questions.some(q => q.answer && q.answer.selection !== ""),
          allQuestionsAnswered: questions.every(q => q.answer && q.answer.selection !== "" && q.answer.selection != null)
        },
        questions,
        answers: getAnswers(questions),
      }
    })

    setLevelFiveQuestions(() => {
      const isEnabled = currentQuestions.filter(q => q.level === 4).every(q => q.answer && q.answer.selection === "Fully");
      const questions = currentQuestions.filter(q => q.level === 5);
      return {
        control: {
          isEnabled,
          hasAnyAnsweredItem: questions.some(q => q.answer && q.answer.selection !== ""),
          allQuestionsAnswered: questions.every(q => q.answer && q.answer.selection !== "" && q.answer.selection != null)
        },
        questions,
        answers: getAnswers(questions),
      }
    })

    setAnswers(() => {
      return currentQuestions.map(q => {
        if (q.answer) return q.answer;
        return {
          id: 0,
          questionId: q.id,
          score: null,
          selection: '',
        }
      });
    })

    setFormStatus(() => {
      const hasAnyAnsweredQuestions = currentQuestions.some(q => q.answer?.id);
      if (hasAnyAnsweredQuestions) return "Review";
      return "New"
    })

    const getNotes = async () => {
      if (currentCapability) {
        try {
          const response = await api(API.GET_CAPABILITY_NOTE(Number(assessmentId), currentCapability.id)).then(res => res.data)
          if (response) {
            setFormState(() => {
              return {
                ...assmtFormState,
                assessmentNote: response.body
              }
            })
          }

        } catch (error) {
          setFormState(() => {
            return {
              ...assmtFormState,
              assessmentNote: ''
            }
          })
        }
      }
      setIsLoading(false)
    }

    getNotes()

  }, [currentQuestions]);

  useEffect(() => {
    if (currMatQuestions.length === 0) return;

    const foundCurrQuestion = currMatQuestions.find(q => q.id === currentMaturity?.id);

    if (foundCurrQuestion) {
      setCurrPracMatQuestion(foundCurrQuestion)
      setMaturityOptions(() => {
        return foundCurrQuestion.options.map(o => {
          return {
            label: o.name,
            value: o.id.toString(),
            statement: o.statement,
          }
        })
      })

      setFormState(() => {
        const { answer } = foundCurrQuestion;
        const answeredOption = answer ? answer.optionId.toString() : ""
        return {
          maturity: answeredOption
        }
      })
    }

    setFormStatus(() => {
      const isQuestionsAnswered = currMatQuestions.find(q => q.code === practiceCode && q.answer != null);
      if (isQuestionsAnswered) return "Review";
      return "New"
    })

    const getNotes = async () => {
      if (currentMaturity) {
        try {
          const response = await api(API.GET_MATURITY_NOTE(Number(assessmentId), currentMaturity.id)).then(res => res.data)
          if (response)
            setFormState(() => {
              const answeredOption = foundCurrQuestion?.answer ? foundCurrQuestion.answer.optionId.toString() : ""
              return {
                maturity: answeredOption,
                assessmentNote: response.body
              }
            })
        } catch (error) {
          setFormState(() => {
            const answeredOption = foundCurrQuestion?.answer ? foundCurrQuestion.answer.optionId.toString() : ""
            return {
              maturity: answeredOption,
              assessmentNote: ""
            }
          })
        }
      }
      setIsLoading(false)
    }

    getNotes()
  }, [currMatQuestions])

  useEffect(() => {
    if (noteState == null) return;

    const basePayLoad = {
      assessmentId: assessment.id,
      body: noteState,
    }

    const timer = setTimeout(
      async () => {
        try {
          if (currentMaturity) {
            const payload = {
              ...basePayLoad,
              maturityId: currentMaturity.id
            }
            await api(
              API.POST_MATURITY_NOTE(payload)
            ).then(res => res.data)

          }
          if (currentCapability) {
            const payload = {
              ...basePayLoad,
              capabilityId: currentCapability.id
            }
            await api(
              API.POST_CAPABILITY_NOTE(payload)
            ).then(res => res.data)

          }

        } catch (error) {
          console.log(error)
        }

      },
      700
    );

    return () => {
      clearTimeout(timer);
    };
  }, [noteState]);

  useEffect(() => {
    if (!progress) return;
    const { maturityProgress, capabilityProgress } = progress;

    const allMaturitiesCompleted = maturityProgress.length > 0 ? maturityProgress.every(m => m.complete && m.started) : null;
    const allCapabilitiesCompleted = capabilityProgress.length > 0 ? capabilityProgress.every(c => c.complete && c.started) : null;

    if (assessment.selectedScope === "Capability") {
      if (allCapabilitiesCompleted) {
        setPaginationButtonLabel("View Summary")
      }
      return;
    }

    if (allMaturitiesCompleted && allCapabilitiesCompleted) setPaginationButtonLabel("View Summary")

  }, [progress])

  const handleAnswerConvertion = (answer: string | null) => {
    switch (answer) {
      case "Fully":
        return "1"
      case "Rarely":
        return "2"
      case "1":
        return "Fully"
      case "2":
        return "Rarely"
      default:
        return null
    }
  }

  const getAnswers = (questions: CapabilityQuestionType[]) => {
    return questions.map(q => {
      if (q.answer) {
        return q.answer
      }
      return {
        id: 0,
        questionId: q.id,
        selection: "N/A",
        score: null
      }
    })
  }

  const handleAnswersSetValues = (isLevelEnabled: boolean, questions: AnswerSetValueType[]) => {
    return questions.map(q => {
      const isEnabled = isLevelEnabled ? null : "N/A";
      const fully = "1";
      const rarely = "2";

      const idFound = answers.find(i => i.questionId === q.questionsId);
      const isEnabledAndHasSelection = isLevelEnabled && q.selection;

      return {
        id: idFound && idFound.id !== 0 ? idFound.id : q.id,
        questionId: q.questionsId,
        selection: isLevelEnabled ? handleAnswerConvertion(q.selection) : isEnabled,
        score: isEnabledAndHasSelection && q.selection === fully
          ? 1
          : isEnabledAndHasSelection && q.selection === rarely
            ? 0
            : null
      }
    })
  }


  const handleSetValues = (values: StateType, updatedAnswers: BaseAnswerType[]) => {

    const levelTwoAnswers = levelTwoQuestions ? levelTwoQuestions.questions.map(q => {
      const id = q.answer ? q.answer.id : 0;
      return { id, questionsId: q.id, code: q.code, selection: values[q.code] }
    }) : []

    const levelThreeAnswers = levelThreeQuestions ? levelThreeQuestions.questions.map(q => {
      const id = q.answer ? q.answer.id : 0;
      return { id, questionsId: q.id, code: q.code, selection: values[q.code] }
    }) : []

    const levelFourAnswers = levelFourQuestions ? levelFourQuestions.questions.map(q => {
      const id = q.answer ? q.answer.id : 0;
      return { id, questionsId: q.id, code: q.code, selection: values[q.code] }
    }) : []

    const levelFiveAnswers = levelFiveQuestions ? levelFiveQuestions.questions.map(q => {
      const id = q.answer ? q.answer.id : 0;
      return { id, questionsId: q.id, code: q.code, selection: values[q.code] }
    }) : []


    const isLevelTreeEnabled = levelTwoAnswers.every(q => q.selection === "1");
    const isLevelFourEnabled = levelThreeAnswers.every(q => q.selection === "1");
    const isLevelFiveEnabled = levelFourAnswers.every(q => q.selection === "1");

    const lv2Answers = handleAnswersSetValues(true, levelTwoAnswers);
    const lv3Answers = handleAnswersSetValues(isLevelTreeEnabled, levelThreeAnswers);
    const lv4Answers = handleAnswersSetValues(isLevelTreeEnabled && isLevelFourEnabled, levelFourAnswers);
    const lv5Answers = handleAnswersSetValues(isLevelTreeEnabled && isLevelFourEnabled && isLevelFiveEnabled, levelFiveAnswers);


    setLevelTwoQuestions((prevState) => {
      if (prevState) {
        return {
          questions: prevState.questions,
          control: {
            isEnabled: true,
            hasAnyAnsweredItem: levelTwoAnswers.some(i => i.selection != null && i.selection !== ""),
            allQuestionsAnswered: levelTwoAnswers.every(i => i.selection != null && i.selection !== "")
          },
          answers: lv2Answers
        }
      }
      return null
    })

    setLevelThreeQuestions((prevState) => {
      if (prevState) {
        return {
          questions: prevState.questions,
          control: {
            isEnabled: isLevelTreeEnabled,
            hasAnyAnsweredItem: levelThreeAnswers.some(q => q.selection != null && q.selection !== ""),
            allQuestionsAnswered: levelThreeAnswers.every(q => q.selection != null && q.selection !== "")
          },
          answers: lv3Answers,
        }
      }
      return null
    })

    setLevelFourQuestions((prevState) => {
      if (prevState) {
        return {
          questions: prevState.questions,
          control: {
            isEnabled: isLevelTreeEnabled && isLevelFourEnabled,
            hasAnyAnsweredItem: levelFourAnswers.some(q => q.selection != null && q.selection !== ""),
            allQuestionsAnswered: levelFourAnswers.every(q => q.selection != null && q.selection !== "")
          },
          answers: lv4Answers,
        }
      }
      return null
    })

    setLevelFiveQuestions((prevState) => {
      if (prevState) {
        return {
          questions: prevState.questions,
          control: {
            isEnabled: isLevelTreeEnabled && isLevelFourEnabled && isLevelFiveEnabled,
            hasAnyAnsweredItem: levelFiveAnswers.some(q => q.selection != null && q.selection !== ""),
            allQuestionsAnswered: levelFiveAnswers.every(q => q.selection != null && q.selection !== "")
          },
          answers: lv5Answers,
        }
      }
      return null
    })

    setAnswers([
      ...lv2Answers,
      ...lv3Answers,
      ...lv4Answers,
      ...lv5Answers,
    ])

    setPostAnswers([
      ...lv2Answers,
      ...lv3Answers,
      ...lv4Answers,
      ...lv5Answers,
    ])

    setSendRequest(true);
  };

  const handleSetMaturityValues = (values: StateType) => {

    setPostMaturityAnswer(() => {
      const { id, answer } = currPracMatQuestion
      return [{
        id: answer ? answer.id : 0,
        questionId: id,
        optionId: Number(values.maturity)
      }]
    })

    setSendMaturityRequest(true)

  }

  const handlePagination = () => {

    if (questionType === "Capability" || questionType === "Maturity") {

      if (paginationButtonLabel === "View Summary") {
        history.push(`/itil/assessments/${assessmentId}/summary`);
        return;
      }
    }

    if (questionType === "Capability") {
      const currPracticeIdx = progress?.capabilityProgress.findIndex(i => i.code === currentPractice?.code);
      const nextPractiveIdx = currPracticeIdx != null ? currPracticeIdx + 1 : null;

      if (currPracticeIdx != null && nextPractiveIdx != null) {
        const nextPractice = progress && progress.capabilityProgress.length > 0 ? progress.capabilityProgress[nextPractiveIdx] : null
        const hasMaturities = progress && progress.maturityProgress.length > 0;

        if (nextPractice) {
          history.push(`/itil/assessments/${assessmentId}/questions/${nextPractice.code}/`);
          return
        }

        if (hasMaturities) {
          const nextComponent = progress && progress.maturityProgress.length > 0 ? progress.maturityProgress[0] : null;
          if (nextComponent) history.push(`/itil/assessments/${assessmentId}/questions/${nextComponent.code}/`);
          return;
        }

        if (assessment.status === "Completed") {
          history.push(`/assessments/${assessmentId}/result/itil`);
          return;
        }

        history.push(`/itil/assessments/${assessmentId}/summary`);
      }
      return;
    }

    if (questionType === "Maturity") {
      const currPracticeIdx = progress?.maturityProgress?.findIndex(i => i.code === currentPractice?.code);
      const nextPractiveIdx = currPracticeIdx != null ? currPracticeIdx + 1 : null;

      if (currPracticeIdx != null && nextPractiveIdx != null && progress) {
        const nextPractice = progress?.maturityProgress.length > 0 ? progress?.maturityProgress[nextPractiveIdx] : null
        if (nextPractice) {
          history.push(`/itil/assessments/${assessmentId}/questions/${nextPractice.code}/`);
          return;
        }

        if (assessment.status === "Completed") {
          history.push(`/assessments/${assessmentId}/result/itil`);
          return;
        }

        history.push(`/itil/assessments/${assessmentId}/summary`);
      }
    }
  }

  const handleSaveAndExit = () => {
    history.push(`/`)
  }

  const handleNotes = (note: string | null) => {
    setNoteState((oldNote) => {
      if (oldNote === note) {
        return null
      }
      return note
    })
  }

  const handleReturnToResults = () => {
    history.push(`/assessments/${assessmentId}/result/itil`);
  };

  return (
    <Template
      assessment={assessment}
      formState={formState}
      currentPractice={currentPractice}
      currentQuestions={currentQuestions}
      levelTwoQuestions={levelTwoQuestions}
      levelThreeQuestions={levelThreeQuestions}
      levelFourQuestions={levelFourQuestions}
      levelFiveQuestions={levelFiveQuestions}
      handleSetValues={handleSetValues}
      handleSetMaturityValues={handleSetMaturityValues}
      maturityOptions={maturityOptions}
      answers={answersIds}
      progress={progress}
      isLoading={isLoading}
      questionType={questionType}
      handlePagination={handlePagination}
      handleSaveAndExit={handleSaveAndExit}
      practiceCode={practiceCode}
      paginationButtonLabel={paginationButtonLabel}
      currentMaturity={currentMaturity}
      currentMaturityPractice={currPracMatQuestion}
      capData={capData}
      matData={matData}
      isFirstAnswerSubmitted={isFirstAnswerSubmitted}
      handleNotes={handleNotes}
      isAssessmentCompleted={isAssessmentCompleted}
      handleReturnToResults={handleReturnToResults}
    />
  );
};

export default QuestionsItilScope;
