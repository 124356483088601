import React from "react";
import styles from "./styles.module.scss";

import { Organization } from "modules/api/endpoints/organizationGet";

type ChartsWrapperProps = {
  children?: React.ReactNode;
  organization?: Organization;
};

const ChartsContainerHeader: React.FunctionComponent<ChartsWrapperProps> = ({
  organization,
}) => {
  return (
    <div className={styles.ChartsHeader}>
      <h3>{organization?.name}</h3>
      <ul>
        <li>
          Location: <span>{organization?.country.name}</span>
        </li>
        <li>
          Business Unit: <span>{organization?.businessUnit}</span>
        </li>
        <li>
          Industry: <span>{organization?.industry.name}</span>
        </li>
        <li>
          Number of employees: <span>{organization?.size.name}</span>
        </li>
      </ul>
    </div>
  );
};

export default ChartsContainerHeader;
