export enum InputMode {
  DECIMAL = "decimal",
  EMAIL = "email",
  NONE = "none",
  NUMERIC = "numeric",
  SEARCH = "search",
  TEL = "tel",
  TEXT = "text",
  URL = "url",
}

export enum InputType {
  DATE = "date",
  EMAIL = "email",
  MONTH = "month",
  NUMBER = "number",
  PASSWORD = "password",
  TEL = "tel",
  TEXT = "text",
  TEXTAREA = "textarea",
}
