import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";

import { Control } from "react-hook-form";

import styles from "./styles.module.scss";

import { OptionType } from "./types";

interface IRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  control: Control;
  name: string;
  options: OptionType[];
  label?: string;
  sublabel?: string;
  required?: boolean;
  readOnly?: boolean;
  isInvalid?: boolean;
}

const RadioInput = React.forwardRef<HTMLInputElement, IRadioProps>(
  ({ name, id, label, options, disabled, isInvalid, ...rest }, ref) => {
    const classNames = clsx(
      styles["radio-buttons"],
      disabled && styles["read-only"],
      isInvalid && styles["is-invalid"]
    );

    return (
      <div id={id} className={classNames}>
        {options &&
          options.map((o) => (
            <label
              className={styles.label}
              key={o.value}
              htmlFor={name + o.value}
            >
              <input
                {...rest}
                type="radio"
                ref={ref}
                tabIndex={0}
                id={name + o.value}
                name={name}
                value={o.value}
                disabled={disabled}
              />
              <div>
                <span></span>
                {o.label}
              </div>
            </label>
          ))}
      </div>
    );
  }
);

export default RadioInput;
