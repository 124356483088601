import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { enableES5 } from "immer";
import App from "modules/app";
import reportWebVitals from "./reportWebVitals";

import "modules/theme/sass/_index.scss";

enableES5();

document.addEventListener(
  "touchstart",
  function () {
    return;
  },
  true
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
