import React, { useEffect, useState, useRef } from "react";

import { useIdleTimer } from "react-idle-timer";
import { useModal } from "modules/hooks/modal";
import { useAuth } from "modules/hooks/auth";

import Template from "./Template";

const Route: React.FunctionComponent = ({ children }) => {
  const [isTimeout, setIsTimeout] = useState(false);
  const dialog = useModal();
  const { logout } = useAuth();

  useEffect(() => {
    if (!isTimeout) return;

    const timer = setTimeout(() => {
      window.location.assign(logout());
    }, 60 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isTimeout]);

  const handleOnIdle = async () => {
    setIsTimeout(true);

    await dialog({
      type: "info",
      title: "You have been idle for a while!",
      description: "You will be logged out soon",
      buttonLabel: "Keep me signed in",
      catchOnCancel: true,
      displayCancelButton: true,
    })
      .catch(() => null)
      .finally(() => {
        setIsTimeout(false);
        reset();
      });
  };

  const { reset } = useIdleTimer({
    timeout: 60 * 1000 * 25,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <Template>{children}</Template>;
};

export default Route;
