import React, { useEffect } from "react";
import { Provider } from "react-redux";

import AppProvider from "modules/hooks/index";
import { setupPlatform } from "./helpers";
import store from "modules/redux/store";
import Router from "modules/router";

import PlatformContext, {
  PlatformType,
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
} from "modules/platform/context";

const platform: PlatformType = {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
};

export default function App(): JSX.Element {
  useEffect(() => {
    setupPlatform(platform);
  }, []);

  return (
    <Provider store={store}>
      <PlatformContext.Provider value={platform}>
        <AppProvider>
          <Router />
        </AppProvider>
      </PlatformContext.Provider>
    </Provider>
  );
}
