import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as XLSX from "xlsx";
import { api, API } from "modules/api";

import { AssessmentGetResponse } from "modules/api/endpoints/assessmentGet";
import { Organization } from "modules/api/endpoints/organizationGet";
import { ChartData } from "components/Charts/Radar";
import {
  ResultType,
  AssessmentResultGetResponse,
  ImprovmentsItem,
  NotesType,
} from "modules/api/endpoints/assessmentResultGet";

import { ErrorMessagesType, resultsErrorMessages } from "modules/messages";
import { useModal } from "modules/hooks/modal";

import { GetHelpersResponse } from "modules/api/endpoints/helpersGet";

import Template from "./Template";
import { Item } from "routes/questions/Template";
import { getErrorMessages } from "modules/messages/helpers";

type ResultsParams = {
  assessmentId: string;
};

enum AssessmentStatus {
  ACTIVE = "Active",
  COMPLETED = "Completed",
}

type AssessmentRoute = {
  capabilityCode: string;
};

type PerspectiveAndScoreType = {
  perspective: string;
  perspectiveId: string;
  score: string;
};

export type BenchmarkDataType = {
  message: string;
  perspectivesAndScores: PerspectiveAndScoreType[];
};

export type TableItilType = {
  id: number;
  name: string;
  score: number;
};

const AssessmentResult: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState<ResultType>({} as ResultType);
  const [organization, setOrganization] = useState<Organization>(
    {} as Organization
  );
  const [isEndorsed, setIsEndorsed] = useState(false);
  const [assessmentScope, setAssessmentScope] = useState("");
  const [assessmentRoute, setAssessmentRoute] = useState("");
  const [assessmentName, setAssessmentName] = useState("");

  const [maturityResult, setMaturityResult] = useState<ChartData | null>(null);
  const [capabilityResult, setCapabilityResult] = useState<ChartData | null>(
    null
  );

  const [improvementAreas, setImprovementAreas] = useState<ImprovmentsItem[]>(
    []
  );

  const [maturityNotes, setMaturitiesNotes] = useState<NotesType | null>(null);

  const [tableMaturityResult, setTableMaturityResult] = useState<
    TableItilType[]
  >([]);

  const [tablePrimaryCapabilities, setTablePrimaryCapabilities] = useState<
    TableItilType[]
  >([]);

  const [tableSupportingCapabilities, setTableSupportingCapabilities] =
    useState<TableItilType[]>([]);

  const [activeTab, setActiveTab] = useState("Results");

  const errorConfirmation = useModal();

  const {
    params: { assessmentId },
  } = useRouteMatch<ResultsParams>();

  const history = useHistory();
  useEffect(() => {
    let isCancelled = false;
    if (!isAssessmentValid(assessmentId)) {
      return history.push("/");
    }
    const getAssessmentResult = async () => {
      try {
        const assessment = await api(API.GET_ASSESSMENT(assessmentId)).then(
          (assessmentResponse: AssessmentGetResponse) => assessmentResponse.data
        );

        if (assessment.status !== AssessmentStatus.COMPLETED && isCancelled)
          return history.push("/");

        const response = await api(
          API.GET_ASSESSMENT_RESULT(assessmentId)
        ).then((assessmentResultResponse) => assessmentResultResponse.data);

        if (response) {
          setOrganization(assessment.organization);
          setAssessmentScope(assessment.selectedScope);
          setResult(response);
          setIsEndorsed(assessment.endorsed);
          setAssessmentName(assessment.name);
          setMaturitiesNotes(response.notes);

          setImprovementAreas(response.areasOfImprovement);

          setMaturityResult(() => {
            const chartLabels = response.maturities.map((m: any) => {
              return m.name;
            });

            const maturityScore = response.maturities.map((m: any) => {
              return m.score;
            });

            const datasets = [
              {
                label: `Maturity results`,
                data: maturityScore,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
              },
            ];

            return {
              labels: chartLabels,
              datasets,
            };
          });

          setCapabilityResult(() => {
            const chartLabels = response.capabilities.map((c: any) => {
              return c.name;
            });

            const capabilityScore = response.capabilities.map((c: any) => {
              return c.score;
            });

            const datasets = [
              {
                label: `Capability results`,
                data: capabilityScore,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
              },
            ];

            return {
              labels: chartLabels,
              datasets,
            };
          });

          setTableMaturityResult(response.maturities);
          setTablePrimaryCapabilities(() => {
            return response.capabilities.filter((c: any) => c.mode === 1);
          });
          setTableSupportingCapabilities(() => {
            return response.capabilities.filter((c: any) => c.mode === 2);
          });
          setAssessmentRoute(assessment.capabilities[0].code);
          setIsLoading(false);
        }
      } catch (error: any) {
        const messages = getErrorMessages(
          resultsErrorMessages,
          error?.response?.status
        );
        await errorConfirmation({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description,
        }).finally(() => history.push("/"));
      }
    };

    getAssessmentResult();
    return () => {
      isCancelled = true;
    };
  }, []);

  const isAssessmentValid = useCallback((assemt: string) => {
    return !!assemt;
  }, []);

  const handleViewFullAssessment = () => {
    history.push(
      `/itil/assessments/${assessmentId}/questions/${assessmentRoute}`
    );
  };
  const primaryCapabilitiesExport = tablePrimaryCapabilities?.map((item) => ({
    Practice: item.name,
    Score: item.score,
  }));
  const supportingCapabilitiesExport = tableSupportingCapabilities?.map(
    (item) => ({
      Practice: item.name,
      Score: item.score,
    })
  );
  const maturityExport = tableMaturityResult?.map((item) => ({
    Components: item.name,
    Score: item.score,
  }));
  const diagnosticExport = improvementAreas?.map((item) => ({
    name: [{ name: item.name }],
    note: [
      {
        value: item.note ? item.note.body : "Not Provided",
      },
    ],
    questions: item.questions.map((question) => ({
      Criterion: question.statement,
      Dimension: question.dimension,
      PSF: question.psf,
    })),
  }));
  const exportToElsx = () => {
    // Sheet 1
    const wf = XLSX.utils.json_to_sheet([]);
    // Titles
    XLSX.utils.sheet_add_json(
      wf,
      [{ overalldScores: "Primary Capabilities results" }],
      {
        skipHeader: true,
        origin: "A1",
      }
    );
    XLSX.utils.sheet_add_json(
      wf,
      [{ threadScores: "Supporting Capabilities results" }],
      {
        skipHeader: true,
        origin: "D1",
      }
    );
    if (maturityExport.length > 0) {
      XLSX.utils.sheet_add_json(wf, [{ maturityScores: "Maturity results" }], {
        skipHeader: true,
        origin: "G1",
      });
    }
    if (result.maturityScore > 0) {
      XLSX.utils.sheet_add_json(wf, [{ "Final Score": result.maturityScore }], {
        origin: "J1",
      });
    }
    // Place data on the sheet
    XLSX.utils.sheet_add_json(wf, primaryCapabilitiesExport, {
      origin: "A2",
    });
    XLSX.utils.sheet_add_json(wf, supportingCapabilitiesExport, {
      origin: "D2",
    });
    if (maturityExport.length > 0) {
      XLSX.utils.sheet_add_json(wf, maturityExport, { origin: "G2" });
    }
    /* Merge Cells */
    const mergeO = { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } };
    const mergeT = { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } };
    const mergeM = { s: { r: 0, c: 6 }, e: { r: 0, c: 7 } };
    if (!wf["!merges"]) wf["!merges"] = [];
    wf["!merges"].push(mergeO, mergeT, mergeM);

    // Sheet 2
    const ws = XLSX.utils.json_to_sheet([]);
    if (diagnosticExport.length > 0) {
      diagnosticExport.forEach((practice) => {
        XLSX.utils.sheet_add_json(ws, practice.name, {
          origin: -1,
          skipHeader: true,
        });
        XLSX.utils.sheet_add_json(ws, practice.questions, { origin: -1 });
        XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
        XLSX.utils.sheet_add_json(ws, [{ note: "Note" }], {
          origin: -1,
          skipHeader: true,
        });
        XLSX.utils.sheet_add_json(ws, practice.note, {
          origin: -1,
          skipHeader: true,
        });
        XLSX.utils.sheet_add_json(ws, [{}], { origin: -1 });
      });
    }

    // Export data
    const wb = {
      Sheets: { Scores: wf, Areas_Of_Improvement: ws },
      SheetNames: ["Scores", "Areas_Of_Improvement"],
    };
    const date = new Date();
    const fnDate = `${date.getFullYear()}${
      date.getMonth() + 1
    }${date.getDate()}`;
    const fnName = assessmentName.replace(/\s+/g, "_");
    XLSX.writeFile(wb, `${fnDate}_ITIL_${fnName}.xlsx`);
  };
  return (
    <Template
      isLoading={isLoading}
      organization={organization}
      finalScore={result.maturityScore}
      isEndorsed={isEndorsed}
      handleViewFullAssessment={handleViewFullAssessment}
      assessmentName={assessmentName}
      capabilityChart={capabilityResult}
      maturityChart={maturityResult}
      primaryCapabilityTable={tablePrimaryCapabilities}
      supportingCapabilityTable={tableSupportingCapabilities}
      maturityTable={tableMaturityResult}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
      improvementAreas={improvementAreas}
      assessmentScope={assessmentScope}
      notes={maturityNotes ? maturityNotes.maturity : []}
      exportToElsx={exportToElsx}
    />
  );
};

export default AssessmentResult;
