import React, { useRef, useLayoutEffect, useState } from "react";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ReactComponent as DownloadSVG } from "modules/theme/icons/buttons/download.svg";
import { ReactComponent as CertifiedIcon } from "modules/theme/icons/icon-certified-green.svg";
import { ReactComponent as BenchmarkArrow } from "modules/theme/icons/icon-benchmark-arrow.svg";
import { ReactComponent as BenchmarkArrowDown } from "modules/theme/icons/icon-benchmark-arrow-down.svg";
import { exportComponentAsJPEG } from "react-component-export-image";

type RowsType = {
  id?: number;
  name: string;
  items: RowsItem[];
};

type RowsItem = {
  id?: number;
  level: number;
  statement: string;
};

type RowsOverallType = {
  id: number;
  rank: number;
  name: string;
  score: number;
  maturityScore: number;
};

type RowsThreadType = {
  id: number;
  name: string;
  score: number;
  rank: number;
};

export type FinalScoresType = {
  decimal: number;
  maturity: number;
};

type PerspectiveAndScoreType = {
  perspective: string;
  perspectiveId: string;
  score: string;
};

type ItilsRowType = {
  id: number;
  name: string;
  score: number;
};

type ItilsImprovmentsRowType = {
  id: number;
  criterion: string;
  dimension: string;
  psf: string;
};

type RowsNotesType = {
  id: number;
  body: string;
  thread: string;
  perspective: string;
};

type TableProps = {
  hasHeading?: string;
  hasFullBg?: boolean;
  noBg?: boolean;
  noBorder?: boolean;
  canDownload?: boolean;
  grouped?: boolean;
  tblSmall?: boolean;
  tableLabel?: string;
  rowsDiagnostics?: RowsType[];
  rowsThreads?: RowsThreadType[];
  rowsOverall?: RowsOverallType[];
  rowsFinalScore?: FinalScoresType;
  rowsItils?: ItilsRowType[];
  rowsItilsImprovements?: ItilsImprovmentsRowType[];
  note?: string;
  thead?: string[];
  isCertified?: boolean;
  displayBenchmark?: boolean;
  banchmarkScore?: PerspectiveAndScoreType[];
  gmTagId?: string;
  rowSingleScore?: number;
  noBody?: boolean;
  hasScore?: boolean;
  rowsNotes?: RowsNotesType[];
};

const Table: React.FunctionComponent<TableProps> = ({
  hasHeading,
  hasFullBg,
  noBg,
  canDownload,
  grouped,
  tblSmall,
  tableLabel,
  rowsDiagnostics,
  rowsOverall,
  rowsThreads,
  thead,
  rowsFinalScore,
  isCertified,
  displayBenchmark,
  banchmarkScore,
  gmTagId,
  rowsItils,
  rowsItilsImprovements,
  noBorder,
  note,
  rowSingleScore,
  noBody,
  hasScore,
  rowsNotes,
}) => {
  const isHeadless = () => {
    if (!hasHeading) return styles["Table__headless"];
  };
  const classTableWrapper = clsx(
    styles.Table,
    isHeadless(),
    !canDownload && styles["Table__no-download"],
    grouped && styles["Table__group"],
    noBody && styles["Table__no-body"],
    hasScore && styles["Table__w-score"]
  );
  const tableSmall = clsx(
    styles["Table__wrapper"],
    tblSmall && styles["Table__small"]
  );
  const tableBackground = clsx(
    hasFullBg && styles["Table__full-bg"],
    noBg && styles["Table__no-bg"]
  );

  const downloadButton = clsx(
    styles["Table__image-export"],
    noBorder && styles["no-border"]
  );
  const componentRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    if (componentRef.current) {
      setDimensions({
        width: componentRef.current.offsetWidth,
        height: componentRef.current.offsetHeight,
      });
    }
  }, []);

  const getTdBenchmark = (value: number) => {
    if (value > 0) {
      return (
        <td className={styles.positive}>
          <span className={styles.benchmarkIcon}>
            <BenchmarkArrow />
          </span>
          {`+${value.toFixed(1)}`}
        </td>
      );
    }
    if (value === 0) {
      return <td className={styles.even}>{`- 0`}</td>;
    }

    return (
      <td className={styles.negative}>
        <span className={styles.benchmarkIcon}>
          <BenchmarkArrowDown />
        </span>
        {`${value.toFixed(1)}`}
      </td>
    );
  };

  return (
    <>
      {tableLabel && (
        <h2 className={styles["table-outter-label"]}>{tableLabel}</h2>
      )}
      <div className={classTableWrapper} id="TestT" ref={componentRef}>
        <div className={styles.TableBorder}>
          {hasHeading && (
            <div className={styles["Table__header"]}>
              <h2>{hasHeading}</h2>
              {isCertified && <CertifiedIcon />}
            </div>
          )}
          <div className={tableSmall}>
            <table className={tableBackground}>
              {thead && (
                <thead>
                  <tr>
                    {thead.map((head) => (
                      <th key={head}>{head}</th>
                    ))}
                    {displayBenchmark && <th>Benchmark</th>}
                  </tr>
                </thead>
              )}
              <tbody>
                {rowsDiagnostics &&
                  rowsDiagnostics.map((row) => {
                    return (
                      <tr key={row.id}>
                        <th>{row.name}</th>
                        <td>
                          {row.items.map((i) => {
                            return <p key={i.id}>{i.statement}</p>;
                          })}
                        </td>
                      </tr>
                    );
                  })}

                {rowsOverall &&
                  rowsOverall.map((row) => {
                    const benchmarckDataIdScore = banchmarkScore?.filter(
                      (bs) => Number(bs.perspectiveId) === row.id
                    )[0]?.score;

                    return (
                      <tr key={row.id}>
                        <td>{row.rank}</td>
                        <td>{row.name}</td>
                        <td>{row.score.toFixed(2)}</td>
                        {displayBenchmark &&
                          getTdBenchmark(
                            row.score - Number(benchmarckDataIdScore)
                          )}
                      </tr>
                    );
                  })}

                {rowsThreads &&
                  rowsThreads.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>{row.rank}</td>
                        <td>{row.name}</td>
                        <td>{row.score.toFixed(2)}</td>
                      </tr>
                    );
                  })}

                {rowsItils &&
                  rowsItils.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>{row.name}</td>
                        <td>{row.score}</td>
                      </tr>
                    );
                  })}

                {rowsItilsImprovements &&
                  rowsItilsImprovements.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>{row.criterion}</td>
                        <td>{row.dimension}</td>
                        <td>{row.psf}</td>
                      </tr>
                    );
                  })}

                {(rowSingleScore || rowSingleScore === 0) && (
                  <tr className={styles.finalScores}>
                    <td>
                      <strong>{rowSingleScore}</strong>
                    </td>
                  </tr>
                )}

                {rowsFinalScore && (
                  <tr className={styles.finalScores}>
                    <td>
                      <strong>{rowsFinalScore.decimal.toFixed(2)}</strong>{" "}
                      <span>Decimal score</span>
                    </td>
                    <td>
                      <strong>{rowsFinalScore.maturity}</strong>{" "}
                      <span>Maturity score (Certification)</span>
                    </td>
                  </tr>
                )}

                {rowsNotes &&
                  rowsNotes.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>{row.body}</td>
                        <td>{row.thread}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {note && (
          <div style={{ marginTop: 20 }} className={styles.TableBorder}>
            <div className={tableSmall}>
              <table className={tableBackground}>
                <thead>
                  <tr>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{note}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {canDownload && (
        <div className={downloadButton}>
          <Button
            label="Download image"
            style={ButtonStyle.SECONDARY}
            type={ButtonType.BUTTON}
            benchMarking={displayBenchmark}
            id={gmTagId}
            onClick={() =>
              exportComponentAsJPEG(componentRef, {
                fileName: hasHeading,
                html2CanvasOptions: {
                  scrollX: 10,
                  width: dimensions.width + 20,
                },
              })
            }
          >
            <DownloadSVG />
          </Button>
        </div>
      )}
    </>
  );
};

export default Table;
