import React, { createContext, useContext, useRef, useState } from "react";
import Modal, { ModalOptions } from "components/Modal";

import { api, API } from "modules/api";

const ModalContext = createContext<(options: ModalOptions) => Promise<void>>(
  Promise.reject
);

const ModalProvider: React.FunctionComponent = ({ children }) => {
  const [confirmationState, setConfirmationState] =
    useState<ModalOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ModalOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  const handleCerfifyAssessment = async (
    assessmentId: number,
    type: string
  ) => {
    try {
      const response =
        type === "ITIL"
          ? await api(API.POST_ASSESSMENT_ENDORSE(assessmentId))
          : await api(API.POST_ASSESSMENT_CERFITY(assessmentId));
      if (response && awaitingPromiseRef.current) {
        awaitingPromiseRef.current?.resolve();
        return true;
      }
    } catch (error) {
      awaitingPromiseRef.current?.reject();

      const errorMsg: ModalOptions = {
        title: "Something went wrong, please try again later",
        type: "error",
      };

      setConfirmationState(errorMsg);
      return false;
    }
    return false;
  };

  return (
    <ModalContext.Provider value={openConfirmation}>
      {children}
      {confirmationState && (
        <Modal
          open={Boolean(confirmationState)}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onCerfify={handleCerfifyAssessment}
          {...confirmationState}
        />
      )}
    </ModalContext.Provider>
  );
};

function useModal(): (options: ModalOptions) => Promise<void> {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
}

export { ModalProvider, useModal };
