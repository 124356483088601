import React from "react";
import { SubmitHandler } from "react-hook-form";

import * as Form from "components/Form";
import * as Question from "components/Questions";
import * as Progress from "components/Progress";

import Headings from "components/Headings";
import Section from "components/Section";
import Sidebar from "components/Sidebar";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { ButtonsFormat } from "components/Inputs/Buttons/constants";
import Loading from "components/Loading";
import Note from "components/Notes";

import { CapabilityType } from "modules/api/endpoints/capabilitiesGet";
import { MaturityType } from "modules/api/endpoints/maturitiesGet";

import { ItilFormStateType, OptionType, ItilStateType } from "../types";
import { ProgressBarPageType } from "components/Progress/Simple/index";
import { Organization } from "modules/api/endpoints/organizationGet";
import { ItilAssessmentScopeSchema } from "../schema";
import OrganizationDetails from "components/Headings/OrganizationDetails";
import {
  BaseOptions,
  capabilities,
  servicePractice,
  technicalPractice,
} from "./constants";

import styles from "./styles.module.scss";

type TemplateProps = {
  isLoading: boolean;
  isCertified: boolean;
  handleSubmit: SubmitHandler<ItilFormStateType>;
  handleGoBack: () => void;
  formStates: ItilStateType;
  organization: Organization;
  selectedScope: string;
  progressBarPages: ProgressBarPageType[];
  assessmentId?: number;
  capabilitiesData: CapabilityType[];
  maturitiesData: MaturityType[];
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  isCertified,
  handleSubmit,
  handleGoBack,
  formStates,
  organization,
  selectedScope,
  progressBarPages,
  assessmentId,
  capabilitiesData,
  maturitiesData,
}) => {
  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <Section>
            <Headings
              title="Assessment profile set-up"
              subtitle="Scope details"
            />
            <div className={styles.itilWrapper}>
              <Form.Wrapper
                onSubmit={handleSubmit}
                state={formStates}
                mode="all"
                schema={ItilAssessmentScopeSchema}
              >
                {({ control, formState: { isSubmitting, isValid } }) => {
                  return (
                    <>
                      <Form.Fieldset assessmentSetup title={selectedScope}>
                        <div className={styles.rowHeader}>
                          <h3>Capability Practices</h3>
                          <p>
                            Select the ITIL 4 General Management Practices to be
                            assessed:
                          </p>
                          <span>
                            Please select the practices you wish to assess from
                            the lists below
                          </span>
                        </div>

                        {selectedScope !== "Capability" && (
                          <div className={styles.fakeItem}>
                            <div>
                              <p>
                                Continual improvement <span>*</span>
                              </p>
                              <span>Primary</span>
                            </div>
                            <p>
                              <span>*</span> This practice is mandatory{" "}
                            </p>
                          </div>
                        )}

                        {selectedScope !== "Capability" &&
                          capabilitiesData &&
                          capabilitiesData
                            .filter(
                              (cap) => cap.id !== 2 && cap.code.includes("CG-")
                            )
                            .map((cp) => {
                              return (
                                <Question.QuestionRadio
                                  key={cp.id}
                                  control={control}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={cp.name}
                                  name={cp.code}
                                  options={BaseOptions}
                                  row
                                />
                              );
                            })}

                        {selectedScope === "Capability" &&
                          capabilitiesData &&
                          capabilitiesData
                            .filter((cp) => cp.code.includes("CG-"))
                            .map((cp) => {
                              return (
                                <Question.QuestionRadio
                                  key={cp.id}
                                  control={control}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={cp.name}
                                  name={cp.code}
                                  options={BaseOptions}
                                  row
                                />
                              );
                            })}

                        <div className={styles.rowHeader}>
                          <p>
                            Select the ITIL 4 Service Management Practice(s) to
                            be assessed:
                          </p>
                          <span>
                            Select one or more of the following options
                          </span>
                        </div>

                        {capabilitiesData &&
                          capabilitiesData
                            .filter((cp) => cp.code.includes("CS-"))
                            .map((cp) => {
                              return (
                                <Question.QuestionRadio
                                  key={cp.id}
                                  control={control}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={cp.name}
                                  name={cp.code}
                                  options={BaseOptions}
                                  row
                                />
                              );
                            })}
                        <div className={styles.rowHeader}>
                          <p>
                            Select the ITIL 4 Technical management Practice(s)
                            to be assessed:
                          </p>
                          <span>
                            Select one or more of the following options
                          </span>
                        </div>
                        {capabilitiesData &&
                          capabilitiesData
                            .filter((cp) => cp.code.includes("CT-"))
                            .map((cp) => {
                              return (
                                <Question.QuestionRadio
                                  key={cp.id}
                                  control={control}
                                  format={ButtonsFormat.HORIZONTAL}
                                  label={cp.name}
                                  name={cp.code}
                                  options={BaseOptions}
                                  row
                                />
                              );
                            })}

                        {selectedScope &&
                          (selectedScope === "Comprehensive" ||
                            selectedScope === "Maturity") && (
                            <div className={styles.warningBox}>
                              <h3>Maturity components</h3>
                              <p>
                                This part of the assessment comprises of 5
                                components
                              </p>
                              <span>
                                No selection is required as these components are
                                mandatory
                              </span>
                              <ul>
                                {maturitiesData &&
                                  maturitiesData.map((md) => {
                                    return (
                                      <li key={md.id}>
                                        {md.name} <span>*</span>
                                      </li>
                                    );
                                  })}
                              </ul>
                              <div>
                                <span>*</span> These components are mandatory
                              </div>
                            </div>
                          )}
                      </Form.Fieldset>

                      <Form.Actions>
                        <Button
                          label="Go back"
                          style={ButtonStyle.SECONDARY}
                          type={ButtonType.BUTTON}
                          onClick={handleGoBack}
                        />
                        <Button
                          label="Save & Continue"
                          style={ButtonStyle.PRIMARY}
                          type={ButtonType.SUBMIT}
                          isLoading={isSubmitting}
                        />
                      </Form.Actions>
                    </>
                  );
                }}
              </Form.Wrapper>
            </div>
          </Section>
          <Sidebar>
            <OrganizationDetails
              name={organization && organization.name ? organization.name : ""}
              country={
                organization && organization.country
                  ? organization.country.name
                  : ""
              }
              businessUnit={
                organization && organization.businessUnit
                  ? organization.businessUnit
                  : ""
              }
            />
            {isCertified && (
              <Note>
                <h3>Note</h3>
                <p>
                  Your options have been greyed out because you have opted to be
                  certified. You must assess all perspectives and four mandatory
                  threads.
                </p>
              </Note>
            )}
            <Progress.Simple
              title="Your Progress"
              subtitle="Assessment profile set-up"
              company={organization.name}
              pages={progressBarPages}
              assessmentId={assessmentId}
              organizationId={organization.id ? organization.id : 0}
            />
          </Sidebar>
        </>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
