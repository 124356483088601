export enum ROUTE {
  ASSESSMENT_PROFILE = "/assessments/:assessmentId/profile",
  ASSESSMENT_SCOPE = "/assessments/:assessmentId/scope",
  ASSESSMENT_REVIEW = "/assessments/:assessmentId/review",
  ASSESSMENT_RESULT = "/assessments/:assessmentId/result",
  ASSESSMENT_PROFILE_ITIL = "/assessments/:assessmentId/profile/itil",
  ASSESSMENT_SCOPE_ITIL = "/assessments/:assessmentId/scope/itil",
  ASSESSMENT_REVIEW_ITIL = "/assessments/:assessmentId/review/itil",
  ASSESSMENT_RESULT_ITIL = "/assessments/:assessmentId/result/itil",
  DASHBOARD = "/",
  DASHBOARD_TOKEN = "/entry/:token",
  DEBUG = "/debug",
  ORGANIZATION = "/organization",
  ORGANIZATION_EDIT = "/organization/:id/edit",
  ORGANIZATION_ASSESSMENT = "/organizations/:organizationId/assessment",
  QUESTIONS = "/assessments/:assessmentId/questions/:perspectiveCode/:threadCode",
  QUESTIONS_SUMMARY = "/assessments/:assessmentId/summary",
  QUESTIONS_ITIL_SUMMARY = "/itil/assessments/:assessmentId/summary",
  QUESTIONS_ITIL = "/itil/assessments/:assessmentId/questions/:practiceCode/",
  QUESTIONS_ITIL_SCOPE = "/questionItil/",
}
