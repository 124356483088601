export enum ButtonSize {
  INLINE = "inline",
  SMALL = "small",
}

export enum ButtonStyle {
  NEGATIVE = "negative",
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum ButtonTarget {
  BLANK = "_blank",
  SELF = "_self",
  PARENT = "_parent",
  TOP = "_top",
}

export enum ButtonType {
  BUTTON = "button",
  LINK = "link",
  SUBMIT = "submit",
}
