import React from "react";

import { Organization } from "modules/api/endpoints/organizationGet";
import Button, { ButtonStyle } from "components/Button";
import { ReactComponent as ChevronDownIcon } from "modules/theme/icons/icon-chevron-down.svg";

import styles from "../styles.module.scss";

type PanelHeadProps = {
  organization: Organization;
  isOpen: boolean;
  activeAssessments: number;
  completedAssessments: number;
  createdAssessments: number;
  draftAssessments: number;
  totalAssessments: number;
  handleFetchAssessment: () => void;
  handleEditCompany: (id: number) => void;
  handleCreateAssessment: (id: number) => void;
};

function PanelHead({
  organization,
  isOpen,
  activeAssessments,
  completedAssessments,
  createdAssessments,
  draftAssessments,
  handleFetchAssessment,
  handleEditCompany,
  handleCreateAssessment,
}: PanelHeadProps): JSX.Element {
  return (
    <div className={styles.panel__header}>
      <div className={styles.panel__info}>
        {!isOpen && (
          <>
            <h3>{organization.name}</h3>
            <div className={styles["panel__info-numbers"]}>
              <div>
                <span>
                  Active assessments:{" "}
                  <strong>
                    {createdAssessments + draftAssessments + activeAssessments}
                  </strong>
                </span>
                <span>
                  Location: <strong>{organization.country.name}</strong>
                </span>
              </div>
              <div>
                <span>
                  Completed assessments: <strong>{completedAssessments}</strong>
                </span>
                <span>
                  Business unit: <strong>{organization.businessUnit}</strong>
                </span>
              </div>
            </div>
          </>
        )}
        {isOpen && (
          <>
            <h3>{organization.name}</h3>
            <div className={styles["panel__info-company"]}>
              <div>
                <span>
                  Location: <strong>{organization.country.name}</strong>
                </span>
                <span>
                  Industry: <strong>{organization.industry.name}</strong>
                </span>
              </div>
              <div>
                <span>
                  Business unit: <strong>{organization.businessUnit}</strong>
                </span>
                <span>
                  Number of employees: <strong>{organization.size.name}</strong>
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={styles.panel__action}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
        }}
      >
        {isOpen && (
          <Button
            onClick={() => {
              handleEditCompany(organization.id);
            }}
            label={"Edit Details"}
            style={ButtonStyle.SECONDARY}
          />
        )}
        <Button
          onClick={() => {
            handleCreateAssessment(organization.id);
          }}
          label={"Create assessment"}
          style={ButtonStyle.PRIMARY}
        />
      </div>
      <div className={styles.panel__toggle}>
        <ChevronDownIcon
          onClick={() => {
            handleFetchAssessment();
          }}
          className={isOpen ? styles.isOpen : styles.isClosed}
        />
      </div>
    </div>
  );
}

export default PanelHead;
