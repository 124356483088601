import React, { useContext } from "react";
import clsx from "clsx";

import PlatformContext from "modules/platform/context";

import styles from "./styles.module.scss";
import { ButtonsType } from "../constants";
import { ReactComponent as ExclamationMarkSVG } from "modules/theme/icons/buttons/exclamation-mark.svg";
import { ReactComponent as TickSVG } from "modules/theme/icons/buttons/tick.svg";

type ButtonProps = {
  disabled?: boolean;
  id?: string;
  invalid?: boolean;
  label?: string;
  onClick: () => void;
  readOnly?: boolean;
  selected: boolean;
  tabIndex?: number;
  type: ButtonsType;
  statement?: string;
};

const Button: React.FunctionComponent<ButtonProps> = ({
  disabled,
  id,
  invalid,
  label,
  onClick,
  readOnly,
  selected,
  tabIndex,
  type,
  statement,
}) => {
  const { isSafari } = useContext(PlatformContext);
  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    !disabled && !readOnly && onClick();
  };
  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.key === "Enter" && handleClick(event);
  };
  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    event.preventDefault();
  };

  const isButtonDisabled = () => {
    return disabled && styles["disabled"];
  };

  const isButtonInvalid = () => {
    return !disabled && invalid && styles["invalid"];
  };

  const isButtonReadOnly = () => {
    return readOnly && styles["read-only"];
  };

  const isButtonSelected = () => {
    return selected && !disabled && styles["selected"];
  };

  const className = clsx(
    styles["button"],
    styles[`${type}`],
    isButtonDisabled(),
    isButtonInvalid(),
    isButtonReadOnly(),
    isButtonSelected()
  );

  const handleTabIndex = () => {
    if (disabled || readOnly) return -1;
    return tabIndex ? tabIndex : 0;
  };

  const handleBooleanIcons = (icon: string) => {
    switch (icon) {
      case ButtonsType.TRUE:
        return <TickSVG />;
      case ButtonsType.FALSE:
        return <ExclamationMarkSVG />;
      default:
        return null;
    }
  };

  const handleLabel = (labelText: string | undefined) => {
    return (
      labelText && (
        <span>
          {!statement && labelText}
          {statement ? (
            <p>
              <strong>{labelText}</strong>
              {statement}
            </p>
          ) : (
            ""
          )}
        </span>
      )
    );
  };

  if (isSafari)
    return (
      <div
        aria-label={label}
        className={className}
        id={id}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        onMouseDown={handleMouseDown}
        role="button"
        tabIndex={handleTabIndex()}
      >
        {handleLabel(label)}
        {handleBooleanIcons(type)}
      </div>
    );

  return (
    <button
      aria-label={label}
      className={className}
      disabled={disabled}
      id={id}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      tabIndex={handleTabIndex()}
      type="button"
    >
      {handleLabel(label)}
      {handleBooleanIcons(type)}
    </button>
  );
};

export default Button;
