import React from "react";
import { useHistory } from "react-router-dom";

import Sidebar from "components/Sidebar";
import Loading from "components/Loading";
import Button, { ButtonStyle, ButtonType } from "components/Button";

import * as Progress from "components/Progress";
import Headings from "components/Headings";
import OrganizationDetails from "components/Headings/OrganizationDetails";

import { AssessmentScopeStatesType } from "../../Scope/types";
import { ProgressBarPageType } from "components/Progress/Simple/index";

import styles from "../styles.module.scss";
import Note from "components/Notes";
import { unarchiveAssessment } from "modules/utils/ArchiveAssessments";

type TemplateProps = {
  isLoading: boolean;
  assessment: any;
  progressBarPages: ProgressBarPageType[];
  handleStartAssessment: () => void;
  activePractices: any;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  assessment,
  progressBarPages,
  handleStartAssessment,
  activePractices,
}) => {
  const history = useHistory();
  const isReadOnly = assessment.isArchived;
  return (
    <React.Fragment>
      {!isLoading && (
        <>
          <div className={styles["review__wrapper"]}>
            <Headings
              title="Assessment profile set-up"
              subtitle="Review assessment profile"
            ></Headings>
            <div className={styles["review__items"]}>
              {/* Begins Item  */}
              <div className={styles.review__item}>
                <div className={styles["review__item-header"]}>
                  <h2>Assessment profile</h2>{" "}
                  <Button
                    label="Edit"
                    style={ButtonStyle.SECONDARY}
                    onClick={() =>
                      history.push(`/assessments/${assessment.id}/profile/itil`)
                    }
                    disabled={isReadOnly}
                  />
                </div>
                <div
                  className={`${styles.review__body} ${styles["review__body-m-width"]}`}
                >
                  <div className={styles["review__item-list"]}>
                    <h4>Organization details</h4>
                    <ul>
                      <li>
                        <span>
                          Name: <strong>{assessment.organization.name}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Number of employees:{" "}
                          <strong>
                            {assessment.organization.size.name} people
                          </strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Location:{" "}
                          <strong>
                            {assessment.organization.country.name}
                          </strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Business unit:{" "}
                          <strong>
                            {assessment.organization.businessUnit}
                          </strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Industry:{" "}
                          <strong>
                            {assessment.organization.industry.name}
                          </strong>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className={styles["review__item-list"]}>
                    <h4>Assessment details</h4>
                    <ul>
                      <li>
                        <span>
                          Name: <strong>{assessment.name}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Scope: <strong>{assessment.selectedScope}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          {assessment.selectedScope === "Comprehensive" && (
                            <>
                              Certified:{" "}
                              <strong>
                                {assessment.endorsed ? "Yes" : "No"}
                              </strong>
                            </>
                          )}
                          {assessment.selectedScope !== "Comprehensive" && (
                            <>
                              Statement of results:{" "}
                              <strong>
                                {assessment.endorsed ? "Yes" : "No"}
                              </strong>
                            </>
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Finish Item  */}

              <div
                className={`${styles.review__item} ${styles["review__item--row"]}`}
              >
                <div className={styles["review__item-header"]}>
                  <h2>Scope details</h2>{" "}
                  <Button
                    label="Edit"
                    style={ButtonStyle.SECONDARY}
                    onClick={() =>
                      history.push(`/assessments/${assessment.id}/scope/itil`)
                    }
                    disabled={isReadOnly}
                  />
                </div>
                <div className={styles.review__body}>
                  <div className={styles["review__item-list"]}>
                    <h4>
                      Scope: <strong>{assessment.selectedScope}</strong>
                    </h4>
                  </div>
                  <div className={styles["review__item-list"]}>
                    <h4>Selected capabilities practices being assessed:</h4>
                    <ul className={styles.hasDetails}>
                      {activePractices.length > 0 &&
                        activePractices.map((p: any) => (
                          <li key={p.id}>
                            <span className={styles.checkIcon}>
                              <strong>{p.name}</strong>
                            </span>
                            <p>
                              {p.selectionMode === 1 ? "Primary" : "Supporting"}
                            </p>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {assessment.selectedScope !== "Capability" && (
                    <div className={styles["review__item-list"]}>
                      <h4>Maturity components being assessed:</h4>
                      <ul>
                        <li>
                          <span className={styles.checkIcon}>
                            <strong>
                              Guiding Principles <span>*</span>
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span className={styles.checkIcon}>
                            <strong>
                              Governance <span>*</span>
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span className={styles.checkIcon}>
                            <strong>
                              Service Value Chain <span>*</span>
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span className={styles.checkIcon}>
                            <strong>
                              Practices <span>*</span>
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span className={styles.checkIcon}>
                            <strong>
                              Continual Improvement <span>*</span>
                            </strong>
                          </span>
                        </li>
                      </ul>
                      <p>
                        <span>*</span> These components are mandatory{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* Finish Item  */}
              <div className={styles.review__actions}>
                <Button
                  label="Save & return home"
                  style={ButtonStyle.SECONDARY}
                  type={ButtonType.BUTTON}
                  onClick={() => history.push("/")}
                  disabled={isReadOnly}
                />
                <Button
                  label={
                    assessment.status === "Active"
                      ? "Continue assessment"
                      : "Start assessment"
                  }
                  style={ButtonStyle.PRIMARY}
                  type={ButtonType.BUTTON}
                  onClick={handleStartAssessment}
                  disabled={isReadOnly}
                />
              </div>
            </div>
            {/* Finish Items  */}
          </div>
          {/* sidebar */}
          <Sidebar>
            <OrganizationDetails
              country={assessment.organization.country.name}
              name={assessment.organization.name}
              businessUnit={assessment.organization.businessUnit}
            />
            {isReadOnly && (
              <Note>
                <p>
                  This assessment has been archived.
                  <strong
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={async () => {
                      await unarchiveAssessment(assessment.id);
                      history.push(`/`);
                    }}
                  >
                    &nbsp;Click here&nbsp;
                  </strong>
                  to restore if you would like to continue working on it.
                </p>
              </Note>
            )}
            {!isReadOnly && (
              <Progress.Simple
                title="Your Progress"
                subtitle="Assessment profile set-up"
                company={assessment.organization.name}
                pages={progressBarPages}
                assessmentId={assessment.id}
              />
            )}
          </Sidebar>
          {/* close sidebar */}
        </>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
